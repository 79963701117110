<script setup>
import { ref } from 'vue';
import DialogModal from '../Shared/DialogModal.vue';

const speakers = ref({
    'Keynote Speakers': [
        {
            name: 'The Right Honourable Adrienne Clarkson',
            title: 'Former Governor General of Canada',
            organization: '',
            image_file_name: 'AdrienneClarkson.jpg',
            bio: `
      The Right Honourable Adrienne Clarkson arrived in Canada from Hong Kong with her family in 1942 and made the astonishing journey from penniless child refugee to accomplished broadcaster, journalist, author and distinguished public servant in a multi-faceted lifetime.
      <br> <br>
      Adrienne Clarkson was Canada’s 26th Governor General from 1999-2005 and is universally acknowledged to have transformed the office and leaving an indelible mark on Canada’s history. When she left Rideau Hall, she co-founded the Institute for Canadian Citizenship which helps new citizens to feel involved and included in Canadian life. The ICC offers a one-of-a-kind program, Canoo, an app that gives newcomers free VIP access to over 1,400 of Canada’s best cultural and outdoor experiences.
      <br> <br>
      A Privy Councillor and a Companion of the Order of Canada, Adrienne Clarkson is the National Institute on Ageing’s Honorary Chair for its Advisory Board.
      <br><br>
      La très honorable Adrienne Clarkson a grandi à Ottawa après que sa famille eut fui l’occupation japonaise de Hong Kong, en 1942. Quelques décennies plus tard, elle deviendra le vingt-sixième gouverneur général du Canada.
      <br><br>
      Son engagement, sa forte personnalité, son attachement aux arts et à la culture l’ont amené à remplir ses fonctions de telle manière que le rôle de gouverneur général en a été profondément transformé. Lorsqu'elle a quitté Rideau Hall, elle a cofondé l'Institut pour la citoyenneté canadienne qui aide les nouveaux citoyens à se sentir impliqués et inclus dans la vie canadienne. L’ICC propose un programme unique en son genre, Canoo, une application qui offre aux nouveaux arrivants un accès VIP gratuit à plus de 1 400 des meilleures expériences culturelles et de plein air au Canada.
      <br><br>
      Conseillère privée et Compagnon de l’Ordre du Canada, Adrienne Clarkson est présidente honoraire du conseil consultatif de l’Institut national sur le vieillissement.
    `,
        },
        {
            name: 'The Honourable Seamus O’Regan Jr.',
            title: 'Canada’s Minister of Labour and Minister for Seniors',
            organization: '',
            image_file_name: 'Seasmus_ORegan.jpg',
            bio: `
      Seamus O’Regan Jr. was first elected Member of Parliament for St. John's South-Mount Pearl in 2015, subsequently re-elected in 2019 and 2021. He is currently Canada’s Minister of Labour and Minister for Seniors.
     <br><br>
     O’Regan has also previously served as Canada’s Natural Resources Minister, Minister of Indigenous Services, Minister of Veterans Affairs and Associate Minister of National Defence.
     <br><br>
     He was born in St. John's, Newfoundland, and raised in Goose Bay, Labrador. He studied politics at St. Francis Xavier University and University College Dublin, and received his Master of Philosophy degree from the University of Cambridge, specializing in  Indigenous participation in natural resource development.
     <br><br>
     In the Government of Newfoundland & Labrador, he served as Executive Assistant to the Minister of Justice, and later, Senior Policy Advisor to the Premier.

     <br><br>
     Seamus O'Regan Jr. a été élu pour la première fois député de St. John's South-Mount Pearl en 2015, puis réélu en 2019 et 2021. Il est actuellement ministre du travail et ministre des aînés.
     <br><br>
     M. O'Regan a également été ministre des Ressources naturelles, ministre des Services autochtones, ministre des Anciens combattants et ministre associé de la Défense nationale.
     <br><br>
     Originaire de St-Jean, à Terre-Neuve, il a grandi à Goose Bay, au Labrador. Il a étudié la politique à l'Université St. Francis Xavier et à l'University College Dublin, puis a obtenu une maîtrise de philosophie à l'université de Cambridge, avec une spécialisation dans la participation autochtone au développement des ressources naturelles.
     <br><br>
     Au sein du gouvernement de Terre-Neuve-et-Labrador, il a été assistant exécutif du ministre de la justice, puis conseiller politique principal du premier ministre.
    `,
        },
        {
            name: 'Vinod Rajasekaran',
            title: 'CEO & Editor-in-Chief',
            organization: 'Future of Good',
            image_file_name: 'VinodRajasekaran.jpg',
            bio: `
      An award-winning innovator, Vinod Rajasekaran is CEO & Editor-in-Chief of Future of Good, a fast-growing media and learning organization equipping changemakers with essential news, insights and knowledge on transformations and innovations shaping the social purpose world. In addition to leading Future of Good, Vinod teaches a master’s level course on the trends and debates shaping the future of philanthropy at Carleton University.
      <br><br>
      He was designated as a Global Shaper by the World Economic Forum, a winner of the prestigious Lewis Perinbam Award for social innovation, is a member of the Banff Forum and NEXUS communities, and is a recipient of The Queen Elizabeth II Diamond Jubilee Medal for his contribution to Canada.
      <br><br>
      Vinod is a regular commentator in the media, an invited speaker and moderator to conferences around the world on emerging trends in social impact, shifting narratives, social finance, social innovation, philanthropy, and corporate social responsibility.
      <br><br>
      Innovateur primé, Vinod Rajasekaran est directeur général et rédacteur en chef de Future of Good, une organisation médiatique et d'apprentissage en plein essor qui fournit aux acteurs du changement des informations, des points de vue et des connaissances essentiels sur les transformations et les innovations qui façonnent le monde de l'utilité sociale.
      <br><br>
      En plus de diriger Future of Good, Vinod enseigne un cours de niveau master sur les tendances et les débats qui façonnent l'avenir de la philanthropie à l'université Carleton. Il a été désigné comme Global Shaper par le Forum économique mondial, lauréat du prestigieux prix Lewis Perinbam pour l'innovation sociale, membre du Banff Forum et des communautés NEXUS, et récipiendaire de la Médaille du jubilé de diamant de la Reine Elizabeth II pour sa contribution au Canada.
      <br><br>
      Vinod est un commentateur régulier dans les médias, un orateur invité et un modérateur de conférences dans le monde entier sur les tendances émergentes de l'impact social, les récits changeants, la finance sociale, l'innovation sociale, la philanthropie et la responsabilité sociale des entreprises.
    `,
        },
        {
            name: 'Dr. Samir Sinha',
            title: 'Director of Geriatrics',
            organization:
                'Sinai Health System and the University Health Network',
            image_file_name: 'new/DrSamirSinha.jpg',
            bio: `
            Dr. Samir Sinha is the Director of Geriatrics at Sinai Health System and the University Health Network in Toronto, a Professor of Medicine at the University of Toronto, a Fellow of the Canadian Academy of Health Sciences, and the Director of Health Policy Research at Toronto Metropolitan University’s National Institute on Ageing.
            <br><br>
            A Rhodes Scholar, Samir is a highly regarded clinician and international expert in the care of older adults. In 2021, he was appointed to serve as a member of the Government of Canada’s National Seniors Council and recently led the successful development of Canada’s new National Long-Term Care Services Standard.
    `,
        },
        {
            name: 'Annika Voltan',
            title: 'Executive Director',
            organization: 'Impact Organizations of Nova Scotia (IONS)',
            image_file_name: 'AnnikaـR.jpg',
            bio: `
                Annika Voltan (she/her) is the Executive Director of Impact Organizations of Nova Scotia (IONS), where she works with an amazing team to develop the capacity and influence of the community impact sector. Annika has been in a leadership role in the sector for about 8 years was previously the Executive Director of Inspiring Communities. She has experience working as a policy analyst across federal and provincial government departments, as a lecturer in academia and various roles in small and large businesses, which helps her to act as a bridge across sectors. She has a PhD in social entrepreneurship and research interests in networks and social innovation. Annika grew up in Kjipuktuk (Halifax) and spent time living in different places in Canada and Europe, but Nova Scotia called her home in 2011 and she feels deeply inspired by and connected to her home province. She lives with her husband and three children and can be found exploring nature and the local craft beer scene.
            `,
        },
        {
            name: 'Kahir Lalji',
            title: 'Provincial Director, Government Relations & Programs',
            organization: 'United Way British Columbia',
            image_file_name: 'Kahir.jpeg',
            bio: `
            Kahir Lalji is the Provincial Director, Government Relations & Programs with the United Way British Columbia – working with communities in BC’s North, Interior, Lower Mainland and Central & Northern Vancouver Island. Inspired by caring for his grandparents, Kahir dedicated the last 15+ years of his life to supporting the quality of life of older adults – always attempting to ensure the representation of traditionally underserved populations.
            <br><br>
            Kahir is a Gerontologist with a Masters of Gerontology from Simon Fraser University, and also has a fellowship in developmental leadership and evaluation. He serves as the Vice Chair on the Board of Directors for HelpAge Canada, on the Advisory Board of Aging 2.0, and a Member on the Aga Khan Health Board for Canada. Kahir also serves as the past President of the Board of Directors for British Columbia Original Minds Association and a past Board Member of BC211.
            `,
        },
        {
            name: 'Nicole Clarke',
            title: 'Associate Executive Director',
            organization: 'HelpAge Canada',
            image_file_name: 'Nicole.jpg',
            bio: `
            Nicole is the Associate Executive Director at HelpAge Canada. Since joining the organization in 2020, she has been at the forefront of leading HelpAge Canada’s Canadian initiatives. She oversees a range of transformative programs, including the Let's Connect national digital literacy program, the Age Better granting program, and HelpAge Canada’s national home sharing and age-friendly transportation programs Canada HomeShare and Can Move. Through these programs and by working in partnership with Community-based seniors’ services (CBSS) organizations across the country, HelpAge Canada is taking a holistic approach to healthy aging.
            <br><br>
            Prior to joining HelpAge Canada, Nicole worked to ensure diversity at the most senior levels of organizations across Canada as an executive search consultant for an Indigenous search practice. Working with organizations and communities coast to coast to coast has given Nicole an appreciation for the diversity of cultures and the disparity of needs in Canada. This drives her to ensure that both existing and new HelpAge Canada programs are tailored to the local context of specific communities, and the older people who live there.  
            <br><br>
            Nicole has an honours bachelor's degree in commerce from the University of Ottawa and is French/ English bilingual.  
            `,
        },
        {
            name: 'Mariam Elghahuagi',
            title: 'Director',
            organization: 'Healthy Aging Alberta',
            image_file_name: 'Mariam.jpg',
            bio: `
            Mariam Elghahuagi is the Director of Healthy Aging Alberta at United Way of Calgary and Area, where she leads the backbone efforts of the organization’s pioneering provincial collective impact initiative. This initiative focuses on enhancing the lives of community-dwelling older adults by coordinating the organizations that support them across Alberta. With over ten years of experience in program development and project management, Mariam excels in managing multi-stakeholder projects and forging partnerships across government, corporate, and community sectors.
            <br><br>
            In addition to her role at United Way, Mariam serves as an Organizational Growth Executive Advisor for the Kackaamin Family Development Centre in Port Alberni, British Columbia. Here, she offers expert guidance on developing adaptable organizational structures, optimizing resources, and implementing effective data management processes.
            <br><br>
            Mariam holds a Master of Social Work in Indigenous Trauma & Resiliency from the University of Toronto, a Bachelor of Social Work from the University of Calgary, and a Bachelor of Arts in Radio and Television Production from Toronto Metropolitan University (formerly Ryerson). Her educational background is complemented by certifications in Communication, Conflict Management, and Project Management, which enhance her ability to devise impactful strategies and promote organizational growth.
            <br><br>
            Dedicated to social innovation and asset-based community development, Mariam applies a trauma-informed lens to systems change work. She is committed to developing reciprocal communication and collaboration pathways between grassroots movements, community organizations, and the larger systems that support them.
            `,
        },
    ],
    'SPECIAL GUESTS': [
        {
            name: 'Mary Walsh',
            title: 'Actress and Comedian',
            organization: '',
            image_file_name: 'Mary_Walsh.jpg',
            bio: `
            Mary Walsh created and starred in <strong>This Hour Has 22 Minutes</strong> , CBC’s wildly
            popular take on current affairs. The series earned her many of her numerous
            Gemini awards and showcased her dynamic range of characters, including the
            flagrantly outspoken ‘Marg Delahunty’.
            <br><br>
            Walsh wrote, produced and starred in the Gemini award winning series
            <strong>Hatching, Matching and Dispatching</strong>, which returned to CBC in 2017 as a
            feature length presentation called <strong>A Christmas Fury</strong>, with Walsh and the original
            cast reprising their roles. In 2017 she released her debut novel, a Canadian bestseller, <strong>Crying For The Moon</strong>. She currently writes and stars in <strong>The Missus
            Downstairs</strong>, for which she has been nominated for multiple Canadian Screen
            Awards. 
            <br><br>
            Walsh’s recent TV credits include recurring roles on Syfy’s <strong>Resident Alien</strong>,
            CBC’s <strong>Hudson & Rex</strong>, and <strong>Little Dog</strong> for which she was nominated for a 2020
            Canadian Screen Award, as well as numerous guest appearances on <strong>This Hour Has 22 Minutes.</strong>
            <br><br>
            Select feature film credits include <strong>The Grand Seduction</strong> and <strong>Closet Monster</strong>
            (Official selections at TIFF 2014 and 2015, respectively), and <strong>Mambo Italiano</strong>.
            She won the Canadian Screen Award for Best Supporting Actress in 2021 for her
            leading role in Helen Shaver’s first feature film <strong>Happy Place</strong>, and is soon to
            appear in <strong>Deaner ’89</strong>, alongside Will Sasso, Kevin McDonald and Paul Spence. 
            <br><br>
            Outside of the film, TV and theatre world, Mary is an outspoken advocate for
            mental health and addiction awareness as a spokesperson for Bell Let’s Talk.
            She’s a staunch supporter of the Wabano Centre for Aboriginal Health in Ottawa,
            and lends her time and voice to the CNIB, St. Joseph’s Hospital and CAMH. 
            <br><br>
            Among her many awards and doctorates, Mary is the recipient of the Order of
            Canada, the Governor General’s Lifetime Achievement Award in the Performing
            Arts, and the CSA’s Earle Grey Award for lifetime achievement in television
            acting.
            `,
        },
        {
            name: 'Niary Toodakian',
            title: 'Director, Consumer Marketing',
            organization: 'HomeEquity Bank',
            image_file_name: 'Niary_Toodakian.jpg',
            bio: `
            `,
        },
        {
            name: 'Laura Tamblyn-Watts',
            title: 'CEO',
            organization: 'CanAge',
            image_file_name: 'laura.jpg',
            bio: `
            Laura Tamblyn Watts is an Assistant Professor (status only) at the Factor-Inwentash Faculty of Social Work and a Fellow of the Institute for Lifecourse and Aging. A lawyer, called to the BC Bar in 1999, she is the founder and CEO of CanAge: Canada’s National Seniors’ Advocacy Organization. Previously she was the long-term National Director of the Canadian Centre for Elder Law. 
            <br><br>
            An expert in law and aging issues, Laura is the lead investigator in numerous major research and policy initiatives, and a researcher on key grants in both Canada and internationally. Her work has special focus on mental capacity and decision-making, financial regulation, elder mistreatment, long-term care, home and community care, ageism, seniors’ health policy and the rights of older people. 
            <br><br>
            She is the author of several recent major reports on measurement of Canada’s National Dementia Strategy and also on Adult Vaccination in Canada. Laura serves on a wide number of Boards and Expert Advisory groups in the field of aging and is a regular media commentator on aging issues. She was awarded the Law Foundation of Ontario’s Community Leadership in Justice Fellowship, and is a International Fellow of Stetson University’s Center of Excellence in Elder Law.
            `,
        },
    ],
    'Workshop Presenters': [
        {
            name: 'Asheika Sood',
            title: 'Program Manager',
            organization:
                'Seniors Coordinated Outreach, Edmonton Seniors Coordinating Council',
            image_file_name: 'AsheikaSood.jpg',
            bio: `
      A system designer and community builder, Asheika believes that those most impacted by programs and systems should be central in designing meaningful new approaches. As the program manager for Seniors Coordinated Outreach, Asheika works closely with leaders and staff from 12 organizations to design more accessible and equitable approaches to providing seniors access to Outreach workers that can connect them to the supports they need to live a good quality of life.
    `,
        },
        {
            name: 'Sheila Hallett',
            title: 'Executive Director',
            organization: 'Edmonton Seniors Coordinating Council',
            image_file_name: 'SheilaHallett.jpg',
            bio: `
      Sheila is a coordinator by nature. She leads the Council in encouraging collective community action that strengthens the sector and challenges systemic barriers. The aim is always to build collaborative approaches to services, supports and circumstances affecting older adults in Edmonton. Sheila has served on the Healthy Aging Alberta Community Leadership Council since its inception and prior to working with the Council, she was the director of a local senior centre for several years.
    `,
        },
        {
            name: 'Erica Botner',
            title: 'Manager of Programs for Seniors, Community Health and Social Services Network',
            organization: '',
            image_file_name: 'EricaBotner.jpg',
            bio: `
      Erica Botner is the Manager of Programs for Seniors at the Community Health and Social Services Network. She is also a Lecturer at Concordia University in Applied Human Science where she teaches courses in human relations and therapeutic recreation. She has more than 20 years of experience working with seniors and caregivers in community and clinical settings and in planning, implementing and evaluating large-scale provincial programs.
    `,
        },

        {
            name: 'Shahzi Yasmin Bokhari',
            title: 'Community Engagement Lead, Community',
            organization:
                'Action and Halton’s Older Adult Isolation Action Table',
            image_file_name: 'sbokari.jpg',
            bio: `
      Shahzi Yasmin Bokhari (she / they) is the Community Engagement Lead with Connection in Action and Halton’s Older Adult Isolation Action Table. She is passionate about community engagement, using age-friendly, anti-racist, and anti-oppressive approaches in all aspects of her work. For three years, she has led community knowledge and mobilization strategies in collaboration with stakeholders and older adult community members. She co-facilitates LITE Education (Loneliness, Isolation to Empowerment) with the Halton Elder Abuse Prevention Network. She is leading the newly established Inclusion, Diversity, Equity, Anti-Racism, Anti-Oppression (IDEAA) working group of the Action Table.
      <br><br>
      Using data from UHN OpenLab, Shahzi created a Google map to visualize all 126 naturally occurring retirement communities (NORCs) across Halton, which captures additional information including property ownership and building and rental type. This map has been used as a tool for outreach and engagement, to determine gaps in service areas, and has been presented at the international Mapping for Citizen Action event co-hosted by the Citizen Network and Community Development Halton.
      <br><br>
      Shahzi has earned a Master of Social Work degree with a focus on critical leadership, anti-racism, anti-oppression, and neurodiversity / disability justice. Her previous work includes community engagement with marginalized populations, program evaluation, community-based research, population and census data, and facilitating anti-racism, anti-oppression, and decolonial education for workplaces and community groups.
    `,
        },
        {
            name: 'Paola Leal',
            title: 'Program Coordinator',
            organization: 'Eva Marsden Centre for Social Justice and Aging',
            image_file_name: 'Paola_Leal.jpg',
            bio: `
            Paola Leal, MSc in psychiatric science, has worked as a Community Worker in different community organizations for the last 7 years from youth at risk to older persons dealing with isolation. She has worked over the last two years at the Eva Marsden Centre for Social Justice and Aging, as the Program Coordinator for a Social Prescription initiative, in Montreal. Her work has led to the increased collaboration with public health partners, community organisations and researchers, as well as the creation of a social prescription platform, called Clic Social.  
    `,
        },
        {
            name: 'Amy Sturgeon',
            title: 'Research Assistant and Project Lead',
            organization: 'Gateway Centre of Excellence in Rural Health (CERH)',
            image_file_name: 'Sturgeon_Amy_Headshot.jpg',
            bio: `
            Amy Sturgeon is a Research Assistant and Project Lead at Gateway Centre of Excellence in Rural Health (CERH) in Goderich, ON, helping rural aging populations with tech literacy and socialization. After working in a long-term care home and small rural hospitals, Amy saw firsthand how rural seniors need more support.<br><br>
A recent graduate of Carleton University in Ottawa, ON, Amy is able to apply her B.A. Honours in Sociology with double minor in Women & Gender Studies and Biology to her day-to-day professional life. Amy may spend her days at the Gateway CERH office, but it’s the new people that she interacts with every day and the community impact of Gateway CERH's initiatives that gets her up in the morning.<br><br>
Aside from her work at Gateway CERH, Amy is a new dog mom and enjoys spending time reading.
    `,
        },
        {
            name: 'Anne Smith',
            title: 'Culture Change Project Lead from Alberta Association on Gerontology',
            organization: 'Healthy Aging Alberta',
            image_file_name: 'Anne_Smith.jpg',
            bio: `
            Anne holds a Master of Public Administration from Queen's University and brings a wealth of experience to her work, with 10 years in the public sector and over 30 years in consulting. As the sole proprietor of J.A. Smith Research and Consulting Services Inc., she has facilitated hundreds of conversations and workshops aimed at fostering collaborative action, strategic planning, operational planning, and program design.<br><br>
Her client base spans both government and the non-profit sector, including collaborations with the Edmonton Seniors Coordinating Council, St. Albert Public Library, Blood Tribe Department of Social Development, and Healthy Aging Alberta (United Way of Calgary).<br><br>
In addition to her professional work, Anne has been an active member of the community, serving on the boards of Edmonton's Food Bank and the Terra Centre for Teen Parents. She currently sits on the board of SAGE (Seniors Association of Greater Edmonton).
    `,
        },
        {
            name: 'Marlene Raasok',
            title: 'Healthy Aging Alberta Consultant',
            organization: '',
            image_file_name: 'Marlene_Raasok_0548_5x7.jpg',
            bio: `
            Marlene retired in Airdrie, Alberta, after 30 years in leadership roles across Alberta, BC, and Saskatchewan, spanning both continuing care services and post-secondary education focused on health and community services programs. Her passion for advancing culture change and innovation has been pivotal in supporting older adults' well-being.<br><br>
Currently, she serves on the board of the Alberta Association on Gerontology, contributing to their Futures Policy Forum initiative aimed at fostering a community and culture supportive of Alberta's vision for healthy aging and quality of life. Additionally, Marlene is the Board Chair for the Good Samaritan Society, overseeing their operations across Alberta and British Columbia.
    `,
        },
        {
            name: 'Marta Hajek',
            title: 'CEO',
            organization: 'Elder Abuse Prevention Ontario (EAPON)',
            image_file_name: 'new/Marta-Hajek.png',
            bio: `
            Marta Hajek, is the CEO of Elder Abuse Prevention Ontario and feels privileged to be leading a modest, but extremely dedicated staff complement, supporting older adults and service professionals alike, when addressing the complexity of issues of EA. Under her stewardship EAPO continues to expand its circle of strategic partnerships across both national and international spectrums. As Director of Operations, she helped facilitate the rollout of ON 211, now a national information and referral service engaged in the human services sector.
            <br><br>
            Marta continues to serve on the Seniors Expert Advisory Committee with the ON Securities Commission, as a Board Director with the Canadian Network for the Prevention of EA, is an elected member to the Steering Group of the Global Alliance the Rights of Older Persons (GAROP) and through EAPO a founding partner of the Canadian Coalition Against Ageism (CCAA).
            <br><br>
            In April 2023, Marta addressed the United Nations' Open Ended Working Group on Aging as part of the CCAA delegation, advocating for the protection of human rights of this fastest growing demographic and calling for the declaration of a UN convention for the Rights of Older Persons.
            `,
        },
        {
            name: 'Felicia Hart',
            title: 'Manager of Caregiver Navigation and Workplace Programs',
            organization: 'Ontario Caregiver Organization',
            image_file_name: 'new/Felicia-Hart.jpg',
            bio: `
            Felicia Hart is the Manager of Caregiver Navigation and Workplace Programs at the Ontario Caregiver Organization. Felicia’s background includes diverse management experience in program development, implementation, and evaluation in the social service and not-for-profit sectors. Her goal is to ensure everyone has the opportunity to live well in their community with access to the support they need. As a caregiver, she understands the challenge of balancing career and caregiving priorities which helps inform her work at OCO. 
            `,
        },
        {
            name: 'Sonia Hsiung',
            title: 'Director of Community Health and the Canadian Institute for Social Prescribing (CISP)',
            organization: 'Canadian Red Cross',
            image_file_name: 'new/sonia.jpg',
            bio: `
            Sonia Hsiung is the Director of Community Health and the Canadian Institute for Social Prescribing (CISP) at the Canadian Red Cross. CISP is a national collaboration hub dedicated to bringing people, practices and research together to improve integrated health and social care. With experience managing multi-sectoral projects in Canadian and international community-based organizations spanning from health care, food security, housing, women’s empowerment, to engineering, Sonia is keen to bring the strengths of diverse sectors together to build equitable, resilient and more connected communities.
            `,
        },
        {
            name: 'Maril Murray',
            title: 'Manager of Projects',
            organization: 'Covenant Health Palliative Institute',
            image_file_name: 'new/Maril_Murray.jpg',
            bio: `
            Maril is the Manager of Projects at the Covenant Health Palliative Institute. Her professional background is in business consulting, project development and project management. She is grateful to work with a multidisciplinary team of clinicians and creatives to deliver projects on behalf of the institute.
            `,
        },
        {
            name: 'Michel Tremblay',
            title: 'Directeur general',
            organization: 'FARFO',
            image_file_name: 'new/Michel_Tremblay.jpeg',
            bio: `
            Né à Québec et en Ontario depuis 1974, Michel a travaillé presque toute sa carrière dans le domaine de la santé et des services communautaires, autant dans les secteurs publics que privés. Il a aussi été très impliqué et passionné par le développement de la francophonie en situation minoritaire.
            <br><br>
            Depuis août 2021, Michel occupe le poste de directeur général de la Fédération des aînés et retraités francophones de l’Ontario (FARFO). Auparavant, il a travaillé comme exécutif à Montfort Renaissance, à la Société Santé en français, à l’Hôpital Montfort, au Centre de soins de longue durée Montfort (premier directeur général) et au ministère de la Santé et des Soins de longue durée de l’Ontario comme directeur régional du Nord de l’Ontario.
            <br><br>
            En plus de son travail, il n’hésite pas à s’engager comme bénévole au sein d’organismes communautaires. Il est actuellement président du conseil d’administration de FrancoQueer et membre du conseil du Centre de leadership et d’évaluation (le CLÉ). Il a aussi contribué au Centre de services communautaire de Vanier, à l’Institut du savoir Montfort, la Radio communautaire d’Ottawa, l’Équipe de santé familiale communautaire de l’Est d’Ottawa, et le Centre des ressources communautaires de l’Est d’Ottawa. Michel est aussi membre du Club Richelieu Toronto.
            <br><br>
            Il a une maîtrise en Administration des affaires pour cadres de l’École de gestion Telfer de l’Université d’Ottawa. Il a aussi participé à plusieurs formations sur les principes de gouvernance, la responsabilisation, l’engagement communautaire, les modes alternatifs de règlement de conflit, l’approche EDEN pour les soins de longue durée, et une formation en marketing à l’Université Queens.
            <br><br>
            Michel aime beaucoup voyager et s’intéresse à l’histoire et la politique.
            <br><hr><br>
            Born in Quebec City and in Ontario since 1974, Michel Tremblay has worked most of his career in the field of health management and senior and community services, in both the public and private sectors. He has also been very involved and passionate about the development of French-speaking communities in a minority situation.
            <br><br>
            Since August 2021, Michel has held the position of Executive Director of the Fédération des aînés et retraités francophones de l’Ontario. Previously, he worked as an executive at Montfort Renaissance, at the Société Santé en français, at Hôpital Montfort, at the Montfort long-term care center (funding administrator), with a private long-term care homes company, and with the Ontario Ministry of Health and Long-Term Care as Area Manager and Regional Director for Northern Ontario.
            <br><br>
            In addition to his work, he does not hesitate to get involved as a volunteer in community boards including the Vanier Community Service Centre, the Center for Leadership and Evaluation, the Institut du savoir Montfort (research centre), Ottawa Community Radio (UNIQUE-FM), East Ottawa Community Family Health Team, Centre de services Guigues and Eastern Ottawa Community Resource Centre.
            <br><br>
            He completed an Executive Master of Business Administration (EMBA) in 2009 at the University of Ottawa Telfer School of Management. He has also participated in several trainings on the principles of governance, accountability, community involvement, alternative dispute resolution, the EDEN approach for long-term care, and a one-week leadership training session in marketing (at Queen's University)
            <br><br>
            In his spare time, he likes to read novels, go to movies, concerts, museums, art shows, biking and, above all, to travel across the world.
            `,
        },
        {
            name: 'Dena Silverberg',
            title: 'Links2Wellbeing Social Prescribing for Older Adults Project Coordinator',
            organization: 'OACAO',
            image_file_name: 'dena.jpeg',
            bio: `
            As Links2Wellbeing; Social Prescribing for Older Adults Project Coordinator at OACAO Dena is focused on ensuring that seniors have the opportunity to connect with each other and have access to engaging programs and services.  Prior to joining OACAO, Dena was the Vice President of Health and Wellness at Better Living Health and Community Services and worked there for 22 years overseeing various departments including Senior Active Living Centres, Adult Day Programs, Exercise and Falls Programming, Social Work Services, Volunteer Services and Hospice and Palliative Care Services. Dena has spent her career in the not for profit sector and has a demonstrated track record in program development, building partnerships and grant writing and management. Dena holds an Honours Bachelors Bachelor Arts in Therapeutic Recreation and Gerontology from the University of Waterloo and a Certificate in Community Engagement, Leadership and Development from Ryerson University. In her spare time Dena is an avid fitness enthusiast, snowboarder, reader and knitter!
            `,
        },
        {
            name: 'Sarah Feeney-Martin',
            title: 'Support and Quality Assurance Coordinator',
            organization: 'OACAO’s Links2Wellbeing Project',
            image_file_name: 'SarahFM.JPG',
            bio: `
            Sarah Feeney-Martin is currently the Support and Quality Assurance Coordinator for the OACAO’s Links2Wellbeing Project. Sarah is dedicated to ensuring the implementation and delivery of high-quality programs and services as well as fostering environments for community and individual growth.
            <br><br>
            Sarah has over 15 years experience community-based services including Seniors Active Living Centres, Brain Injury services, Adult Day programs as well as Long Term Care and Retirement programs.  Sarah has a demonstrated ability to lead teams, manage day-to-day operations and implement streamlined processes to ensure high quality services.
            <br><br>
            With an Honours Bachelor of Arts in Recreation and Leisure & Social Development Studies from the University of Waterloo her academic background underscores her understanding of the transformative power of recreational activities, she is driven by a passion for the impact of social and community connectedness on overall wellbeing.  Sarah is married with 2 children and enjoys puzzling while indoors and a variety of outdoor activities with her family. 
            `,
        },
        {
            name: 'Beth Mansell',
            title: 'Provincial Social Prescribing Project Manager',
            organization: 'Healthy Aging Alberta',
            image_file_name: 'new/Beth.JPG',
            bio: `
            Beth is currently the Provincial Social Prescribing Project Manager with Healthy Aging Alberta. Previously, she worked for 5 years as the Strategic Project and Operations Manager at Sage Seniors Association in Edmonton. Beth has a Masters of Public Health with a focus on health policy and management from the University of Alberta. Beth is a Member at Large on the Alberta Association on Gerontology Board and has previously served as the Co-Chair of the Age Friendly Edmonton Aging in Place Committee. Beth has worked closely with the Canadian Institute for Social Prescribing since its inception and has participated in many working groups to develop and grow social prescribing initiatives across Canada.
            `,
        },
        {
            name: 'Bobbi Symes',
            title: 'Director of Healthy Aging',
            organization: 'United Way of British Columbia',
            image_file_name: 'new/Bobbi-S-5.jpg',
            bio: `
            Bobbi Symes is a dedicated professional Gerontologist, with a passion for improving the quality of life for older adults. Bobbi has an Interdisciplinary Master's degree with a focus on Gerontology and over 20 years working in the field in multiple roles. Known for her compassionate approach and innovative solutions, Bobbi's passion for the sector is also rooted in her role as a caregiver and the real-life experiences this brings to her day to day work.
            `,
        },
        {
            name: 'Benedicte Schloepflin',
            title: 'Executive Director',
            organization: 'Canadian Network for the Prevention of Elder Abuse',
            image_file_name: 'new/Benedicte.jpg',
            bio: `
            Bénédicte has worked with CNPEA since 2015 and became its Executive Director in 2018. She previously worked as the Communications Director for the BC Law Institute and its division, the Canadian Centre for Elder Law, before dedicating herself full time to CNPEA. Originally from Avignon, France, Bénédicte now lives and works on the unceded and ancestral territory of the Coast Salish Peoples, including the territories of the Musqueam, Squamish, and Tsleil-Waututh Nations, known today as Vancouver.
            `,
        },
        {
            name: 'Connie Newman',
            title: 'Executive Director',
            organization: 'Manitoba Association of Senior Communities',
            image_file_name: 'new/Connie.jpeg',
            bio: `
            Connie Newman is the executive director and lead consultant for the Manitoba Association of Senior Communities which currently leads Age-Friendly Manitoba and Social Prescribing Initiatives (MASC). MASC is a member association currently with 82 member groups throughout Manitoba. MASC-AFI connects with 95 communities in Manitoba on the Age-Friendly pathway. Notably, she also chaired the committee which developed the Manitoba Government Seniors Strategy, released in February 2023. Since 2022, Connie has also been a member of the Appropriate Use Advisory Committee of the Canadian Drug Agency Transition Office.
            <br><br>
            Among her many volunteer engagements, Connie continues to be very involved with the Assiniboia Youth Community Resource Centre and the St. James-Assiniboia 55+ Active Living Centre.
            <br><br>
            Connie is 76 years young and holds a master’s degree in education administration from the University of Manitoba. She was a junior high teacher and principal for 34 years in the St. James Assiniboia School Division and retired from education in 2004. Newman has many connections in Manitoba, Canada and beyond.
            <br><br>
            Newman believes in active aging and all that it means. Keeping the mind and body active helps to assist those around her who may need information and support as we all age together. She believes giving back and giving to others is important and we can all give in some form as it makes our Canada a much better place.
            `,
        },
    ],
    'Lightning Round Presenters': [
        {
            name: 'Sue Garvey ',
            title: 'Steering Committee Member',
            organization: 'Ambleside Oasis Pilot Project (Queen’s University)',
            image_file_name: 'SueGarvey.jpg',
            bio: `
      Sue Garvey lives in Ottawa and is a graduate of the Carleton University Masters in Social Work program.
      <br><br>
      Sue has worked for a variety of agencies in Ottawa, including the City of Ottawa, Boys and Girls Club of Ottawa, Pinecrest Queensway Community Services Centre and West End Community Ventures. She was the founding Co-ordinator of Centre 507, a day program for those at risk of homelessness. She was the founding Executive Director of Daybreak Non-Profit Housing. She retired in 2019 after 23 years with the Anglican Diocese of Ottawa as Executive Director of Cornerstone Housing for Women. During that time, she opened three new affordable housing communities, including Ottawa’s first residence for older women at risk of homelessness.
      <br><br>
      Sue is the recipient of the Governor General’s Jubilee Medal, Marion Dewar Defender of the Public Good Award and the Community Ministries of Ottawa Social Justice Champion Award. In 2019, she received the Key to the City of Ottawa for her work with women, homelessness, and affordable housing.
      <br><br>
      Currently, Sue works part time as a policy advisor in the office of Ottawa City Councillor Theresa Kavanagh, concentrating on women and gender equity, affordable housing and age friendly cities. Sue focuses her volunteer attention on advocacy, planning and implementation of Ottawa’s first Oasis pilot project, an Aging at Home collaboration with Queen’s University.
      <br><br>
      Sue has been married for 40 years to Larry and has one son, Daniel.
    `,
        },
        {
            name: 'Lyne Ouellet',
            title: 'Healthy Seniors Pilot Project, Community Connectors, St. Thomas University/University of New Brunswick/ Projet Pilote sur les Aînés en Santé: Connecteurs pour la communauté',
            organization: '',
            image_file_name: 'Lyne_Ouellet.jpg',
            bio: `
            Lyne Ouellet is a research coordinator at St. Thomas University, as well as a PhD student at the University of New Brunswick. These roles overlap, providing a great opportunity to learn about the practical and theoretical aspects of community connector programs. All of this work has been well supported by many advocates and community leaders in the city of Fredericton, New Brunswick. This collective group identified social isolation and loneliness as being imperative to address in the community and their leadership has brought the community of Fredericton a long way to ensuring older adults are well connected with the information, resources and supports that are most meaningful to them. 
    `,
        },
        {
            name: 'Diane Weidner',
            title: 'Communications and Community Outreach Officer',
            organization: 'McGill University Dementia Education Program',
            image_file_name: 'new/DianeWeidner_McGillUniversity.jpg',
            bio: `
            Diane Weidner Communications and Community Outreach Officer McGill University Dementia Education Program
            <br><br>
            As the Communications and Community Outreach Officer for the McGill University Dementia Education Program, Diane builds relationships with community partners to educate and support people living with dementia and their care partners. A strategic communicator with extensive experience in the planning and execution of marketing and public relations campaigns, she creates compelling content to raise awareness of the many free, multilingual resources offered by the program.
            `,
        },
        {
            name: 'Ornella Gatore',
            title: 'Ontario Lead Coordinator',
            organization: 'The Good Companions Seniors Centre',
            image_file_name: 'new/Ornella.jpeg',
            bio: `
            Ornella is the Ontario Lead Coordinator at the Good Companions. She grew up in Toronto, holds an interdisciplinary bachelor’s degree in health sciences and is completing her master’s degree in education. She has worked in NGO's, medical and educational institutions & non-profits for over ten years.
            `,
        },
        {
            name: 'Anne Caines',
            title: '',
            organization: 'RECAA',
            image_file_name: 'anneـcaines.jpg',
            bio: '',
        },
    ],
});

let selectedSpeaker = ref(null);
</script>

<template>
    <DialogModal @close="selectedSpeaker = null" :show="selectedSpeaker">
        <template v-slot:title>
            <div class="flex items-start">
                <div
                    class="h-20 w-20 rounded-full bg-top bg-cover flex-none"
                    :style="{
                        backgroundImage: `url(/images/summit2024/speakers/${selectedSpeaker.image_file_name})`,
                    }"
                ></div>
                <div class="pl-4">
                    <h3
                        class="!text-2xl font-bold text-blue-dark"
                        v-html="selectedSpeaker.name"
                    ></h3>
                    <p class="text-grayt-400">{{ selectedSpeaker.title }}</p>
                    <p class="text-grayt-400 mb-2">
                        {{ selectedSpeaker.organization }}
                    </p>
                </div>
            </div>
        </template>
        <template v-slot:content>
            <div v-html="selectedSpeaker.bio"></div>
        </template>
    </DialogModal>
    <div
        class="mb-8"
        v-for="[category, categorySpeakers] in Object.entries(speakers)"
    >
        <h4
            class="text-blue-dark mb-8 text-2xl font-bold relative flex items-center whitespace-nowrap before:absolute before:h-6 before:w-1 before:bg-blue-dark before:left-0 before:top-1 after:w-full after:h-px after:bg-grayt-200"
        >
            <span class="px-4">{{ category }}</span>
        </h4>
        <div
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8"
        >
            <div
                class="px-12 sm:p-0"
                v-for="(speaker, index) in categorySpeakers"
                :key="index"
            >
                <div class="flex justify-center">
                    <div
                        class="h-60 w-60 rounded-full border border-grayt-200 bg-gray bg-cover bg-top"
                        :style="{
                            backgroundImage: `url(
                                        /images/summit2024/speakers/${speaker.image_file_name}
                                    )
                                    `,
                        }"
                    ></div>
                </div>
                <div class="flex flex-col items-center mt-4 text-center">
                    <h3
                        class="text-blue-dark !text-xl mb-1 font-bold"
                        v-html="speaker.name"
                    ></h3>
                    <p class="text-grayt-400">{{ speaker.title }}</p>
                    <p class="text-grayt-400 mb-1">
                        {{ speaker.organization }}
                    </p>
                    <a
                        class="text-blue"
                        href="javascript:void(0)"
                        @click="selectedSpeaker = speaker"
                        >View Bio</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
