<script setup>
import {onMounted, ref} from "vue";
import {watch} from "@vue/runtime-core";
import {floor} from "lodash";

let props = defineProps({
  registrationLink: {
    type: String,
    required: true
  }
})
let currentDate = ref();
let diff = ref();
let days = ref();
let hours = ref();
let mins = ref();
let secs = ref();
const eventDate = new Date('2024-06-02 08:00:00');

onMounted(() => {
  fetch("/api/server-time/America/Toronto")
      .then(res => res.json())
      .then(res => currentDate.value = Date.parse(res));
});

watch(currentDate, (value, oldValue) => {
  if (currentDate) {
    startTimer();
  }
});

const startTimer = () => {
  diff.value = eventDate - currentDate.value;

  setInterval(() => {
    // console.log(diff.value);
    secs.value = padZero(floor((diff.value / 1000) % 60));
    mins.value = padZero(floor((diff.value / 1000 / 60) % 60));
    hours.value = padZero(floor((diff.value / 1000 / 60 / 60) % 24));
    days.value = padZero(floor((diff.value / 1000 / 60 / 60 / 24)));
    diff.value = diff.value - 1000;
  }, 1000);
}

const padZero = (num) => String(num).padStart(2, '0');
</script>

<template>
  <div v-if="days" class="flex items-center">
    <div class="h-[100px] sm:h-[150px] relative flex-1 flex items-center justify-center flex-col bg-gray rounded-tl-xl rounded-bl-xl">
      <span class="text-xl sm:text-3xl font-bold text-blue-dark">{{ days }}</span>
      <span class="font-light text-gray-darkest pt-1 text-xs sm:text-sm">Days</span>
      <span class="flex flex-col absolute right-0">
        <span class="rounded-full h-[5px] w-[5px] bg-blue bg-opacity-50 mb-2"></span>
        <span class="rounded-full h-[5px] w-[5px] bg-blue bg-opacity-50"></span>
      </span>
    </div>
    <div class="h-[100px] sm:h-[150px] relative flex-1 flex items-center justify-center flex-col bg-gray">
      <span class="text-xl sm:text-3xl font-bold text-blue-dark">{{ hours }}</span>
      <span class="font-light text-gray-darkest pt-1 text-xs sm:text-sm">Hours</span>
      <span class="flex flex-col absolute right-0">
        <span class="rounded-full h-[5px] w-[5px] bg-blue bg-opacity-50 mb-2"></span>
        <span class="rounded-full h-[5px] w-[5px] bg-blue bg-opacity-50"></span>
      </span>
    </div>
    <div class="h-[100px] sm:h-[150px] relative flex-1 flex items-center justify-center flex-col bg-gray">
      <span class="text-xl sm:text-3xl font-bold text-blue-dark">{{ mins }}</span>
      <span class="font-light text-gray-darkest pt-1 text-xs sm:text-sm">Minutes</span>
      <span class="flex flex-col absolute right-0">
        <span class="rounded-full h-[5px] w-[5px] bg-blue bg-opacity-50 mb-2"></span>
        <span class="rounded-full h-[5px] w-[5px] bg-blue bg-opacity-50"></span>
      </span>
    </div>
    <div class="h-[100px] sm:h-[150px] relative flex-1 flex items-center justify-center flex-col bg-gray rounded-tr-xl rounded-br-xl lg:rounded-none">
      <span class="text-xl sm:text-3xl font-bold text-blue-dark">{{ secs }}</span>
      <span class="font-light text-gray-darkest pt-1 text-xs sm:text-sm">Seconds</span>
    </div>
    <a :href="registrationLink" class="hidden lg:flex group h-[150px] flex-1 items-center justify-center flex-col bg-blue-dark rounded-tr-xl rounded-br-xl">
      <span class="text-white uppercase group-hover:font-bold group-hover:text-lg">Register Now!</span>
    </a>
  </div>

  <div class="flex justify-around">
    <a :href="registrationLink" class="lg:hidden mt-6 button primary">Register Now!</a>
  </div>
</template>

<style scoped>

</style>
