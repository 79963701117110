<script setup>
import { onMounted, ref } from 'vue'
import Asterisk from "@/components/shared/Asterisk";

defineProps({
    modelValue: String,
    label: String,
    error: String,
    disabled: { type: Boolean, default: false },
    icon: { type: Boolean, default: false },
    button: { type: Boolean, default: false },
    asterisk: { type: Boolean, default: false }
})

defineEmits(['update:modelValue'])

const input = ref(null)

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus()
    }
})

defineExpose({ focus: () => input.value.focus() })
</script>

<template>
    <div class="floating" :class="{'invalid': error}">
        <div class="floating-inner">
            <div class="floating-control text-left"
                :class="{
                    'floating-control--has-icon': icon,
                    'floating-control--has-button': button,
                }">
                <label :for="label" class="sr-only">
                    {{ label }}
                </label>
                <input
                    ref="input"
                    class="floating-input"
                    :class="{
                        '!border-gray-dark cursor-not-allowed': icon
                    }"
                    placeholder=" "
                    :disabled="disabled"
                    :value="modelValue"
                    @input="$emit('update:modelValue', $event.target.value)"
                >
                <div class="floating-text">
                    {{ label }} <Asterisk v-if="asterisk" />
                </div>
                <slot />
            </div>
        </div>
        <div v-if="error" class="invalid--feedback pt-2 flex items-center space-x-2 text-sm">
            <i class="fa-solid fa-times"></i>
            <span>{{ error }}</span>
        </div>
    </div>
</template>
