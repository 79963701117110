<template>
    <div
        @mouseleave="showLessEvents"
        @mouseenter="clearShowInterval"
        class="flex flex-col justify-start text-left h-full z-10 p-2 max-w-[225px]"
        :class="{
            'bg-white absolute w-[120%] h-auto left-[-10%] shadow-xl p-3':
            showAll === day.id,
        }"
    >
        <div
            class="h-6 w-6 flex justify-center items-center rounded-full"
            :class="{ 'bg-blue text-white': day.isToday }"
        >
            {{ day.day }}
        </div>
        <div class="flex-grow">
            <ul :class="{ 'space-y-1' : showAll === day.id }">
                <li
                    class="relative group w-full"
                    v-for="event in filteredEvents"
                    :key="event.key"
                >
                    <a :href="`${event.customData.url}`" class="group flex">
                        <p
                            class="flex-auto truncate font-medium text-blue-dark group-hover:text-blue transition-all duration-100 oveflow-hidden delay-300 group-hover:delay-300"
                        >
                            {{ event.customData.title }}
                        </p>
                        <time
                            datetime=""
                            class="ml-3 hidden flex-none text-blue-dark group-hover:text-blue xl:block transition-all duration-100 oveflow-hidden delay-300 group-hover:delay-300"
                        >
                            {{ event.customData.formated_date.start_hour }}
                        </time>
                    </a>
                    <div
                        class="event-card bg-white rounded-lg absolute overflow-hidden left-[127%] -bottom-[185px] w-[22rem] shadow-2xl z-30 transition-all duration-100 oveflow-hidden delay-300 opacity-0 invisible group-hover:visible group-hover:opacity-100 group-hover:delay-300"
                        :class="{ 'expanded left-[122%]': showAll === day.id }"
                    >
                        <div
                            class="w-full h-44 bg-cover bg-center bg-no-repeat bg-blue-light"
                            :style="[
                                event.customData.cover
                                    ? {
                                          'background-image':
                                              'url(' +
                                              event.customData.image +
                                              ')',
                                      }
                                    : '',
                            ]"
                        ></div>
                        <div class="px-4 py-6 space-y-4">
                            <div
                                class="flex justify-between items-start text-blue-dark"
                            >
                                <div
                                    class="flex flex-col sm:flex-row items-center"
                                >
                                    <div
                                        class="sm:pr-4 pb-4 sm:pb-0 border-b-2 sm:border-b-0 sm:border-r-2 border-r-blue border-b-blue sm:text-center"
                                    >
                                        <h2 class="text-3xl text-red">
                                            {{
                                                event.customData.formated_date
                                                    .start_day
                                            }}
                                        </h2>
                                        <h5 class="text-lg font-medium">
                                            {{
                                                event.customData.formated_date.start_month.slice(
                                                    0,
                                                    3
                                                )
                                            }}
                                        </h5>
                                    </div>
                                    <div
                                        class="sm:pl-4 pt-4 sm:pt-0 font-medium"
                                    >
                                        <p class="text-sm sm:text-base">
                                            <span
                                                >{{
                                                    event.customData
                                                        .formated_date
                                                        .start_month
                                                }}&nbsp;</span
                                            >
                                            <span>{{
                                                event.customData.formated_date
                                                    .start_day
                                            }}</span
                                            >,
                                            <span>{{
                                                event.customData.formated_date
                                                    .year
                                            }}</span
                                            >-<span>{{
                                                event.customData.formated_date
                                                    .start_hour
                                            }}</span>
                                        </p>
                                        <p class="text-sm sm:text-base">
                                            ({{
                                                event.customData.formated_date
                                                    .timezone
                                            }})
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <a :href="`${event.customData.url}`">
                                <h5
                                    class="text-xl text-blue-dark line-clamp-2 hover:text-blue-darkest mt-2"
                                >
                                    {{ event.customData.title }}
                                </h5>
                            </a>
                            <p class="text-base sm:text-normal line-clamp-4">
                                {{ event.customData.summary }}
                            </p>
                        </div>
                    </div>
                    <div
                        class="event-card-triangle w-11 overflow-hidden inline-block absolute left-full z-30 -top-[22px] transition-all duration-100 oveflow-hidden delay-300 opacity-0 invisible group-hover:visible group-hover:opacity-100 group-hover:delay-300"
                    >
                        <div
                            class="h-16 bg-white -rotate-45 transform origin-top-right shadow-left"
                        ></div>
                    </div>
                </li>
                <li
                    class="cursor-pointer bg-blue/10 hover:bg-blue/20 text-blue-dark px-2 py-px mt-1 rounded-md text-center"
                    v-if="(attributes?.length > 5 && showAll != day.id)"
                    @click="showMoreEvents()"
                >
                    view {{ attributes?.length - 5 }} more
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue';

const showAll = ref(false);
const events = ref([]);
const addListeners = ref(false)
let showInterval = null

const props = defineProps({
    day: {
        type: Object,
    },
    attributes: {
        type: Array,
        default: [],
    },
});

watchEffect(() => {
    events.value = props.attributes;
});

const filteredEvents = computed(() => {
    if (showAll.value === props.day.id) {
        return events.value;
    }
    return events.value.slice(0, 5);
});

function showMoreEvents() {
    showAll.value = props.day?.id;
}

function showLessEvents() {
    showInterval = setTimeout(() => {
        showAll.value = false;
    }, 1000);
}

function clearShowInterval() {
  clearInterval(showInterval)
}
</script>
