<template>
    <div id="group-quick-links" class="space-y-4 lg:space-y-6">
        <div class="bg-gray p-8 sm:p-12 rounded">
            <h4 class="text-blue-dark font-bold mb-4 lg:mb-6">About</h4>
            <ul class="space-y-6">
                <li class="flex space-x-4">
                    <div>
                        <i
                            class="fa-regular fa-user text-xl text-blue-dark"
                        ></i>
                    </div>
                    <div v-if="!readMore">
                        <span
                            class="text-normal text-gray-darkest"
                            v-html="group.about?.slice(0, 200)"
                        ></span>
                        <a
                            v-if="group.about?.length > 200"
                            class="text-blue-dark text-normal"
                            href="javascript:"
                            @click="readMore = !readMore"
                        >
                            ... Read more</a
                        >
                    </div>
                    <div v-if="readMore">
                        <span
                            class="text-normal text-gray-darkest"
                            v-html="group.about"
                        ></span>
                        <div>
                            <a
                                class="text-blue-dark text-normal"
                                href="javascript:"
                                @click="readMore = !readMore"
                                >See less</a
                            >
                        </div>
                    </div>
                </li>
                <li class="flex space-x-4">
                    <div>
                        <svg
                            class="text-blue-dark"
                            fill="none"
                            height="25"
                            viewBox="0 0 24 25"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17 21.25V19.25C17 18.1891 16.5786 17.1717 15.8284 16.4216C15.0783 15.6714 14.0609 15.25 13 15.25H5C3.93913 15.25 2.92172 15.6714 2.17157 16.4216C1.42143 17.1717 1 18.1891 1 19.25V21.25"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            <path
                                d="M9 11.25C11.2091 11.25 13 9.45914 13 7.25C13 5.04086 11.2091 3.25 9 3.25C6.79086 3.25 5 5.04086 5 7.25C5 9.45914 6.79086 11.25 9 11.25Z"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            <path
                                d="M23 21.2499V19.2499C22.9993 18.3636 22.7044 17.5027 22.1614 16.8022C21.6184 16.1017 20.8581 15.6015 20 15.3799"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            <path
                                d="M16 3.37988C16.8604 3.60018 17.623 4.10058 18.1676 4.80219C18.7122 5.5038 19.0078 6.36671 19.0078 7.25488C19.0078 8.14305 18.7122 9.00596 18.1676 9.70757C17.623 10.4092 16.8604 10.9096 16 11.1299"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                        </svg>
                    </div>
                    <div>
                        <p class="text-normal text-gray-darkest">
                            {{ group.type == 1 ? 'Close' : 'Open' }} Group ·
                            <span v-text="userCount"> </span> members
                        </p>
                    </div>
                </li>
            </ul>
        </div>

        <!-- Check if resources exist -->
        <div v-if="resources?.length" class="bg-gray p-8 sm:p-12 rounded">
            <h4 class="text-blue-dark font-bold mb-4 lg:mb-6">
                Featured Resources
            </h4>
            <ul class="space-y-6">
                <!-- Get last 3 resources -->
                <li
                    v-for="resource in resources"
                    :key="resource"
                    class="flex space-x-4"
                >
                    <div>
                        <svg
                            class="text-blue-dark"
                            fill="none"
                            height="25"
                            viewBox="0 0 24 31"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.07031 25.75C4.07031 25.087 4.3337 24.4511 4.80255 23.9822C5.27139 23.5134 5.90727 23.25 6.57031 23.25H20.0703"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            <path
                                d="M6.57031 8.25H20.0703V28.25H6.57031C5.90727 28.25 5.27139 27.9866 4.80255 27.5178C4.3337 27.0489 4.07031 26.413 4.07031 25.75V10.75C4.07031 10.087 4.3337 9.45107 4.80255 8.98223C5.27139 8.51339 5.90727 8.25 6.57031 8.25V8.25Z"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                        </svg>
                    </div>
                    <div>
                        <a
                            :href="`/group/${groupSlug}/resource/${resource.slug}`"
                            class="text-blue-dark"
                        >
                            <p class="text-normal" v-text="resource.title"></p>
                        </a>
                    </div>
                </li>

                <li>
                    <!-- Navigate to resources -->
                    <a
                        :href="`/group/${groupSlug}/resource`"
                        class="text-blue-dark text-normal font-medium space-x-4"
                    >
                        <span>SHOW ALL</span>
                        <i class="fa-solid fa-arrow-right text-blue"></i>
                    </a>
                </li>
            </ul>
        </div>

        <div v-if="discussions?.length" class="bg-gray p-8 sm:p-12 rounded">
            <h4 class="text-blue-dark font-bold mb-4 lg:mb-6">
                Featured Discussions
            </h4>
            <ul class="space-y-6">
                <li
                    v-for="discussion in discussions"
                    :key="discussion"
                    class="flex space-x-4"
                >
                    <div>
                        <ChatAlt2Icon class="w-6 text-xl text-blue-dark" />
                    </div>
                    <div>
                        <a
                            :href="`/group/${groupSlug}/discussion/${discussion.slug}`"
                            class="text-blue-dark"
                        >
                            <p
                                class="text-normal"
                                v-html="discussion.title"
                            ></p>
                        </a>
                    </div>
                </li>

                <li>
                    <!-- Navigate to discussion -->
                    <a
                        :href="`/group/${groupSlug}/discussion`"
                        class="text-blue-dark text-normal font-medium space-x-4"
                    >
                        <span>SHOW ALL</span>
                        <i class="fa-solid fa-arrow-right text-blue"></i>
                    </a>
                </li>
            </ul>
        </div>
        <!-- Check if events exist -->

        <div v-if="events?.length" class="bg-gray p-8 sm:p-12 rounded">
            <h4 class="text-blue-dark font-bold mb-4 lg:mb-6">
                Featured Events
            </h4>
            <ul class="space-y-6">
                <!-- Get last 3 events -->
                <li v-for="event in events" :key="event" class="flex space-x-4">
                    <div>
                        <ArchiveIcon class="w-6 text-xl text-blue-dark" />
                    </div>
                    <div>
                        <a
                            :href="`/group/${groupSlug}/event/${event.slug}`"
                            class="text-blue-dark"
                        >
                            <p class="text-normal" v-text="event.title"></p>
                        </a>
                    </div>
                </li>
                <li>
                    <!-- Navigate to events -->
                    <a
                        :href="`/group/${groupSlug}/event`"
                        class="text-blue-dark text-normal font-medium space-x-4"
                    >
                        <span>SHOW ALL</span>
                        <i class="fa-solid fa-arrow-right text-blue"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { ArchiveIcon, ChatAlt2Icon } from '@heroicons/vue/outline';

const props = defineProps({
    groupId: String,
    groupSlug: String,
});

const resources = ref('');
const events = ref('');
const discussions = ref('');
const group = ref('');
const userCount = ref();
const readMore = ref(false);

onMounted(() => {
    // Get group
    axios
        .get(`/api/groups/${props.groupId}`)
        .then((response) => (group.value = response.data.data));

    // Get resources
    axios
        .get(`/api/get-group-featured/${props.groupSlug}/resource?limit=3`)
        .then((response) => (resources.value = response.data));

    // Get events
    axios
        .get(`/api/get-group-featured/${props.groupSlug}/event?limit=3`)
        .then((response) => (events.value = response.data));

    // Get discussions
    axios
        .get(`/api/get-group-featured/${props.groupSlug}/discussion?limit=3`)
        .then((response) => (discussions.value = response.data));

    axios
        .get(`/api/group/${props.groupSlug}/member-count`)
        .then((response) => (userCount.value = response.data.user_counts));
});
</script>
