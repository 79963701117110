<template>
  <label for="group_id" class="form-input-label">{{ label }}</label>
  <input type="file" id="uploadfiles" @change="selectFile" accept=".jpg, .jpeg, .png, .mp4, .pdf, .ppt, .pptx ,.xlsx ,.xls ,.doc ,.docx, .dotx" ref="uploadfiles" multiple class="mt-1 form-input  w-full !border-gray-dark cursor-not-allowed"/>
  <template v-if="errorMessage">
      <p v-for="(error, key) in errorMessage" :key="key" class="mt-2 text-sm text-red-500">
        {{ error[0] }}
      </p>
  </template>
  <div v-if="files.length > 0">
      <div class="text-sm leading-5 font-medium mt-4">
          <table class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg w-full">
            <tr>
                <th class="text-left p-2" scope="col">Name</th>
                <th class="text-left p-2" scope="col">Size</th>
                <th class="text-left p-2" scope="col">Type</th>
            </tr>
            <tr v-for="(file, index) in files" :key="index">
                <td class="text-left p-2">{{ file.name }}</td>
                <td class="text-left p-2">{{ parseFloat(file.size/1048576).toFixed(2) }}MB</td>
                <td class="text-left p-2">
                  {{ file.type }}
                </td>
            </tr>
        </table>
      </div>
  </div>
</template>

<script setup>

import {onMounted, reactive, ref, watch ,watchEffect} from 'vue';

const props = defineProps({
    id: String,
    label: String,
    modelValue: Object,
    errorMessage: Array,

});

const emit = defineEmits(['update:modelValue']);
const files = ref(props.modelValue)
// Allowed file types
const fileTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'video/mp4',
    'application/pdf',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/msword',
    'application/vnd.ms-office',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

// Select files attachment
function selectFile(event) {

  files.value = event.dataTransfer ? event.dataTransfer.files : event.target.files;

  let i = 0;

  while(i < files.value.length){

    // Check file type
    if (!fileTypes.includes(files.value[i].type)) {
        files.value = [];
        alert('Please select a valid file. Thanks');
        resetAttachFileInput();
    }

    // Check file size
    const maxSizeInBytes = 20 * 1024 * 1024;
    const fileSizeInBytes = files.value[i].size;

    if (fileSizeInBytes > maxSizeInBytes) {
        files.value = [];
        alert('Sorry! Your file size should be less than 20MB.');
        resetAttachFileInput();
        break;
    }
    i++;
  };
  emit("update:modelValue", files.value)
}

function resetAttachFileInput(){
  document.getElementById("uploadfiles").value = "";
}


</script>
