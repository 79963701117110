<template>
    <div>
        <div class="relative cursor-default select-none" :class="{'pr-8': files}">
            <div class="flex justify-between items-center" v-if="customCoverLocation">
                <label class="block text-sm font-medium text-gray-700" for="role">Cover Photo</label>
                <div class="flex items-center gap-x-2">
                    <label class="block text-sm font-medium text-gray-700 whitespace-nowrap" for="role">Cover Location: </label>
                    <select @change="changeCoverLocation" v-model="coverLocation" name="cover_location" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 pl-4 pr-8 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        <option value="0" selected>Right</option>
                        <option value="1">Middle</option>
                    </select>
                </div>
            </div>
            <span v-if="files" class="absolute inset-y-0 right-0 flex items-center text-indigo-400 cursor-pointer"
                @click="reset()">
                <RefreshIcon class="h-5 w-5 text-indigo-600 hover:text-indigo-900" />
            </span>
        </div>
        <div class="mt-1 border-2 border-[#B3B3B3] border-dashed rounded-md px-6 pt-10 pb-12 flex justify-center"
            @drop.prevent="selectFile" @dragenter.prevent @dragover.prevent>
            <div class="space-y-1 text-center">
                <svg v-if="!props.file && !preview" aria-hidden="true" class="mx-auto h-12 w-12 text-gray-200" fill="none"
                    stroke="#B3B3B3" viewBox="0 0 48 48">
                    <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                </svg>
                <img v-if="preview" :src="preview" height="300" width="400" />
                <img v-if="props.file && !files" :src="props.file" height="300" width="400" />
                <template v-if="files">
                    <div class="text-sm leading-5 font-medium text-pink-600 truncate">
                        <p>{{ files[0].name }} ({{ files[0].size }})</p>
                    </div>
                </template>
                <div class="flex justify-center text-sm text-gray-300 mx-auto">
                    <label
                        class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                        for="file-upload">
                        <span>Upload a file <Asterisk v-if="asterisk" /> </span>
                        <input id="file-upload" accept="image/png, image/jpg, image/jpeg" class="sr-only" name="file-upload"
                            type="file" @change="selectFile" />
                    </label>
                    <p class="pl-1">just by clicking here</p>
                </div>
                <p class="text-xs">PNG, JPG, JPEG up to 2MB</p>
            </div>
        </div>
        <p v-if="ErrorMessage" class="mt-2 text-sm text-red-500">
            {{ ErrorMessage }}
        </p>
    </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import { RefreshIcon } from '@heroicons/vue/outline';
import Asterisk from "./Asterisk.vue";

const files = ref(null);
const preview = ref(null);
const emit = defineEmits(['fileSelected', 'changeCoverLocation']);
const coverLocation = ref(0)

const props = defineProps({
    errors: {
        type: Array,
        default: [],
    },
    asterisk: {
          type: Boolean,
          default: false,
      },
    file: {
        default: null,
    },
    customCoverLocation: {
        default: false,
        type: Boolean,
    },
    coverLocation: {
        default: 0,
        type: Number,
    },
    ErrorMessage: String,
});

function selectFile(event) {
    files.value = event.dataTransfer
        ? event.dataTransfer.files
        : event.target.files;

    // Check file type (file should be an image)
    if (files.value[0].type.split('/')[0] == 'image') {
        preview.value = URL.createObjectURL(files.value[0]);
        emit('fileSelected', files.value);
    } else {
        files.value = '';
        preview.value = '';
        emit('fileSelected', '');

        alert('Please select only image.');
    }

    // check if files size be greater then 2MB
    if (files.value[0] && files.value[0].size / 2000000 > 1) {
        files.value = '';
        preview.value = '';
        emit('fileSelected', '');

        alert('Sorry! your image size should be less then 2MB.');
    }
}

function reset() {
    files.value = null;
    preview.value = null;
}

function changeCoverLocation() {
    emit('changeCoverLocation', coverLocation.value)
}

watchEffect(() => {
    coverLocation.value = props.coverLocation
})
</script>
