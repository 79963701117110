<template>
  <div class="container">
    <div class="sm:flex sm:items-center">
      <div v-if="!onWebsite" class="sm:flex-auto">
        <Breadcrumb
          :pages="[
            { name: 'Event List', href: '/events', current: false },
            { name: 'Edit Event', href: null, current: true },
          ]"
        />
      </div>
      <div v-if="event" class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <router-link :to="`/events/${event.id}`">
          <template v-if="!onWebsite">
            <button
              class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              type="button"
            >
              View
            </button>
          </template>
        </router-link>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <form action="#" method="POST" @submit.prevent="submit">
          <div
            :class="{
              'shadow sm:rounded-md sm:overflow-hidden': !onWebsite,
            }"
          >
            <div
              :class="{
                'bg-white py-6 px-4 space-y-6 sm:p-6': !onWebsite,
              }"
            >
              <fieldset v-if="!onWebsite">
                <AppLabel asterisk for="website-checkboxes"> Website</AppLabel>
                <div class="mt-2 flex items-center space-x-2">
                  <AppCheckbox
                    id="website_id"
                    :checked="true"
                    class="cursor-not-allowed"
                    disabled="disabled"
                    name="website_id"
                  />
                  <label v-if="event.website" class="font-medium text-sm text-gray-700 cursor-not-allowed">{{
                    event.website.name
                  }}</label>
                </div>
              </fieldset>
              <div class="grid grid-cols-6 gap-6">
                <!-- Group -->
                <div v-if="!onWebsite" class="col-span-6 sm:col-span-3">
                  <group-component
                    v-if="event.website"
                    id="group"
                    v-model="form.group_id"
                    :ErrorMessage="errors.group_id ? errors.group_id[0] : null"
                    :websiteId="event.website.id"
                    labelText="Group"
                  />
                </div>

                <!-- Title -->
                <div class="col-span-3">
                  <template v-if="!onWebsite">
                    <input-component
                      id="title"
                      v-model="form.title"
                      :ErrorMessage="errors.title ? errors.title[0] : null"
                      asterisk
                      labelText="Title"
                      placeholder="Title"
                    />
                  </template>
                  <template v-else>
                    <AppInput v-model="form.title" label="Title" />
                    <AppInputError v-if="errors.title" :message="errors.title[0]" class="mt-2" />
                  </template>
                </div>

                <!-- Body -->
                <div class="col-span-6">
                  <fieldset>
                    <label class="block text-sm font-medium text-gray-700" for="street-address"
                      >Body
                      <Asterisk />
                    </label>
                    <AppTextEditor v-model="form.body" />
                    <p v-if="errors.body" class="mt-2 text-sm text-red-500">
                      {{ errors.body[0] }}
                    </p>
                  </fieldset>
                </div>

                <!-- summary -->
                <div class="col-span-6 sm:col-span-3">
                  <template v-if="!onWebsite">
                    <input-component
                      id="summary"
                      v-model="form.summary"
                      :ErrorMessage="errors.summary ? errors.summary[0] : null"
                      labelText="Summary"
                      placeholder="Summary"
                    />
                  </template>
                  <template v-else>
                    <AppInput v-model="form.summary" label="Summary" />
                    <AppInputError v-if="errors.summary" :message="errors.summary[0]" class="mt-2" />
                  </template>
                </div>

                <!-- Registeration link -->
                <div class="col-span-6 sm:col-span-3">
                  <template v-if="!onWebsite">
                    <input-component
                      id="register"
                      v-model="form.register"
                      :ErrorMessage="errors.register ? errors.register[0] : null"
                      labelText="Registration Link"
                      placeholder="Registration Link"
                    />
                  </template>
                  <template v-else>
                    <AppInput v-model="form.register" label="Registration Link" />
                    <AppInputError v-if="errors.register" :message="errors.register[0]" class="mt-2" />
                  </template>
                </div>
                <!-- Organizer -->
                <div class="col-span-6 sm:col-span-3">
                  <template v-if="!onWebsite">
                    <input-component
                      id="organizer"
                      v-model="form.organizer"
                      :ErrorMessage="errors.organizer ? errors.organizer[0] : null"
                      labelText="Organizer"
                      placeholder="Organizer"
                    />
                  </template>
                  <template v-else>
                    <AppInput v-model="form.organizer" label="Organizer" />
                    <AppInputError v-if="errors.organizer" :message="errors.organizer[0]" class="mt-2" />
                  </template>
                </div>

                <!-- Location -->
                <div class="col-span-6 sm:col-span-3">
                  <template v-if="!onWebsite">
                    <input-component
                      id="location"
                      v-model="form.location"
                      :ErrorMessage="errors.location ? errors.location[0] : null"
                      labelText="Location"
                      placeholder="Location"
                    />
                  </template>
                  <template v-else>
                    <AppInput v-model="form.location" label="Location" />
                    <AppInputError v-if="errors.location" :message="errors.location[0]" class="mt-2" />
                  </template>
                </div>

                <!-- Start Date -->
                <div class="col-span-6 sm:col-span-3">
                  <div class="col-span-6 sm:col-span-3">
                    <label class="block text-sm font-medium text-gray-700" for="start_date"
                      >Start Date ({{ adminPanelTz(onWebsite) }})
                      <Asterisk />
                    </label>

                    <datepicker id="start_date" v-model="form.start_date" model-type="format"></datepicker>
                    <p v-if="errors.start_date" class="mt-2 text-sm text-red-500">
                      {{ errors.start_date[0] }}
                    </p>
                  </div>
                </div>

                <!-- End Date -->
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700" for="end_date"
                    >End Date ({{ adminPanelTz(onWebsite) }})
                    <Asterisk />
                  </label>

                  <datepicker id="end_date" v-model="form.end_date" model-type="format"></datepicker>
                  <p v-if="errors.end_date" class="mt-2 text-sm text-red-500">
                    {{ errors.end_date[0] }}
                  </p>
                </div>

                <!-- Cover Photo -->
                <div class="col-span-6">
                  <DropFile
                    :coverLocation="form.cover_location"
                    :file="form.cover"
                    customCoverLocation
                    @changeCoverLocation="form.cover_location = $event"
                    @fileSelected="onSelectFile"
                  />
                </div>
                <!-- Alternative Text-->
                <div class="col-span-6 sm:col-span-6">
                  <template v-if="!onWebsite">
                    <input-component
                      id="register"
                      v-model="form.alt"
                      :ErrorMessage="errors.alt ? errors.alt[0] : null"
                      labelText="Alternative Text"
                      placeholder="Alternative Text"
                    />
                  </template>
                  <template v-else>
                    <AppInput v-model="form.alt" label="Alternative Text" />
                    <AppInputError v-if="errors.alt" :message="errors.alt[0]" class="mt-2" />
                  </template>
                </div>

                <!-- Featured -->
                <div v-if="!onWebsite && hasAccessToAdminPanel" class="col-span-3 sm:col-span-2">
                  <div class="flex items-center space-x-4">
                    <AppCheckbox id="featured" v-model:checked="form.featured" name="featured" />
                    <label class="block text-sm font-medium text-gray-700" for="featured">Featured in Home Page</label>
                  </div>
                  <p v-if="errors.featured" class="mt-2 text-sm text-red-500">
                    {{ errors.featured[0] }}
                  </p>
                </div>

                <!-- Group Featured -->
                <div v-if="userRole != 'site users' && hasAccessToAdminPanel" class="col-span-3 sm:col-span-2">
                  <div class="flex items-center space-x-4">
                    <AppCheckbox id="group_featured" v-model:checked="form.group_featured" name="group_featured" />
                    <label class="block text-sm font-medium text-gray-700" for="group_featured"
                      >Featured in group</label
                    >
                  </div>
                  <p v-if="errors.group_featured" class="mt-2 text-sm text-red-500">
                    {{ errors.group_featured[0] }}
                  </p>
                </div>

                <!-- Published -->
                <div v-if="userRole != 'site users' && hasAccessToAdminPanel" class="col-span-3 sm:col-span-2">
                  <div class="flex items-center space-x-4">
                    <AppCheckbox id="is_published" v-model:checked="form.is_published" name="is_published" />
                    <label class="block text-sm font-medium text-gray-700" for="is_published">Published</label>
                  </div>
                  <p v-if="errors.is_published" class="mt-2 text-sm text-red-500">
                    {{ errors.is_published[0] }}
                  </p>
                </div>

                <div v-if="userRole != 'site users' && hasAccessToAdminPanel" class="col-span-3 sm:col-span-2">
                  <div class="flex items-center space-x-4">
                    <AppCheckbox id="from_united_way" v-model:checked="form.from_united_way" name="from_united_way" />
                    <label class="block text-sm font-medium text-gray-700" for="from_united_way">From United Way</label>
                  </div>
                  <p v-if="errors.from_united_way" class="mt-2 text-sm text-red-500">
                    {{ errors.from_united_way[0] }}
                  </p>
                </div>

                <div class="col-span-6 sm:col-span-6">
                  <!-- Subject Area -->
                  <fieldset>
                    <legend class="text-base font-medium text-gray-900">Subject Area</legend>
                    <MultiCheckbox
                      v-model="form.subject_area_id"
                      :error="errors.subject_area_id ? errors.subject_area_id[0] : null"
                      :options="subjectAreas"
                    />
                  </fieldset>

                  <!-- Categories -->
                  <fieldset class="mt-6">
                    <legend class="text-base font-medium text-gray-900">Category</legend>
                    <MultiCheckbox
                      v-model="form.category_id"
                      :error="errors.category_id ? errors.category_id[0] : null"
                      :options="categories"
                    />
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                :class="{
                  'bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500':
                    !onWebsite,
                  'button primary': onWebsite,
                  'disabled:!cursor-wait': isDisabled,
                }"
                :disabled="isDisabled"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </form>
        <DropZoneComponent
          v-if="!onWebsite"
          :deleting="true"
          :files="event.files"
          :uploading="true"
          modelType="App\Models\Event"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import request from '@/environment/request'
import '@vuepic/vue-datepicker/dist/main.css'
import { onMounted, reactive, ref, watch } from 'vue'
import Datepicker from '@vuepic/vue-datepicker'
import { useRoute, useRouter } from 'vue-router'
import DropFile from '@/components/shared/DropFile'
import Breadcrumb from '@/components/shared/Breadcrumb'
import InputComponent from '@/components/shared/InputComponent'
import GroupComponent from '@/components/shared/GroupComponent'
import DropZoneComponent from '@/components/shared/DropZoneComponent'
import AppLabel from '@/components/shared/Label'
import AppCheckbox from '@/components/shared/Checkbox'
import AppTextEditor from '@/components/shared/TextEditor'
import MultiCheckbox from '@/components/shared/MultiCheckbox'
import subjectAreasService from '@/services/subject-area'
import categoriesService from '@/services/category'
import { adminPanelTz } from '../../support/utils'
import permsMixin from '@/services/permsMixin'
import Asterisk from '@/components/shared/Asterisk'

// Imports for website section
import AppInput from '@js/Components/Shared/Input'
import AppInputError from '@js/Components/Shared/InputError'

const group = ref([])
const event = ref('')
const errors = ref('')
const route = useRoute()
const websiteId = ref(true)

const props = defineProps({
  onWebsite: Boolean,
  group: Object,
  eventId: String,
  userRole: String,
})

let form = reactive({
  body: '',
  summary: '',
  register: '',
  title: '',
  alt: '',
  cover: null,
  featured: false,
  end_date: '',
  location: '',
  group_id: '',
  organizer: '',
  website_id: [],
  start_date: '',
  from_united_way: false,
  category_id: [],
  is_published: false,
  group_featured: false,
  subject_area_id: [],
  id: props.onWebsite ? props.eventId : route.params.id,
  cover_location: 0,
})

const types = reactive([
  { id: 1, name: 'Public' },
  { id: 3, name: 'Hidden' },
  { id: 2, name: 'Private' },
])

const featurs = reactive([
  { id: 1, name: 'Yes' },
  { id: 2, name: 'No' },
])

let id = props.onWebsite ? props.eventId : route.params.id
const router = useRouter()
const isDisabled = ref(false)
const fileSelected = ref(false)
const subjectAreas = ref([])
const categories = ref([])

function submit() {
  isDisabled.value = true
  const config = { headers: { 'Content-Type': 'multipart/form-data' } }

  let formData = new FormData()

  formData.append('_method', 'PATCH')
  formData.append('featured', form.featured)
  formData.append('title', form.title)
  formData.append('alt', form.alt)
  formData.append('body', form.body)
  formData.append('summary', form.summary)
  formData.append('register', form.register)
  formData.append('organizer', form.organizer)
  formData.append('location', form.location)
  formData.append('start_date', form.start_date)
  formData.append('end_date', form.end_date)
  formData.append('is_published', form.is_published)
  formData.append('group_id', form.group_id)
  formData.append('subject_area_id', form.subject_area_id)
  formData.append('category_id', form.category_id)
  formData.append('from_united_way', form.from_united_way)
  formData.append('group_featured', form.group_featured)
  formData.append('cover', fileSelected.value == true ? form.cover : '')
  formData.append('cover_location', form.cover_location)

  request(!props.onWebsite)
    .post(`events/${id}`, formData, config)
    .then(() => {
      if (props.onWebsite) {
        _toast.success({
          title: 'Updated event',
          message: 'Event has been successfully updated',
        })
        setTimeout(() => (location.href = `/group/${props.group.slug}/event/${event.value.slug}`), 500)
      } else {
        router.push({ path: `/events/${id}` })
      }
    })
    .catch((err) => {
      isDisabled.value = false
      if (err.response && err.response.status == 422) {
        errors.value = err.response.data.errors
      }
    })
}

onMounted(() => {
  request(!props.onWebsite)
    .get('events/' + id)
    .then((response) => {
      event.value = response.data.data
      assignValue(event.value)

      if (!event.value.group?.id) {
        getSubjectAreas(form.group_id)
        getCategories(form.group_id)
      }
    })
})

function assignValue(event) {
  form.id = event.id
  form.body = event.body
  form.cover = event.cover
  form.title = event.title
  form.alt = event.alt
  form.summary = event.summary
  form.register = event.register
  form.group_id = event.group ? event.group.id : ''
  form.end_date = event.edit_end_date
  form.location = event.location
  form.organizer = event.organizer
  form.start_date = event.edit_start_date
  form.is_published = event.is_published
  form.featured = event.featured
  form.website_id = event.website.id ? true : false
  form.from_united_way = event.from_united_way ? true : false
  form.group_featured = event.group_featured ? true : false
  form.cover_location = event.cover_location
}

function onSelectFile(files) {
  form.cover = files[0]
  fileSelected.value = true
}

function getSubjectAreas(groupId) {
  subjectAreasService.getAll(groupId).then((data) => {
    subjectAreas.value = data
    assignSubjectArea()
  })
}

function getCategories(groupId) {
  categoriesService.getAll(groupId).then((data) => {
    categories.value = data
    assignCategory()
  })
}

function assignSubjectArea() {
  let subjectAreasId = subjectAreas.value.map((item) => item.id)
  let selectedSubjectAreasId = event.value.subject_areas.map((item) => item.id)

  form.subject_area_id = selectedSubjectAreasId.filter((item) => {
    return subjectAreasId.includes(item)
  })
}

function assignCategory() {
  let categoriesId = categories.value.map((item) => item.id)
  let selectedCategoriesId = event.value.categories.map((item) => item.id)

  form.category_id = selectedCategoriesId.filter((item) => {
    return categoriesId.includes(item)
  })
}

watch(
  () => form.group_id,
  () => {
    getSubjectAreas(form.group_id)
    getCategories(form.group_id)
  }
)

const { hasAccessToAdminPanel } = permsMixin.setup()
</script>
