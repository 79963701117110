import JsCookie from "js-cookie";
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

window.dayjs = dayjs;

export function url(url, params) {
    const kebabized = _.mapKeys(params, (v, k) => _.kebabCase(k.toString()))
    const query = new URLSearchParams(kebabized).toString();

    if (! _.isEmpty(query)) {
        return `${url}?${query}`;

    }

    return url;
}

export function adminPanelTz(onWebsite = false) {
  if (onWebsite) {
    return Laravel.localTimezone;
  }

  const tz = JsCookie.get('user-timezone2');

  if (tz === 'MST' || tz === 'PST') {
    return tz;
  }

  return 'PST';
}
//
export function toLocalTz(datetime, onWebsite = false) {
  return dayjs(datetime).tz(adminPanelTz(onWebsite));
}

export function isWebsite(id)
{
  return id == Laravel.websiteId
}

export function cleanHtml(html) {
  const temp = document.createElement('div')
  temp.innerHTML = html
  return temp.textContent || temp.innerText || ""
}

