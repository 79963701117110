import { computed, onMounted, ref } from 'vue';
import userService from '@/services/user';

export default {
    setup() {
        const perms = ref({});
        onMounted(() => {
            userService
                .perms('me', {
                    perms: ['accessAdminPanel'],
                })
                .then((res) => {
                    perms.value = res;
                });
        });

        const hasAccessToAdminPanel = computed(() => {
            return perms.value.accessAdminPanel;
        });

        return {
            hasAccessToAdminPanel,
        };
    },
};
