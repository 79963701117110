import request from '@js/src/environment/request';
import { url } from '../support/utils';

export default {
    getWebsites(userId, params = {}) {
        return request()
            .get(url(`users/${userId}/websites`, params))
            .then(({ data: { data } }) => {
                return data;
            });
    },

    get(id, params = {}) {
        return request()
            .get(url(`users/${id}`, params))
            .then(({ data: { data } }) => data);
    },

    me(params = {}) {
        return request()
            .get(url('get-authenticated-user', params))
            .then(({ data }) => {
                return data;
            });
    },

    perms(userId, params = {}) {
        return request()
            .get(url(`users/${userId}/permissions`, params))
            .then(({ data: { data } }) => {
                return data;
            });
    },
};
