export default {
    state() {
        return {
            userProfileImage: '',
        };
    },
    mutations: {
        displayUserImage(state, url) {
            state.userProfileImage = url;
        },
    },
    getters: {
        userProfileImage(state) {
            return state.userProfileImage;
        },
    },
};
