<template>
  <section v-if="!postSlug">
    <div class="container">
      <form action="javascript:">
        <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
          <div v-if="groupSection == 'post'" class="col-span-2 flex justify-between items-center">
            <h2 class="text-blue-dark font-bold">Feed</h2>
            <h5 class="uppercase text-normal text-blue-dark">filter by:</h5>
          </div>
          <v-select v-model="form.category_id">
            <option disabled selected value="">
              {{ groupSection == 'resource' ? 'Audience' : 'Category' }}
            </option>
            <option v-for="category in categories" :key="category" :value="category.id">
              {{ category.name }}
            </option>
          </v-select>
          <v-select v-model="form.subject_area_id">
            <option disabled selected value="">Subject Area</option>
            <option v-for="subjectArea in subjectAreas" :key="subjectArea" :value="subjectArea.id">
              {{ subjectArea.name }}
            </option>
          </v-select>
          <div class="flex">
            <button class="button primary" @click="getData(groupSection)">Apply</button>
            <button class="button tertiary" @click="reset()">Reset</button>
          </div>
          <div v-if="groupSection == 'discussion'" class="sm:col-start-1 lg:col-start-4 xl:col-start-5">
            <AppInput v-model="searchKeyword" button="true" label="Search">
              <button @click="getData(groupSection)">
                <i class="fa-solid fa-search text-gray-600"></i>
              </button>
            </AppInput>
          </div>
          <div class="lg:col-start-5 lg:col-end-6">
            <v-select v-if="groupSection == 'event'" v-model="eventMode">
              <option :value="`/group/${groupSlug}/event/calendar`">View as calendar</option>
              <option :value="`/group/${groupSlug}/event`" disabled selected>View as list</option>
            </v-select>
          </div>
        </div>
      </form>
    </div>
  </section>

  <section v-if="postSlug">
    <div class="container">
      <nav aria-label="Breadcrumb" class="flex">
        <ul class="flex items-center space-x-4 list-none text-lg sm:text-xl font-medium" role="list">
          <li>
            <div>
              <a :href="`${baseUrl}/group/${groupSlug}`" class="text-blue-dark"> Feed </a>
            </div>
          </li>
          <li>
            <div class="flex items-center text-[#5F6368]">
              <i class="fa-solid fa-angle-right mr-4"></i>
              <div v-for="(item, index) in groupItems" :key="index">
                <template v-if="index < 1">
                  {{ item?.title }}
                </template>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </section>

  <section v-if="!postSlug" class="mb-16 sm:mb-24 pt-6 sm:pt-8 relative z-0 overflow-x-hidden">
    <div class="container">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-y-6 lg:gap-x-6">
        <div class="col-span-2 pr-0 lg:pr-8 space-y-4 lg:space-y-6">
          <div class="flex flex-col md:flex-row items-center gap-4 p-4 lg:p-6 bg-gray">
            <div class="flex items-center flex-auto w-full space-x-4">
              <div class="flex-none">
                <div
                  :style="'background-image: url(' + authUserThumbnail + ')'"
                  class="h-12 w-12 rounded-full bg-no-repeat bg-cover bg-center bg-blue-light shadow"
                ></div>
              </div>
              <div class="flex-auto self-stretch relative">
                <div
                  v-if="groupSection == 'post'"
                  :class="{
                    '!border-none shadow-xl bg-white z-30': showCreatePost,
                  }"
                  class="absolute inset-x-0 top-0 border border-gray-dark rounded-4xl overflow-hidden sm:w-fit"
                >
                  <div class="w-full h-full cursor-pointer" @click="showCreatePost = !showCreatePost">
                    <div class="w-full flex items-center justify-between px-4 h-12">
                      <span class="text-gray-darkest pr-4">Add a new...</span>
                      <i
                        :class="{
                          '-rotate-180': showCreatePost,
                        }"
                        class="fa-solid fa-angle-down text-gray-darkest transition-transform duration-200"
                      ></i>
                    </div>
                    <div v-show="showCreatePost" class="w-full inset-x-0 bg-white relative p-4 pt-0">
                      <ul class="grid grid-cols-2 border-t border-t-gray-dark/50 pt-4">
                        <li>
                          <a
                            :href="`${baseUrl}/group/${groupSlug}/discussion/create`"
                            class="block p-2 text-blue-dark text-base font-medium hover:bg-blue-light"
                            >Discussion</a
                          >
                        </li>
                        <li>
                          <a
                            :href="`${baseUrl}/group/${groupSlug}/resource/create`"
                            class="block p-2 text-blue-dark text-base font-medium hover:bg-blue-light"
                            >Resource</a
                          >
                        </li>
                        <li>
                          <a
                            :href="`${baseUrl}/group/${groupSlug}/event/create`"
                            class="block p-2 text-blue-dark text-base font-medium hover:bg-blue-light"
                            >Event</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  :class="{
                    '!border-none shadow-xl bg-white': showCreatePost,
                  }"
                  class="group absolute inset-x-0 top-0 border border-gray-dark hover:border-blue rounded-4xl overflow-hidden sm:w-fit"
                >
                  <div class="w-full h-full cursor-pointer">
                    <a v-if="groupSection === 'resource'" :href="`${baseUrl}/group/${groupSlug}/resource/create`">
                      <div class="w-full flex items-center justify-between px-4 h-12">
                        <span class="text-gray-darkest group-hover:text-blue">Add a new resource</span>
                      </div>
                    </a>

                    <a v-else-if="groupSection === 'event'" :href="`${baseUrl}/group/${groupSlug}/event/create`">
                      <div class="w-full flex items-center justify-between px-4 h-12">
                        <span class="text-gray-darkest group-hover:text-blue">Add a new event</span>
                      </div>
                    </a>

                    <a
                      v-else-if="groupSection === 'discussion'"
                      :href="`${baseUrl}/group/${groupSlug}/discussion/create`"
                    >
                      <div class="w-full flex items-center justify-between px-4 h-12">
                        <span class="text-gray-darkest group-hover:text-blue">Add a new discussion</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isGroupAdmin" class="grid grid-cols-2 gap-4 w-full md:max-w-xs">
              <button
                :class="[publishedAt == 'no' ? 'primary' : 'secondary']"
                class="w-full button"
                @click="getPublishedData(groupSection, 'no')"
              >
                unpublished
              </button>
              <button
                :class="[publishedAt == 'yes' ? 'primary' : 'secondary']"
                class="w-full button"
                @click="getPublishedData(groupSection, 'yes')"
              >
                published
              </button>
            </div>
          </div>
          <div v-if="group.group_intro?.length > 15" class="relative col-span-2 bg-gray shadow prose-default">
            <div class="p-px px-4" v-html="group.group_intro"></div>
          </div>

          <template v-if="groupItems?.length && dataState == false">
            <template v-if="!postSlug">
              <div
                v-for="(item, index) in groupItems"
                :key="index"
                class="md:h-80 bg-gray rounded shadow overflow-hidden p-4 lg:p-6 flex flex-col gap-y-4"
              >
                <PrimaryPostView :baseUrl="baseUrl" :groupSlug="groupSlug" :index="index" :item="item" />
              </div>
            </template>
            <div v-if="nextPage">
              <div
                v-if="!nextPageState"
                class="text-base sm:text-normal text-blue-dark text-center cursor-pointer bg-gray hover:bg-gray-dark/30 rounded py-4"
                @click="getNextPage()"
              >
                Load more
              </div>
              <div v-else>
                <SkeletonGroupFeed />
              </div>
            </div>
          </template>
          <template v-else>
            <div v-if="dataState">
              <SkeletonGroupFeed />
            </div>
            <div v-else>
              <EmptyState class="mt-12" />
            </div>
          </template>
        </div>

        <!-- Beginning of Featured Quick Links  -->
        <featured-links-component :groupId="groupId" :groupSlug="groupSlug" />
        <!-- End of Featured Quick Links -->
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue'
import FeaturedLinksComponent from './shared/FeaturedLinksComponent'
import VSelect from '@/components/ui/VSelect.vue'
import SkeletonGroupFeed from '../skeleton/SkeletonGroupFeed.vue'
import EmptyState from '../shared/EmptyState.vue'
import AppInput from '@js/Components/Shared/Input'
import PrimaryPostView from './components/PrimaryPostView'
import DetailsPostView from './components/DetailsPostView'

export default {
  components: {
    FeaturedLinksComponent,
    VSelect,
    SkeletonGroupFeed,
    EmptyState,
    AppInput,
    PrimaryPostView,
    DetailsPostView,
  },

  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    groupSlug: {
      type: [String, Number],
      required: true,
    },
    groupSection: {
      type: String,
      required: true,
    },
    userId: {
      type: [String, Number],
      required: true,
    },
    authUserThumbnail: {
      type: String,
      required: true,
    },
    isGroupAdmin: {
      type: Boolean,
      required: true,
    },
    post: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: '',
    },
  },

  setup({ groupId, groupSlug, groupSection, post, type }) {
    const label = ref('')
    const nextPage = ref('')
    const dataState = ref(false)
    const groupItems = ref('')
    const categories = ref('')
    const subjectAreas = ref('')
    const showCreatePost = ref(false)
    const publishedAt = ref('')
    const postSlug = ref(post)
    const postType = ref(type)
    const eventMode = ref(null)
    const searchKeyword = ref('')
    const baseUrl = window.location.origin
    const group = ref('')
    const nextPageState = ref(false)

    const form = reactive({
      category_id: '',
      subject_area_id: '',
      group_id: groupId,
    })

    onMounted(() => {
      if (groupSection != 'resource') {
        label.value = 'category'
        // Get categories
        axios.get(`/api/groups/${groupId}/categories`).then((response) => (categories.value = response.data.data))
      }

      if (groupSection == 'resource') {
        label.value = 'audience'
        axios.get(`/api/get-auadiences`).then((response) => (categories.value = response.data.data))
      }

      // Get subject areas
      axios.get(`/api/groups/${groupId}/subject-areas`).then((response) => (subjectAreas.value = response.data.data))

      getData(groupSection)

      // Get group data
      if (groupSection == 'post') {
        axios.get(`/api/groups/get-group/${groupId}`).then((response) => {
          group.value = response.data.data
        })
      }
    })

    watch(eventMode, () => {
      window.location.href = eventMode.value
    })

    function getData(groupSection) {
      // Get data
      dataState.value = true
      axios
        .get(
          `/api/group/${groupSlug}/${groupSection}/?${label.value}=${form.category_id}&subject_area_id=${form.subject_area_id}&published=${publishedAt.value}&post=${postSlug.value}&type=${postType.value}&search=${searchKeyword.value}`
        )
        .then((response) => {
          groupItems.value = response.data.data
          getNextPageNumber(response.data.links.next)
        })
        .catch()
        .finally(() => {
          dataState.value = false
        })
    }

    function getNextPage() {
      nextPageState.value = true
      axios
        .get(
          `/api/group/${groupSlug}/${groupSection}/?page=${nextPage.value}&${label.value}=${form.category_id}&subject_area_id=${form.subject_area_id}&published=${publishedAt.value}`
        )
        .then((response) => {
          getNextPageNumber(response.data.links.next)
          groupItems.value.push.apply(groupItems.value, response.data.data)
        })
        .catch()
        .finally(() => {
          nextPageState.value = false
        })
    }

    function getNextPageNumber(path) {
      if (path) {
        nextPage.value = path.split('=')[1]
      } else {
        nextPage.value = ''
      }
    }

    function getPublishedData(groupSection, state) {
      publishedAt.value = state
      getData(groupSection)
    }

    function reset() {
      form.category_id = ''
      form.subject_area_id = ''
      publishedAt.value = ''
      getData(groupSection)
    }

    return {
      form,
      group,
      label,
      nextPage,
      dataState,
      groupItems,
      categories,
      publishedAt,
      subjectAreas,
      nextPageState,
      postSlug,
      postType,
      searchKeyword,
      showCreatePost,
      baseUrl,
      eventMode,
      reset,
      getData,
      getNextPage,
      getPublishedData,
    }
  },
}
</script>
