<template>
    <div class="container">
        <div v-if="!onWebsite" class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <Breadcrumb
                    :pages="[
                        {
                            name: 'Discussions List',
                            href: `/discussions`,
                            current: false,
                        },
                        { name: 'Create Discussin', href: null, current: true },
                    ]"
                />
            </div>
            <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
        </div>
        <div class="mt-8 flex flex-col">
            <div
                :class="{ 'text-normal': onWebsite }"
                class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9"
            >
                <form action="#" method="post" @submit.prevent="saveDiscussion">
                    <div
                        :class="{
                            'shadow sm:rounded-md sm:overflow-hidden':
                                !onWebsite,
                        }"
                    >
                        <div
                            :class="{
                                'bg-white py-6 px-4 space-y-6 sm:p-6':
                                    !onWebsite,
                            }"
                        >
                            <div class="grid grid-cols-6 gap-6">
                                <div
                                    v-if="!onWebsite"
                                    class="col-span-6 sm:col-span-3"
                                >
                                    <label
                                        class="block text-sm font-medium text-gray-700"
                                        >Website <Asterisk/></label
                                    >
                                    <select
                                        v-model="form.website_id"
                                        class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2.5 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        @change="getGroups()"
                                    >
                                        <option value="">...</option>
                                        <option
                                            v-for="website in websites"
                                            :key="website"
                                            :value="website.id"
                                        >
                                            {{ website.name }}
                                        </option>
                                    </select>
                                    <p
                                        v-if="errors.website_id"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.website_id[0] }}
                                    </p>
                                </div>

                                <div class="col-span-6 sm:col-span-3">
                                    <template v-if="!onWebsite">
                                        <input-component
                                            id="title"
                                            v-model="form.title"
                                            :ErrorMessage="
                                                errors.title
                                                    ? errors.title[0]
                                                    : null
                                            "
                                            labelText="Title"
                                            placeholder="Title"
                                            asterisk
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="form.title"
                                            label="Title"
                                            asterisk
                                        />
                                        <AppInputError
                                            v-if="errors.title"
                                            :message="errors.title[0]"
                                            class="mt-2"
                                        />
                                    </template>
                                </div>

                                <div class="col-span-6">
                                    <fieldset>
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="street-address"
                                            >Description <Asterisk/></label
                                        >
                                        <AppTextEditor
                                            v-model="form.description"
                                        />
                                        <p
                                            v-if="errors.description"
                                            class="mt-2 text-sm text-red-500"
                                        >
                                            {{ errors.description[0] }}
                                        </p>
                                    </fieldset>
                                </div>

                                <div
                                    v-if="userRole != 'site users'"
                                    class="col-span-3 sm:col-span-3"
                                >
                                    <div
                                        v-if="hasAccessToAdminPanel"
                                        class="flex items-center space-x-4"
                                    >
                                        <AppCheckbox
                                            id="is_published"
                                            v-model:checked="form.is_published"
                                            name="is_published"
                                        />
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="is_published"
                                            >Publish Now</label
                                        >
                                    </div>
                                    <p
                                        v-if="errors.is_published"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.is_published[0] }}
                                    </p>
                                </div>

                                <div
                                    v-if="!onWebsite && hasAccessToAdminPanel"
                                    class="col-span-3 sm:col-span-3"
                                >
                                    <div class="flex items-center space-x-4">
                                        <AppCheckbox
                                            id="featured"
                                            v-model:checked="form.featured"
                                            name="featured"
                                        />
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="featured"
                                            >Featured in Home Page</label
                                        >
                                    </div>
                                    <p
                                        v-if="errors.featured"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.featured[0] }}
                                    </p>
                                </div>

                                <div
                                    v-if="
                                        userRole != 'site users' &&
                                        hasAccessToAdminPanel
                                    "
                                    class="col-span-3 sm:col-span-3"
                                >
                                    <div class="flex items-center space-x-4">
                                        <AppCheckbox
                                            id="group_featured"
                                            v-model:checked="
                                                form.group_featured
                                            "
                                            name="group_featured"
                                        />
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="group_featured"
                                            >Featured in group</label
                                        >
                                    </div>
                                    <p
                                        v-if="errors.group_featured"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.group_featured[0] }}
                                    </p>
                                </div>

                                <div
                                    v-if="!onWebsite && hasAccessToAdminPanel"
                                    class="col-span-3 sm:col-span-3"
                                >
                                    <div class="flex items-center space-x-4">
                                        <AppCheckbox
                                            id="from_united_way"
                                            v-model:checked="
                                                form.from_united_way
                                            "
                                            name="from_united_way"
                                        />
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="from_united_way"
                                            >From United Way</label
                                        >
                                    </div>
                                    <p
                                        v-if="errors.from_united_way"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.from_united_way[0] }}
                                    </p>
                                </div>

                                <!-- Cover Photo -->
                                <div class="col-span-6">
                                    <DropFile
                                        :file="form.cover"
                                        customCoverLocation
                                        @changeCoverLocation="
                                            ($event) =>
                                                (form.cover_location = $event)
                                        "
                                        @fileSelected="onSelectFile"
                                    />
                                    <p
                                        v-if="errors.cover"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.cover[0] }}
                                    </p>
                                </div>

                                <div v-if="!onWebsite" class="col-span-6">
                                    <label
                                        class="form-input-label"
                                        for="group_id"
                                        >Group <Asterisk/></label
                                    >
                                    <MultiSelect
                                        id="group_id"
                                        ref="multiSelect"
                                        v-model="groupsId"
                                        :options="groups"
                                        :searchable="true"
                                        label="name"
                                        mode="tags"
                                        placeholder="Select Group"
                                        trackBy="name"
                                        valueProp="id"
                                    />
                                    <p
                                        v-if="errors.group_id"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.group_id[0] }}
                                    </p>
                                </div>

                                <div class="col-span-6">
                                    <fieldset>
                                        <legend
                                            class="text-base font-medium text-gray-900"
                                        >
                                            Audience
                                        </legend>
                                        <MultiCheckbox
                                            v-model="form.audience_id"
                                            :error="
                                                errors.audience_id
                                                    ? errors.audience_id[0]
                                                    : null
                                            "
                                            :options="audiences"
                                        />
                                    </fieldset>
                                </div>

                                <div class="col-span-6">
                                    <fieldset>
                                        <legend
                                            class="text-base font-medium text-gray-900"
                                        >
                                            Subject Area
                                        </legend>
                                        <MultiCheckbox
                                            v-model="form.subject_area_id"
                                            :error="
                                                errors.subject_area_id
                                                    ? errors.subject_area_id[0]
                                                    : null
                                            "
                                            :options="subjectAreas"
                                        />
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <button
                                :class="{
                                    'bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500':
                                        !onWebsite,
                                    'button primary': onWebsite,
                                    'disabled:!cursor-wait': isDisabled,
                                }"
                                :disabled="isDisabled"
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import InputComponent from '@/components/shared/InputComponent';
import MultiSelect from '@vueform/multiselect';
import AppCheckbox from '@/components/shared/Checkbox';
import DropFile from '@/components/shared/DropFile';
import store from '@/store';
import Breadcrumb from '@/components/shared/Breadcrumb';
import request from '@/environment/request';
import AppTextEditor from '@/components/shared/TextEditor';
import MultiCheckbox from '@/components/shared/MultiCheckbox';
import audiencesService from '@/services/audience';
import subjectAreasService from '@/services/subject-area';
import AppInput from '@js/Components/Shared/Input';
import AppInputError from '@js/Components/Shared/InputError';
import userService from '@/services/user';
import Asterisk from "@/components/shared/Asterisk";
import permsMixin from '@/services/permsMixin';

const errors = ref([]);
const groupsId = ref([]);
const groups = ref([]);
const types = ref([]);
const cover = ref(null);
const websites = ref('');

const router = useRouter();

const form = reactive({
    title: '',
    cover: null,
    audience_id: [],
    group_id: [],
    website_id: '',
    description: '',
    featured: false,
    subject_area_id: [],
    group_featured: false,
    from_united_way: false,
    is_published: false,
    cover_location: 0,
});

const props = defineProps({
    onWebsite: Boolean,
    group: Object,
    userRole: String,
});

const audiences = ref([]);
const subjectAreas = ref([]);

onMounted(() => {
    request(!props.onWebsite)
        .get('groups-all')
        .then((response) => (groups.value = response.data));
    userService.getWebsites('me', { checkAdminAccess: false }).then((res) => {
        websites.value = res;
        form.website_id = websites.value[0].id;
        getGroups();
    });

    audiencesService.getAll().then((data) => {
        audiences.value = data;
    });

    if (props.onWebsite) {
        getSubjectAreas(props.group.id);
    }
});

function getSubjectAreas(groupId) {
    subjectAreasService.getAll(groupId).then((data) => {
        subjectAreas.value = data;
        form.subject_area_id = [];
    });
}

const isDisabled = ref(false);

function saveDiscussion() {
    if (props.userRole == 'site users') {
        form.is_published = true;
    }
    isDisabled.value = true;
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let formData = new FormData();

    formData.append(
        'website_id',
        props.onWebsite ? `${props.group.website_id}` : form.website_id
    );
    formData.append('title', form.title);
    formData.append('cover', form.cover ? form.cover : '');
    formData.append(
        'group_id',
        props.onWebsite ? props.group.id : groupsId.value
    );
    formData.append('description', form.description);
    formData.append('featured', props.onWebsite ? `false` : form.featured);
    formData.append(
        'from_united_way',
        props.onWebsite ? `false` : form.from_united_way
    );
    formData.append('group_featured', form.group_featured);
    formData.append('audience_id', form.audience_id);
    formData.append('is_published', form.is_published);
    formData.append('subject_area_id', form.subject_area_id);
    formData.append('cover_location', form.cover_location);

    request(!props.onWebsite)
        .post(`discussions`, formData, config)
        .then(() => {
            store.commit('displaySuccessModal', 'Successfully updated.');
            if (props.onWebsite) {
                _toast.success({
                    title: 'Created discussion',
                    message: 'New discussion has been successfully created.',
                });
                setTimeout(
                    () =>
                        (location.href = `/group/${props.group.slug}/discussion`),
                    500
                );
            } else {
                router.push({ path: '/discussions' });
            }
        })
        .catch((err) => {
            isDisabled.value = false;
            if (err.response && err.response.status == 422) {
                errors.value = err.response.data.errors;
            }
        });
}

function selectedCover($event) {
    const formData = new FormData();
    formData.append('file', $event.target.files[0]);
    const headers = { 'Content-Type': 'multipart/form-data' };
    request(!props.onWebsite)
        .post('upload', formData, { headers })
        .then((res) => {
            cover.value = res.data.path;
            form.cover = cover.value;
        })
        .catch((error) => (errors.value = error.response.data.errors));
}

function onSelectFile(files) {
    form.cover = files[0];
}

function getGroups() {
    request(!props.onWebsite)
        .get(`get-groups?website_id=${form.website_id}`)
        .then((res) => {
            groups.value = res.data.data;
            form.group_id = '';
        });
}

watch(
    () => groupsId.value,
    () => {
        getSubjectAreas(groupsId.value);
    }
);

const { hasAccessToAdminPanel } = permsMixin.setup();
</script>
