<template>
    <section>
        <div class="container">
            <h1 class="text-blue-dark font-bold">Settings</h1>
        </div>
    </section>

    <section
        class="mb-16 sm:mb-24 pt-12 sm:pt-18 relative z-0 overflow-x-hidden"
    >
        <div class="container">
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-y-6 lg:gap-x-6">
                <div class="col-span-2 pr-0 lg:pr-8 space-y-4 lg:space-y-6">
                    <div class="flex justify-between items-center">
                        <label
                            class="space-x-4 text-base sm:text-xl text-gray-darkest"
                            for=""
                        >
                            <i class="fa-solid fa-lock"></i>
                            <span>Private group</span>
                        </label>

                        <label
                            class="relative inline-block w-14 h-7 rounded-full"
                        >
                            <input
                                v-model="type"
                                :checked="type"
                                class="peer opacity-0 w-0 h-0"
                                type="checkbox"
                                @change="toggleType"
                            />

                            <span
                                class="absolute cursor-pointer inset-0 bg-blue-light rounded-full duration-300 before:content-[''] before:absolute before:w-7 before:h-7 before:bottom-0 before:left-0 before:rounded-full before:bg-blue before:duration-300 peer-checked:before:translate-x-7 peer-checked:before:bg-blue-dark"
                            ></span>
                        </label>
                    </div>
                    <div class="flex justify-between items-center">
                        <label
                            class="space-x-4 text-base sm:text-xl text-gray-darkest"
                            for=""
                        >
                            <i class="fa-solid fa-edit"></i>
                            <span>Approve posts before publishing</span>
                        </label>
                        <label
                            class="relative inline-block w-14 h-7 rounded-full"
                        >
                            <input
                                v-model="needToBePublished"
                                :checked="needToBePublished"
                                class="peer opacity-0 w-0 h-0"
                                type="checkbox"
                                @change="toggleNeedToBePublished"
                            />
                            <span
                                class="absolute cursor-pointer inset-0 bg-blue-light rounded-full duration-300 before:content-[''] before:absolute before:w-7 before:h-7 before:bottom-0 before:left-0 before:rounded-full before:bg-blue before:duration-300 peer-checked:before:translate-x-7 peer-checked:before:bg-blue-dark"
                            ></span>
                        </label>
                    </div>
                    <div
                        class="flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-4 sm:space-y-0"
                    >
                        <label
                            class="space-x-4 text-base sm:text-xl text-gray-darkest"
                            for=""
                        >
                            <i class="fa-solid fa-user-plus"></i>
                            <span>Who can invite others to this group</span>
                        </label>

                        <select
                            v-model="invitationType"
                            class="bg-transparent block w-full px-3 py-3 text-base text-blue-dark border border-gray-dark focus:outline-none focus:ring-transparent hover:border-blue focus:border-blue focus:shadow-none sm:text-sm rounded-[5px] w-full notranslate xl:w-48"
                            @change="updateInvitationType"
                        >
                            <option
                                v-for="inviteType in invitationTypes"
                                :key="inviteType"
                                :value="inviteType.id"
                            >
                                {{ inviteType.name }}
                            </option>
                        </select>
                    </div>

                    <div
                        v-if="
                            invitationType == groupInvitationTypesEnum['custom']
                        "
                        class="px-4 sm:py-6 pb-4 sm:pb-6 rounded bg-white shadow-md"
                    >
                        <div>
                            <p class="text-base mb-4">
                                Add/Remove members who can invite others to this
                                group
                            </p>
                            <input
                                v-model="searchQuery"
                                class="bg-transparent block w-full px-3 py-4 text-base text-blue-dark border border-gray-dark focus:outline-none focus:ring-transparent hover:border-blue focus:border-blue focus:shadow-none sm:text-sm rounded-[5px]"
                                name="name_email"
                                placeholder="Search members by their name"
                                type="text"
                                v-on:keyup="searchUser()"
                            />
                        </div>
                        <ul
                            v-if="filteredUsers"
                            class="space-y-4 lg:space-y-6 mt-6"
                        >
                            <li
                                v-for="user in filteredUsers"
                                :key="user"
                                class="flex items-center justify-between hover:bg-gray px-4 sm:px-6"
                            >
                                <label
                                    class="flex items-center space-x-4 w-full cursor-pointer"
                                >
                                    <input
                                        id="newsletter"
                                        v-model="user.is_invited"
                                        :checked="user.is_invited"
                                        class="rounded border-gray-dark text-blue-dark shadow-sm focus:border-blue-dark focus:ring focus:ring-blue-dark focus:ring-opacity-50 sm:h-6 sm:w-6"
                                        name="newsletter"
                                        type="checkbox"
                                        @change="toggleIniviteUser(user.id)"
                                    />
                                    <div
                                        :style="
                                            'background-image: url(' +
                                            user.thumbnail +
                                            ')'
                                        "
                                        class="w-12 h-12 lg:h-16 lg:w-16 flex-none rounded-full bg-no-repeat bg-cover bg-center bg-blue-light"
                                    ></div>
                                    <div>
                                        <h5
                                            class="text-normal text-blue-dark mb-0 font-medium"
                                            v-text="
                                                user.name + ' ' + user.last_name
                                            "
                                        ></h5>
                                        <p
                                            class="text-gray-darkest"
                                            v-text="user.title"
                                        ></p>
                                    </div>
                                </label>
                            </li>

                            <li
                                v-if="notFound"
                                class="flex items-center justify-between hover:bg-gray px-4 sm:px-6"
                            >
                                <p
                                    class="flex items-center space-x-4 w-full cursor-pointer text-sm"
                                >
                                    No member found!
                                </p>
                            </li>
                        </ul>
                        <hr class="border-gray-dark/50 my-4" />
                        <ul v-if="membersWhoCanInvite">
                            <p class="text-sm sm:text-base mb-2">
                                <strong>{{
                                    membersWhoCanInvite.length
                                }}</strong>
                                Member(s) can invite others to this group
                            </p>
                            <li
                                v-for="member in membersWhoCanInvite"
                                :key="member"
                                class="flex items-center justify-between hover:bg-gray px-4 sm:px-6"
                            >
                                <label
                                    class="flex items-center space-x-4 w-full cursor-pointer"
                                >
                                    <input
                                        id="newsletter"
                                        v-model="member.is_invited"
                                        :checked="member.is_invited"
                                        class="rounded border-gray-dark text-blue-dark shadow-sm focus:border-blue-dark focus:ring focus:ring-blue-dark focus:ring-opacity-50 sm:h-6 sm:w-6"
                                        name="newsletter"
                                        type="checkbox"
                                        @change="toggleIniviteUser(member.id)"
                                    />
                                    <div
                                        :style="
                                            'background-image: url(' +
                                            member.thumbnail +
                                            ')'
                                        "
                                        class="w-12 h-12 lg:h-16 lg:w-16 flex-none rounded-full bg-no-repeat bg-cover bg-center bg-blue-light"
                                    ></div>
                                    <div>
                                        <h5
                                            class="text-normal text-blue-dark mb-0 font-medium"
                                            v-text="
                                                member.name +
                                                ' ' +
                                                member.last_name
                                            "
                                        ></h5>
                                        <p
                                            class="text-gray-darkest"
                                            v-text="member.tile"
                                        ></p>
                                    </div>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <hr class="border-gray-dark/50" />
                    <div>
                        <h4 class="text-blue-dark font-bold mb-4">
                            Group Introduction <Asterisk />
                        </h4>
                        <fieldset>
                            <AppTextEditor
                                v-model="group_intro"
                            />
                        </fieldset>
                        <p
                            v-if="errors.group_intro"
                            class="mt-2 text-sm text-red-500"
                        >
                            {{ errors.group_intro[0] }}
                        </p>
                    </div>
                    <div>
                        <h4 class="text-blue-dark font-bold mb-4">About <Asterisk/></h4>
                        <textarea
                            v-model="about"
                            class="w-full p-4 border-gray-dark/50 rounded text-base sm:text-normal focus:ring-0 focus:border-blue"
                            name="about"
                            rows="8"
                        >
                        </textarea>
                        <p
                            v-if="errors.about"
                            class="mt-2 text-sm text-red-500"
                            v-text="errors.about[0]"
                        ></p>
                    </div>
                    <div
                        v-if="
                            group &&
                            (about !== group.about ||
                                group_intro !== group.group_intro)
                        "
                        class="space-x-4"
                    >
                        <button
                            :disabled="disableSubmitBtn"
                            class="button primary"
                            @click="updateGroup()"
                        >
                            Save Changes
                        </button>
                        <button
                            class="button secondary"
                            @click="about = group.about"
                        >
                            Cancel
                        </button>
                    </div>
                </div>

                <!-- Beginning of Featured Quick Links  -->
                <featured-links-component
                    :groupId="groupId"
                    :groupSlug="groupSlug"
                />
                <!-- End of Featured Quick Links -->
            </div>
        </div>
    </section>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue';
import FeaturedLinksComponent from './shared/FeaturedLinksComponent';
import AppTextEditor from '@/components/shared/TextEditor';
import Asterisk from "@/components/shared/Asterisk.vue";

export default {
    components: {
      Asterisk,
        FeaturedLinksComponent,
        AppTextEditor,
    },

    props: {
        groupId: {
            type: [String, Number],
            required: true,
        },
        groupSlug: {
            type: [String, Number],
            required: true,
        },
    },

    setup({ groupId, groupSlug }) {
        const events = ref('');
        const userCount = ref('');
        const group = ref('');
        const notFound = ref(false);
        const message = ref('');
        const searchQuery = ref('');
        const disableSubmitBtn = ref(false);
        const users = ref([]);
        const type = ref(0);
        const about = ref(null);
        const group_intro = ref('');
        const needToBePublished = ref(0);
        const invitationType = ref(1);
        const membersWhoCanInvite = ref([]);

        const groupInvitationTypesEnum = Object.freeze({
            anyone: 1,
            noone: 2,
            custom: 3,
        });

        const invitationTypes = reactive([
            { id: 1, name: 'Anyone' },
            { id: 2, name: 'No One' },
            { id: 3, name: 'Custom' },
        ]);

        const readMore = ref(false);

        onMounted(() => {
            getGroup(groupId);
            getGroupMembers();
            getMembersWhoCanInvite();
        });

        function getMembersWhoCanInvite() {
            axios
                .get(`/api/groups/${groupId}/members-who-can-invite`)
                .then(({ data }) => {
                    membersWhoCanInvite.value = data.data;
                });
        }

        function getGroup(id) {
            axios.get(`/api/groups/get-group/${groupId}`).then((response) => {
                group.value = response.data.data;
                assignGroupDetails(group.value);
            });
        }

        function assignGroupDetails(group) {
            about.value = group.about;
            group_intro.value = group.group_intro;
            needToBePublished.value = group.need_to_be_published;
            invitationType.value = group.invitation_type;
            type.value = group.type;
        }

        function toggleNeedToBePublished() {
            axios
                .post(
                    `/api/groups/${groupId}/settings/need-to-be-published/toggle`,
                    { need_to_be_published: needToBePublished.value }
                )
                .then(({ data }) => {
                    _toast.success({
                        title: 'Group settings updated.',
                        message: data.message,
                    });
                })
                .catch((error) => {
                    _toast.error({
                        title: 'Error occurred!',
                        message: 'Something went wrong, please try again!',
                    });
                });
        }

        function toggleType() {
            axios
                .post(`/api/groups/${groupId}/settings/type/toggle`, {
                    type: type.value,
                })
                .then(({ data }) => {
                    _toast.success({
                        title: 'Group settings updated.',
                        message: data.message,
                    });
                })
                .catch((error) => {
                    _toast.error({
                        title: 'Error occurred!',
                        message: 'Something went wrong, please try again!',
                    });
                });
        }

        function updateInvitationType() {
            axios
                .post(`/api/groups/${groupId}/settings/invitation-type`, {
                    invitation_type: invitationType.value,
                })
                .then(({ data }) => {
                    _toast.success({
                        title: 'Group settings updated.',
                        message: data.message,
                    });
                })
                .catch((error) => {
                    _toast.error({
                        title: 'Error occurred!',
                        message: 'Something went wrong, please try again!',
                    });
                });
        }

        const errors = ref('');

        function updateGroup() {
            disableSubmitBtn.value = true;
            axios
                .post(`/api/groups/${groupId}/settings/about`, {
                    about: about.value,
                    group_intro: group_intro.value,
                })
                .then(({ data }) => {
                    group.value.about = about.value;
                    group.value.group_intro = group_intro.value;
                    _toast.success({
                        title: 'Group settings updated.',
                        message: data.message,
                    });
                    errors.value = '';
                })
                .catch((error) => {
                    _toast.error({
                        title: 'Error occurred!',
                        message: 'Something went wrong, please try again!',
                    });
                    if (error.response && error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    }
                })
                .finally(() => {
                    disableSubmitBtn.value = false;
                });
        }

        function searchUser() {
            if (searchQuery.value.length === 0) {
                notFound.value = false;
            }
            if (searchQuery.value.length) {
                axios
                    .post(`/api/groups/search-users`, {
                        name: searchQuery.value,
                        groupId: groupId,
                    })
                    .then((response) => {
                        users.value = response.data.data;
                        if (filteredUsers.value.length > 0) {
                            notFound.value = false;
                        } else {
                            notFound.value = true;
                        }
                    });
            } else {
                users.value = [];
            }
        }

        function getGroupMembers() {
            axios
                .get(`/api/group/${groupSlug}/member-count`)
                .then(
                    (response) => (userCount.value = response.data.user_counts)
                );
        }

        function toggleIniviteUser(userId) {
            axios
                .post(`/api/groups/toggle-invite-user`, {
                    user_id: userId,
                    group_id: groupId,
                })
                .then((response) => {
                    searchQuery.value = '';
                    getMembersWhoCanInvite();
                });
        }

        const filteredUsers = computed({
            get() {
                return users.value.filter((user) => !user.is_invited);
            },
        });

        return {
            type,
            users,
            group,
            about,
            events,
            errors,
            message,
            readMore,
            notFound,
            userCount,
            searchQuery,
            group_intro,
            filteredUsers,
            invitationType,
            invitationTypes,
            disableSubmitBtn,
            needToBePublished,
            membersWhoCanInvite,
            groupInvitationTypesEnum,
            toggleNeedToBePublished,
            updateInvitationType,
            assignGroupDetails,
            toggleIniviteUser,
            updateGroup,
            toggleType,
            searchUser,
            getGroup,
        };
    },
};
</script>
