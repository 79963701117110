<template>
    <div class="section">
        <div class="hidden lg:block">
            <v-calendar v-if="isMounted"

                class="custom-calendar max-w-full"
                :masks="masks"
                :attributes="filteredEvents"
                disable-page-swipe
                is-expanded
                :from-page="defaultDate"
                v-bind="{
                    fromDate: props.occurrence ? new Date(occurrence) : null,
                }"
                v-model="defaultDate"
                @update:from-page="navigationHandler($event)"
            >
                <template v-slot:day-content="{ day, attributes }">
                  <CalendarDay :day="day" :attributes="attributes" />
                </template>
            </v-calendar>
        </div>
        <div class="lg:hidden">
            <v-calendar v-if="isMounted"
                :masks="masks"
                :attributes="filteredEvents"
                disable-page-swipe
                @dayclick="showDayEvents"
                :from-page="defaultDate"
                v-bind="{
                    fromDate: props.occurrence ? new Date(occurrence) : null,
                }"

                @update:from-page="navigationHandler($event)"
                is-expanded
            />
            <div class="my-4" v-if="selectedDate">
                <h4 class="font-semibold" v-if="dayEvents.length">
                    Training & Events on {{ selectedDate }}
                </h4>
                <h4 class="font-semibold" v-else>
                    There is no Training & Events on {{ selectedDate }}
                </h4>
            </div>
            <ul class="mt-4" v-if="dayEvents.length">
                <li
                    class="bg-white p-4 rounded shadow-md space-y-2"
                    v-for="event in dayEvents"
                    :key="event.id"
                >
                    <div class="flex justify-between">
                        <div class="flex-auto space-y-2">
                            <h4 class="font-semibold">
                                <a
                                    :href="event.customData.url"
                                    class="text-blue-dark"
                                    >{{ event.customData.title }}</a
                                >
                            </h4>
                            <p class="text-sm text-gray-600"></p>
                            <p class="text-sm sm:text-base">
                                <span>
                                    {{
                                        event.customData.formated_date
                                            .start_month
                                    }}&nbsp;
                                </span>
                                <span>
                                    {{
                                        event.customData.formated_date.start_day
                                    }} </span
                                >,
                                <span>{{
                                    event.customData.formated_date.year
                                }}</span
                                >-<span>{{
                                    event.customData.formated_date.start_hour
                                }}</span>
                                <span
                                    >&nbsp;{{
                                        event.customData.formated_date.timezone
                                    }}</span
                                >
                            </p>
                        </div>
                    </div>
                    <div>
                        <p class="line-clamp-3">
                            {{ event.customData.summary }}
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import 'v-calendar/dist/style.css';
import CalendarDay from './CalendarDay.vue'
import { ref, computed, onMounted, watch } from 'vue';
import {adminPanelTz, toLocalTz} from "../../support/utils";

const hasDot = ref(null);
const dayEvents = ref([]);
const selectedDate = ref(null);
const defaultDate = ref(null);
const isMounted = ref(false);

const props = defineProps({
    events: {
        type: Array,
    },
    occurrence: {
        type: String,
    },
});

onMounted(() => {
  const occurrenceDate = new Date(props.occurrence+'-01T00:00');

  defaultDate.value = { month: occurrenceDate.getMonth()+1, year: occurrenceDate.getFullYear() };

  selectedDate.value = new Date(props.occurrence + '-01T00:00');

  hasDot.value = !(getWindowWith() > 1024);
  window.onresize = () => {
      hasDot.value = !(getWindowWith() > 1024);
  };

  isMounted.value = true;
});

const masks = ref({
    weekdays: 'WWW',
});

const filteredEvents = computed({
    get() {
        return props.events.map((event) => {
            return {
                key: event.id,
                dot: hasDot.value,
                dates: new Date(event.postable.start_date),
                customData: {
                    dot: true,
                    ...event.postable,
                    formated_date: formatDate(event.postable.start_date),
                },
            };
        });
    },
});

function showDayEvents(e) {
    const date = new Date(e.date);
    selectedDate.value = `${date.getDate()} ${date.toLocaleString('default', {
        month: 'long',
    })}`;

    dayEvents.value = filteredEvents.value.filter((item) => {
      return Number(item.customData.formated_date.start_day) === date.getDate()
    });
}

function formatDate(startDate) {
  const dt = toLocalTz(startDate, true);

  let formatedDate = {};
  formatedDate.start_hour = dt.format('hh:mm A');
  formatedDate.start_month = dt.format('MMMM');
  formatedDate.start_day = dt.format('DD');
  formatedDate.year = dt.format('YYYY');
  formatedDate.timezone = dt.format('z');
  return formatedDate;
}

function convertDateHourFormat(dt) {
  let hours = dt.getHours();
  let AmOrPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  hours = ('0' + hours).slice(-2);
  let minutes = ('0' + dt.getMinutes()).slice(-2);
  return hours + ':' + minutes + ' ' + AmOrPm;
}

function getWindowWith() {
    return window.innerWidth;
}

const navigationHandler = ($event) => {
  if ('URLSearchParams' in window) {
    const url = new URL(window.location.href);
    let search_params = url.searchParams;

    // Get occurrence value from url
    const monthYear = search_params.get('filter[occurrence]');
    const monthYearArr = monthYear?.split('-') ?? [];

    let year = $event.year;
    let month = $event.month < 10 ? `0${$event.month}` : $event.month

    if(!monthYear) {
      search_params.set('filter[occurrence]', `${year}-${month}`);
      search_params.set('filter[type]', 'calendar');
      window.history.pushState(null, '', url.toString());
    }
    else if(+new Date(`${monthYearArr[0]}-${monthYearArr[1]}-01T00:00`) !== +new Date(`${year}-${month}-01T00:00`)) {
      search_params.set('filter[occurrence]', `${year}-${month}`);
      search_params.set('filter[type]', 'calendar');
      window.location.search = search_params.toString();
    }
  }
};
</script>
