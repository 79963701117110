<template>
    <div class="container">
        <div v-if="!onWebsite" class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <Breadcrumb
                    :pages="[
                        { name: 'Event List', href: `/events`, current: false },
                        { name: 'Create Event', href: null, current: true },
                    ]"
                />
            </div>
            <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <router-link :to="'/events'">
                    <button
                        class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        type="button"
                    >
                        Event List
                    </button>
                </router-link>
            </div>
        </div>
        <div class="mt-8 flex flex-col">
            <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                <form action="#" method="POST" @submit.prevent="submit">
                    <div
                        :class="{
                            'shadow sm:rounded-md sm:overflow-hidden':
                                !onWebsite,
                        }"
                    >
                        <div
                            :class="{
                                'bg-white py-6 px-4 space-y-6 sm:p-6':
                                    !onWebsite,
                            }"
                        >
                            <div class="grid grid-cols-6 gap-6">
                                <!-- Website -->
                                <div
                                    v-if="!onWebsite"
                                    class="col-span-6 sm:col-span-3"
                                >
                                    <label
                                        class="block text-sm font-medium text-gray-700"
                                        >Website <Asterisk /></label
                                    >
                                    <select
                                        v-model="form.website_id"
                                        class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2.5 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        @change="getGroups()"
                                    >
                                        <option value="">...</option>
                                        <option
                                            v-for="website in websites"
                                            :key="website"
                                            :value="website.id"
                                        >
                                            {{ website.name }}
                                        </option>
                                    </select>
                                    <p
                                        v-if="errors.website_id"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.website_id[0] }}
                                    </p>
                                </div>

                                <!-- Title -->
                                <div class="col-span-6 sm:col-span-3">
                                    <template v-if="!onWebsite">
                                        <input-component
                                            id="title"
                                            v-model="form.title"
                                            :ErrorMessage="
                                                errors.title
                                                    ? errors.title[0]
                                                    : null
                                            "
                                            labelText="Title"
                                            placeholder="Title"
                                            asterisk
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="form.title"
                                            label="Title"
                                            asterisk
                                        />
                                        <AppInputError
                                            v-if="errors.title"
                                            :message="errors.title[0]"
                                            class="mt-2"
                                        />
                                    </template>
                                </div>

                                <!-- Body -->
                                <div class="col-span-6">
                                    <fieldset>
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="street-address"
                                            >Body <Asterisk /></label
                                        >
                                        <AppTextEditor v-model="form.body" />
                                        <p
                                            v-if="errors.body"
                                            class="mt-2 text-sm text-red-500"
                                        >
                                            {{ errors.body[0] }}
                                        </p>
                                    </fieldset>
                                </div>

                                <!-- summary -->
                                <div class="col-span-6 sm:col-span-3">
                                    <template v-if="!onWebsite">
                                        <input-component
                                            id="summary"
                                            v-model="form.summary"
                                            :ErrorMessage="
                                                errors.summary
                                                    ? errors.summary[0]
                                                    : null
                                            "
                                            labelText="Summary"
                                            placeholder="Summary"
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="form.summary"
                                            label="Summary"
                                        />
                                        <AppInputError
                                            v-if="errors.summary"
                                            :message="errors.summary[0]"
                                            class="mt-2"
                                        />
                                    </template>
                                </div>

                                <!-- Register -->
                                <div class="col-span-6 sm:col-span-3">
                                    <template v-if="!onWebsite">
                                        <input-component
                                            id="register"
                                            v-model="form.register"
                                            :ErrorMessage="
                                                errors.register
                                                    ? errors.register[0]
                                                    : null
                                            "
                                            labelText="Registration Link"
                                            placeholder="Registration Link"
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="form.register"
                                            label="Registration link"
                                        />
                                        <AppInputError
                                            v-if="errors.register"
                                            :message="errors.register[0]"
                                            class="mt-2"
                                        />
                                    </template>
                                </div>

                                <!-- Organizer -->
                                <div class="col-span-6 sm:col-span-3">
                                    <template v-if="!onWebsite">
                                        <input-component
                                            id="organizer"
                                            v-model="form.organizer"
                                            :ErrorMessage="
                                                errors.organizer
                                                    ? errors.organizer[0]
                                                    : null
                                            "
                                            labelText="Organizer"
                                            placeholder="Organizer"
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="form.organizer"
                                            label="Organizer"
                                        />
                                        <AppInputError
                                            v-if="errors.organizer"
                                            :message="errors.organizer[0]"
                                            class="mt-2"
                                        />
                                    </template>
                                </div>

                                <!-- Location -->
                                <div class="col-span-6 sm:col-span-3">
                                    <template v-if="!onWebsite">
                                        <input-component
                                            id="location"
                                            v-model="form.location"
                                            :ErrorMessage="
                                                errors.location
                                                    ? errors.location[0]
                                                    : null
                                            "
                                            labelText="Location"
                                            placeholder="Location"
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="form.location"
                                            label="Location"
                                        />
                                        <AppInputError
                                            v-if="errors.location"
                                            :message="errors.location[0]"
                                            class="mt-2"
                                        />
                                    </template>
                                </div>
                                <!-- Start Date -->
                                <div class="col-span-6 sm:col-span-3">
                                    <div class="col-span-6 sm:col-span-3">
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="start_date"
                                            >Start Date <Asterisk/></label
                                        >
                                        <datepicker
                                            id="start_date"
                                            v-model="form.start_date"
                                            model-type="format"
                                        ></datepicker>
                                        <p
                                            v-if="errors.start_date"
                                            class="mt-2 text-sm text-red-500"
                                        >
                                            {{ errors.start_date[0] }}
                                        </p>
                                    </div>
                                </div>

                                <!-- End Date -->
                                <div class="col-span-6 sm:col-span-3">
                                    <label
                                        class="block text-sm font-medium text-gray-700"
                                        for="end_date"
                                        >End Date <Asterisk/></label
                                    >
                                    <datepicker
                                        id="end_date"
                                        v-model="form.end_date"
                                        model-type="format"
                                    ></datepicker>
                                    <p
                                        v-if="errors.end_date"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.end_date[0] }}
                                    </p>
                                </div>

                                <!-- Cover Photo -->
                                <div class="col-span-6">
                                    <DropFile
                                        customCoverLocation
                                        @changeCoverLocation="
                                            form.cover_location = $event
                                        "
                                        @fileSelected="onSelectFile"
                                    />
                                    <p
                                        v-if="errors.cover"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.cover[0] }}
                                    </p>
                                </div>
                                <!-- Alternative Text-->
                                <div class="col-span-6 sm:col-span-6">
                                    <template v-if="!onWebsite">
                                        <input-component
                                            id="register"
                                            v-model="form.alt"
                                            :ErrorMessage="
                                                errors.alt
                                                    ? errors.alt[0]
                                                    : null
                                            "
                                            labelText="Alternative Text"
                                            placeholder="Alternative Text"
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="form.alt"
                                            label="Alternative Text"
                                        />
                                        <AppInputError
                                            v-if="errors.alt"
                                            :message="errors.alt[0]"
                                            class="mt-2"
                                        />
                                    </template>
                                </div>

                                <!-- Featured -->
                                <div
                                    v-if="!onWebsite && hasAccessToAdminPanel"
                                    class="col-span-3 sm:col-span-2"
                                >
                                    <div class="flex items-center space-x-4">
                                        <AppCheckbox
                                            id="featured"
                                            v-model:checked="form.featured"
                                            name="featured"
                                        />
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="featured"
                                            >Featured in Home Page</label
                                        >
                                    </div>
                                    <p
                                        v-if="errors.featured"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.featured[0] }}
                                    </p>
                                </div>

                                <!-- Featured in group -->
                                <div
                                    v-if="
                                        userRole != 'site users' &&
                                        hasAccessToAdminPanel
                                    "
                                    class="col-span-3 sm:col-span-2"
                                >
                                    <div class="flex items-center space-x-4">
                                        <AppCheckbox
                                            id="group_featured"
                                            v-model:checked="
                                                form.group_featured
                                            "
                                            name="group_featured"
                                        />
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="group_featured"
                                            >Featured in group</label
                                        >
                                    </div>
                                    <p
                                        v-if="errors.group_featured"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.group_featured[0] }}
                                    </p>
                                </div>

                                <!-- Published Now -->
                                <div
                                    v-if="
                                        userRole != 'site users' &&
                                        hasAccessToAdminPanel
                                    "
                                    class="col-span-3 sm:col-span-2"
                                >
                                    <div class="flex items-center space-x-4">
                                        <AppCheckbox
                                            id="is_published"
                                            v-model:checked="form.is_published"
                                            name="is_published"
                                        />
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="is_published"
                                            >Publish Now</label
                                        >
                                    </div>
                                    <p
                                        v-if="errors.is_published"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.is_published[0] }}
                                    </p>
                                </div>

                                <!-- From united way -->
                                <div
                                    v-if="
                                        userRole != 'site users' &&
                                        hasAccessToAdminPanel
                                    "
                                    class="col-span-3 sm:col-span-2"
                                >
                                    <div class="flex items-center space-x-4">
                                        <AppCheckbox
                                            id="from_united_way"
                                            v-model:checked="
                                                form.from_united_way
                                            "
                                            name="featured"
                                        />
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="from_united_way"
                                            >From United Way</label
                                        >
                                    </div>
                                    <p
                                        v-if="errors.featured"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.from_united_way[0] }}
                                    </p>
                                </div>

                                <!-- Group -->
                                <div v-if="!onWebsite" class="col-span-6">
                                    <label
                                        class="form-input-label"
                                        for="group_id"
                                        >Group</label
                                    >
                                    <MultiSelect
                                        id="group_id"
                                        ref="multiSelect"
                                        v-model="groupsId"
                                        :options="groups"
                                        :searchable="true"
                                        label="name"
                                        mode="tags"
                                        placeholder="Select Group"
                                        trackBy="name"
                                        valueProp="id"
                                    />
                                    <p
                                        v-if="errors.group_id"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.group_id[0] }}
                                    </p>
                                </div>
                                <div class="col-span-6 sm:col-span-6 mb-4">
                                    <!-- Subject Area -->
                                    <fieldset>
                                        <legend
                                            class="text-base font-medium text-gray-900 mb-2"
                                        >
                                            Subject Area
                                        </legend>
                                        <MultiCheckbox
                                            v-model="form.subject_area_id"
                                            :error="
                                                errors.subject_area_id
                                                    ? errors.subject_area_id[0]
                                                    : null
                                            "
                                            :options="subjectAreas"
                                        />
                                    </fieldset>

                                    <!-- Categories -->
                                    <div class="mt-6">
                                        <fieldset>
                                            <legend
                                                class="text-base font-medium text-gray-900 mb-2"
                                            >
                                                Category
                                            </legend>
                                            <MultiCheckbox
                                                v-model="form.category_id"
                                                :error="
                                                    errors.category_id
                                                        ? errors.category_id[0]
                                                        : null
                                                "
                                                :options="categories"
                                            />
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <button
                                :class="{
                                    'bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500':
                                        !onWebsite,
                                    'button primary': onWebsite,
                                    'disabled:!cursor-wait': isDisabled,
                                }"
                                :disabled="isDisabled"
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import store from '@/store';
import { useRouter } from 'vue-router';
import request from '@/environment/request';
import '@vuepic/vue-datepicker/dist/main.css';
import { onMounted, reactive, ref, watch, watchEffect } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import AppCheckbox from '@/components/shared/Checkbox';
import Breadcrumb from '@/components/shared/Breadcrumb';
import InputComponent from './../shared/InputComponent';
import DropFile from '@/components/shared/DropFile';
import MultiSelect from '@vueform/multiselect';
import AppTextEditor from '@/components/shared/TextEditor';
import MultiCheckbox from '@/components/shared/MultiCheckbox';
import subjectAreasService from '@/services/subject-area';
import categoriesService from '@/services/category';
import userService from '@/services/user';
import permsMixin from '@/services/permsMixin';
import Asterisk from "@/components/shared/Asterisk";

// Imports for website section
import AppInput from '@js/Components/Shared/Input';
import AppInputError from '@js/Components/Shared/InputError';

let form = reactive({
    featured: false,
    cover: null,
    group_featured: false,
    website_id: [],
    category_id: [],
    subject_area_id: [],
    is_published: false,
    title: '',
    alt: '',
    body: '',
    summary: '',
    register: '',
    start_date: '',
    end_date: '',
    location: '',
    organizer: '',
    group_id: [],
    from_united_way: false,
    cover_location: 0,
});

const errors = ref('');
const websites = ref([]);
const router = useRouter();
const isDisabled = ref(false);
const types = reactive([
    { id: 1, name: 'Public' },
    { id: 2, name: 'Private' },
    { id: 3, name: 'Hidden' },
]);

const groups = ref([]);
const subjectAreas = ref([]);
const categories = ref([]);
const groupsId = ref([]);

const props = defineProps({
    onWebsite: Boolean,
    isPublished: Boolean,
    group: Object,
    userRole: String,
});

onMounted(() => {
    userService.getWebsites('me', { checkAdminAccess: false }).then((res) => {
        websites.value = res;
        form.website_id = websites.value[0].id;
        getGroups();
    });

    getSubjectAreas(props.group?.id || null);
    getCategories(props.group?.id || null);
});

// Submit the form
function submit() {
    if (props.userRole == 'site users') {
        form.is_published = props.isPublished;
        form.group_featured = true;
    }
    isDisabled.value = true;
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let formData = new FormData();

    formData.append('featured', props.onWebsite ? `false` : form.featured);
    formData.append('cover', form.cover ? form.cover : '');
    formData.append(
        'website_id',
        props.onWebsite ? `${props.group.website_id}` : form.website_id
    );
    formData.append('subject_area_id', form.subject_area_id);
    formData.append('title', form.title);
    formData.append('alt', form.alt);
    formData.append('body', form.body);
    formData.append('group_featured', form.group_featured);
    formData.append('summary', form.summary);
    formData.append('register', form.register);
    formData.append('start_date', form.start_date);
    formData.append('end_date', form.end_date);
    formData.append('location', form.location);
    formData.append('organizer', form.organizer);
    formData.append('is_published', form.is_published);
    formData.append('from_united_way', form.from_united_way);
    formData.append('cover_location', form.cover_location);
    formData.append(
        'group_id',
        props.onWebsite ? props.group.id : groupsId.value
    );
    formData.append('category_id', form.category_id);

    request(!props.onWebsite)
        .post('events', formData, config)
        .then(() => {
            let msg = 'New event has been successfully created.';
            if (!props.isPublished) {
                msg = 'The new event will be published after admin approval';
            }
            store.commit('displaySuccessModal', msg);
            if (props.onWebsite) {
                _toast.success({
                    title: 'Created event',
                    message: msg,
                });
                setTimeout(
                    () => (location.href = `/group/${props.group.slug}/event`),
                    5000
                );
            } else {
                router.push({ path: '/events' });
            }
        })
        .catch((err) => {
            isDisabled.value = false;
            if (err.response && err.response.status == 422) {
                errors.value = err.response.data.errors;
            }
        });
}

function onSelectFile(files) {
    form.cover = files[0];
}

function getGroups() {
    request(!props.onWebsite)
        .get(`get-groups?website_id=${form.website_id}`)
        .then((res) => {
            groups.value = res.data.data;
            form.group_id = '';
        });
}

function getSubjectAreas(groupId) {
    subjectAreasService.getAll(groupId).then((data) => {
        subjectAreas.value = data;
        form.subject_area_id = [];
    });
}

function getCategories(groupId) {
    categoriesService.getAll(groupId).then((data) => {
        categories.value = data;
        form.category_id = [];
    });
}

watch(
    () => groupsId.value,
    () => {
        getSubjectAreas(groupsId.value);
        getCategories(groupsId.value);
    }
);

watchEffect(() => {
    form.title.length > 3
        ? (form.alt = `The cover image for ${form.title}`)
        : (form.alt = '');
});

const { hasAccessToAdminPanel } = permsMixin.setup();
</script>
