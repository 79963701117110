<template>
  <div class="flex items-center flex-none gap-x-4">
    <a :href="`/user/${item.user.id}/profile`">
      <div
        :style="`background-image: url('` + item.user.thumbnail + `')`"
        class="h-12 w-12 flex-none rounded-full bg-no-repeat bg-cover bg-center bg-blue-light"
      ></div>
    </a>
    <div class="flex flex-col justify-center">
      <a :href="`/user/${item.user.id}/profile`">
        <div
          :title="item.user.name + ' ' + item.user.last_name"
          class="text-base sm:text-normal !leading-[1.3] text-blue-dark font-medium line-clamp-1"
          v-text="item.user.name + ' ' + item.user.last_name"
        ></div>
      </a>
      <p class="text-gray-darkest text-sm" v-text="item.time"></p>
    </div>
    <div class="ml-auto items-center gap-x-2 flex-none hidden md:flex">
      <label v-if="item.from_united_way" class="py-1 px-2 rounded-full bg-blue/50 text-white capitalize text-sm">
        FROM UNITED WAY
      </label>
      <label v-if="item.is_pinned" class="py-1 px-2 rounded-full bg-blue/50 text-white capitalize text-sm">
        Pinned
      </label>
      <label class="py-1 px-2 rounded-full bg-blue/50 text-white capitalize text-sm">
        {{ item.type }}
      </label>
    </div>
  </div>
  <div class="grid grid-cols-3 flex-auto gap-x-4">
    <div class="col-span-3 md:col-span-2 flex flex-col gap-y-4 sm:gap-y-6">
      <div class="flex flex-col flex-auto justify-between gap-y-4">
        <div class="flex-none">
          <a :href="`${baseUrl}/group/${groupSlug}/${thePostType}/${item.slug}`">
            <h4 class="text-2xl font-bold line-clamp-1 text-blue-dark" v-text="item.title"></h4>
          </a>
        </div>
        <div class="flex-auto">
          <div
            class="text-gray-600 text-base sm:text-normal line-clamp-3"
            v-html="item.summary || cleanHtml(item.description)"
          ></div>
        </div>

        <div class="md:hidden max-h-[20rem] relative rounded overflow-hidden">
          <img :src="item.image" alt="" class="h-full w-full object-center object-contain" />
        </div>
        <div class="flex items-center gap-x-2 md:hidden">
          <label v-if="item.from_united_way" class="py-1 px-2 rounded-full bg-blue/50 text-white capitalize text-sm">
            FROM UNITED WAY
          </label>
          <label class="py-1 px-2 rounded-full bg-blue/50 text-white capitalize text-sm">
            {{ item.type }}
          </label>
        </div>
      </div>
    </div>
    <div class="h-full overflow-hidden relative hidden md:flex justify-center items-center">
      <img
        :src="item.image"
        alt=""
        class="absolute inset-0 h-full w-full object-center object-contain transition-transform duration-300 hover:scale-110 rounded"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { cleanHtml } from '@/support/utils'

const showPreviewModal = ref(false)
const currentPreviewImageSrc = ref(false)

const props = defineProps({
  item: {
    type: Object,
  },
  index: {
    type: Number,
  },
  baseUrl: {
    type: String,
  },
  groupSlug: {
    type: String,
  },
})

let postType = 'post'
if (props.item.type === 'event') {
  postType = 'event'
} else if (props.item.type === 'discussion') {
  postType = 'discussion'
} else if (props.item.type === 'resource') {
  postType = 'resource'
}
const thePostType = ref(postType)
</script>
