<template>
    <div
        ref="inputEl"
        :class="{ 'bg-transparent': disabled, 'bg-white': !disabled }"
        :contenteditable="!disabled"
        :data-placeholder="placeholder"
        class="editable-div has-mentions py-3 w-full h-full overflow-y-auto shadow-none focus:outline focus:outline-1 focus:outline-blue focus-visible:outline-blue border border-gray rounded-[25px] pl-6 pr-12"
        @input="onInputChange"
        @keydown.enter="onKeyDown"
    ></div>
</template>

<script setup>
import Tribute from 'tributejs';
import { onMounted, ref, watch } from 'vue';

const data = ref();
const inputEl = ref(null);

const emit = defineEmits(['keyboardSend', 'update:modelValue']);

const props = defineProps({
    modelValue: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: 'Leave your comment',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    users: {
        type: Array,
        default: [],
    },
});

const onInputChange = (event) => {
    emit('update:modelValue', event.currentTarget.innerHTML);
};

const onKeyDown = (e) => {
    if (e.metaKey || e.ctrlKey) {
        emit('keyboardSend');
    }
};

const updateText = () => {
    if (inputEl) {
        const el = inputEl.value;

        if (el.innerHTML !== props.modelValue) {
            el.innerHTML = props.modelValue;
        }
    }
};

const getThumbnailTag = (item) => {
    return `<div
                class="h-4 w-4 rounded-full bg-no-repeat mr-2 bg-cover bg-center bg-blue-light shadow"
                :style="'background-image: url(${item.original.thumbnail})'"></div>`;
};

const initTribute = () => {
    const tribute = new Tribute({
        values: props.users,
        selectTemplate: function (item) {
            return `<span data-user-id="${item.original.id}" contenteditable="false">${item.original.name} ${item.original.last_name}</span>`;
        },
        menuItemTemplate: function (item) {
            return `<span class="flex items-center text-blue-dark">${getThumbnailTag(
                item
            )} ${item.original.name} ${item.original.last_name}</span>`;
        },
        noMatchTemplate: function () {
            return '<span style:"visibility: hidden;"></span>';
        },
        lookup: function (item) {
            return item.name + item.last_name;
        },
    });

    tribute.attach(inputEl.value);
};

watch(
    () => props.modelValue,
    () => {
        updateText();
    }
);

onMounted(() => {
    initTribute();
    updateText();
});
</script>

<style>
.has-mentions span {
    padding: 0 3px;
    background: #35afc857;
    border-radius: 3px;
}

.tribute-container {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    max-height: 200px;
    overflow: auto;
    display: block;
    border: #e2e8f0 solid 1px;
    border-radius: 5px;
    z-index: 999999;
}
.tribute-container ul {
    margin: 0;
    padding: 0;
    list-style: none;
    background: white;
}
.tribute-container li {
    padding: 3px 10px;
    cursor: pointer;
}
.tribute-container li.highlight {
    background: #35afc857;
}
.tribute-container li.no-match {
    cursor: default;
}
.tribute-container .menu-highlighted {
    /*font-weight: bold;*/
}

.editable-div:empty:before {
    content: attr(data-placeholder);
    color: grey;
    pointer-events: none;
    display: block;
}
</style>
