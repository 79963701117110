import { createApp } from 'vue';
import GroupView from './src/components/group/GroupView';
import DetailsPostView from './src/components/group/components/DetailsPostView.vue';
import CommentInput from './src/components/discussion/CommentInput';
import VSelect from './src/components/ui/VSelect';

const groupApp = createApp({});

groupApp.component('comment-input', CommentInput);
groupApp.component('group-view', GroupView);
groupApp.component('details-post-view', DetailsPostView);
groupApp.component('v-select', VSelect);

groupApp.mount('#group-component');
