<template>
    <div v-if="images" class="bg-white py-3 px-4 shadow mt-4">
        <p class="text-sm font-medium text-gray-500 my-2">File Attachments</p>
        <div v-if="images.files && images.files.length > 0" class="mt-2">
            <div v-if="!itemId" class="mt-8 flex flex-col">
                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div
                        class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
                    >
                        <div
                            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                        >
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Download</th>
                                        <th v-if="deleting" scope="col">
                                            <span class="sr-only">Actions</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="images.files">
                                    <tr v-if="images.files.length === 0">
                                        <td colspan="4">
                                            <p class="text-center mx-4 my-8">
                                                There is not data available!
                                            </p>
                                        </td>
                                    </tr>
                                    <tr
                                        v-for="(file, index) in images.files"
                                        :key="index"
                                    >
                                        <td>{{ file.name }}</td>
                                        <td>{{ file.file_type }}</td>
                                        <td class="max-w-[9rem] truncate">
                                            <span
                                                v-if="
                                                    file.file_type != 'embed' &&
                                                    file.file_type != 'link'
                                                "
                                                class="cursor-pointer hover:text-blue-400"
                                                @click="
                                                    downloadFile(
                                                        file.name,
                                                        file.id,
                                                        file.file_type
                                                    )
                                                "
                                            >
                                                Download
                                            </span>
                                            <span v-else class="cursor-pointer">
                                                <a
                                                    :href="file.path"
                                                    :title="file.name"
                                                    target="_blank"
                                                >
                                                    View
                                                </a>
                                            </span>
                                        </td>

                                        <td v-if="deleting">
                                            <a
                                                class="text-red-600 cursor-pointer hover:text-red-900 ml-2"
                                                href="javascript:"
                                                @click="
                                                    deleteImage(file.id, index)
                                                "
                                            >
                                                <svg
                                                    class="w-6 h-6 inline"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <table class="min-w-full table-fixed border-solid">
                    <tr>
                        <th class="text-left" scope="col">Name</th>
                        <th class="text-left" scope="col">Type</th>
                        <th class="text-left" scope="col">Download</th>
                        <th class="text-left" scope="col">Delete</th>
                    </tr>
                    <tr v-for="(file, index) in images.files" :key="index">
                        <td>{{ file.name }}</td>
                        <td>{{ file.file_type }}</td>
                        <td class="max-w-[9rem] truncate">
                            <span
                                v-if="
                                    file.file_type != 'embed' &&
                                    file.file_type != 'link'
                                "
                                class="cursor-pointer hover:text-blue-400"
                                @click="
                                    downloadFile(
                                        file.name,
                                        file.id,
                                        file.file_type
                                    )
                                "
                            >
                                Download
                            </span>
                            <span v-else class="cursor-pointer">
                                <a
                                    :href="file.path"
                                    :title="file.name"
                                    target="_blank"
                                >
                                    View
                                </a>
                            </span>
                        </td>
                        <td class="py-2 text-center">
                            <a
                                class="text-red cursor-pointer"
                                href="javascript:"
                                @click="deleteImage(file.id, index)"
                            >
                                <svg
                                    class="w-6 h-6 inline"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </a>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <template v-if="uploading">
        <div class="mt-8 sm:flex sm:items-center">
            <div
                class="text-sm font-medium text-gray-500 hover:text-gray-700 max-w-md truncate"
            >
                File Attachment
            </div>
        </div>
        <div v-if="images" class="bg-white py-6 px-4 shadow mt-4">
            <div
                v-if="uploading"
                class="mt-1 border-2 border-gray-300 border-dashed rounded-md px-6 pt-5 pb-6 mb-2 flex justify-center"
                v-bind="getRootProps()"
            >
                <div class="space-y-1 text-center">
                    <span v-if="waiting">Please wait....</span>
                    <svg
                        aria-hidden="true"
                        class="mx-auto h-12 w-12 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 48 48"
                    >
                        <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                        ></path>
                    </svg>

                    <div v-if="file[0]">
                        <img
                            v-if="fileType == 'image'"
                            :src="preview"
                            height="300"
                            width="400"
                        />
                        <template v-else-if="fileType === 'video'">
                            <video autoplay muted width="600">
                                <source :src="preview" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </template>
                        <div
                            class="text-sm leading-5 font-medium text-pink-600 truncate"
                        >
                            <p>{{ file[0].name }} ({{ file[0].size }})</p>
                        </div>
                    </div>
                    <div
                        class="flex justify-center text-sm text-gray-600 mx-auto"
                    >
                        <label
                            class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            for="multi-file-uploading"
                            @click="open"
                        >
                            <input v-bind="getInputProps()" />
                            <span>Drag & drop a file here ...</span>
                        </label>
                        <p v-if="isDragActive"></p>
                        <p v-else>, or click to select file</p>
                    </div>
                    <p class="text-xs text-gray-500">
                        PNG, JPG, JPEG, MP4, PPT, DOC and PDF up to 20MB
                    </p>
                    <p v-if="errors.file" class="mt-2 text-sm text-red-500">
                        {{ errors.file ? errors.file[0] : null }}
                    </p>
                </div>
            </div>
            <div class="text-right">
                <button
                    v-if="uploading"
                    :class="{
                        'bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500':
                            !itemId,
                        'button   primary': itemId,
                    }"
                    @click="submit"
                >
                    Upload File
                </button>
            </div>
        </div>

        <!-- Add new embed video -->
        <div class="mt-8 sm:flex sm:items-center">
            <div
                class="text-sm font-medium text-gray-500 hover:text-gray-700 max-w-md truncate"
            >
                New Embed Video
            </div>
        </div>
        <div class="mt-2 flex flex-col">
            <div class="space-y-6 lg:px-0 lg:col-span-9">
                <form action="#" @submit.prevent="embedVideo">
                    <div class="shadow sm:rounded-md sm:overflow-hidden">
                        <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
                            <div class="grid grid-cols-6 gap-6">
                                <!-- Name -->
                                <div class="col-span-6 sm:col-span-3">
                                    <template v-if="!itemId">
                                        <input-component
                                            id="name"
                                            v-model="embedForm.name"
                                            :ErrorMessage="
                                                embedVideoErrors.name
                                                    ? embedVideoErrors.name[0]
                                                    : null
                                            "
                                            labelText="Name"
                                            placeholder="Name"
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="embedForm.name"
                                            label="Name"
                                        />
                                        <AppInputError
                                            v-if="embedVideoErrors.name"
                                            :message="embedVideoErrors.name[0]"
                                            class="mt-2"
                                        />
                                    </template>
                                </div>

                                <!-- Embed Video -->
                                <div class="col-span-6 sm:col-span-3">
                                    <template v-if="!itemId">
                                        <input-component
                                            id="embed-video"
                                            v-model="embedForm.path"
                                            :ErrorMessage="
                                                embedVideoErrors.path
                                                    ? embedVideoErrors.path[0]
                                                    : null
                                            "
                                            labelText="Embed Video"
                                            placeholder="Embed Video"
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="embedForm.path"
                                            label="Embed Video"
                                        />
                                        <AppInputError
                                            v-if="embedVideoErrors.path"
                                            :message="embedVideoErrors.path[0]"
                                            class="mt-2"
                                        />
                                        <p class="mt-2 text-sm text-gray-400">
                                            <span class="font-medium"
                                                >Youtube:</span
                                            >
                                            https://www.youtube.com/watch?v=000000001
                                        </p>
                                        <p class="mt-2 text-sm text-gray-400">
                                            <span class="font-medium"
                                                >Vimeo:</span
                                            >
                                            https://vimeo.com/000000001
                                        </p>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <!-- Submit button -->
                        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <button
                                :class="{
                                    'bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500':
                                        !itemId,
                                    'button   primary': itemId,
                                }"
                                :disabled="isDisabled"
                                type="submit"
                            >
                                Save Embed
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- Add new link -->
        <div class="mt-8 sm:flex sm:items-center">
            <div
                class="text-sm font-medium text-gray-500 hover:text-gray-700 max-w-md truncate"
            >
                New Link
            </div>
        </div>
        <div class="mt-2 flex flex-col">
            <div class="space-y-6 lg:px-0 lg:col-span-9">
                <form action="#" @submit.prevent="link">
                    <div class="shadow sm:rounded-md sm:overflow-hidden">
                        <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
                            <div class="grid grid-cols-6 gap-6">
                                <!-- Name -->
                                <div class="col-span-6 sm:col-span-3">
                                    <template v-if="!itemId">
                                        <input-component
                                            id="name"
                                            v-model="linkForm.name"
                                            :ErrorMessage="
                                                linkErrors.name
                                                    ? linkErrors.name[0]
                                                    : null
                                            "
                                            labelText="Name"
                                            placeholder="Name"
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="linkForm.name"
                                            label="Name"
                                        />
                                        <AppInputError
                                            v-if="linkErrors.name"
                                            :message="linkErrors.name[0]"
                                            class="mt-2"
                                        />
                                    </template>
                                </div>

                                <!-- New Link -->
                                <div class="col-span-6 sm:col-span-3">
                                    <template v-if="!itemId">
                                        <input-component
                                            id="link"
                                            v-model="linkForm.path"
                                            :ErrorMessage="
                                                linkErrors.path
                                                    ? linkErrors.path[0]
                                                    : null
                                            "
                                            labelText="Link"
                                            placeholder="Link"
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="linkForm.path"
                                            label="Link"
                                        />
                                        <AppInputError
                                            v-if="linkErrors.path"
                                            :message="linkErrors.path[0]"
                                            class="mt-2"
                                        />
                                    </template>
                                </div>
                            </div>
                        </div>

                        <!-- Submit button -->
                        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <button
                                :class="{
                                    'bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500':
                                        !itemId,
                                    'button   primary': itemId,
                                }"
                                :disabled="isDisabled"
                                type="submit"
                            >
                                Save Link
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </template>
</template>
<script setup>
import { onMounted, reactive, ref } from 'vue';
import store from './../../store';
import { useRoute } from 'vue-router';
import { useDropzone } from 'vue3-dropzone';
import request from '../../environment/request';
import InputComponent from '@/components/shared/InputComponent';

// Imports for website section
import AppInput from '@js/Components/Shared/Input';
import AppInputError from '@js/Components/Shared/InputError';

const route = useRoute();
const errors = ref('');
const program = ref([]);
const waiting = ref(false);
const open = ref(false);
const preview = ref(null);
const isDragActive = ref(true);

const props = defineProps({
    uploading: {
        type: Boolean,
        default: false,
    },
    deleting: {
        type: Boolean,
        default: false,
    },

    modelType: {
        type: String,
        default: '',
    },
    itemId: {
        type: Number,
        default: null,
    },
    files: Array,
});

const images = ref();
const file = ref('');
const fileType = ref('');
const id = props.itemId ? props.itemId : route.params.id;

function onDrop(acceptFiles, rejectReasons) {
    file.value = acceptFiles;

    // Allowed file types
    const fileTypes = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'video/mp4',
        'video/mov',
        'audio/mp3',
        'application/pdf',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.ms-powerpoint',
        'application/msword',
        'application/vnd.ms-office',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    // Check file types
    if (file.value[0] && fileTypes.includes(file.value[0].type)) {
        preview.value = URL.createObjectURL(file.value[0]);
        fileType.value = file.value[0].type.split('/')[0];
    } else {
        file.value = '';
        preview.value = '';

        alert('Please select a valid file. Thanks');
    }

    // check if file size be greater then 20MB
    if (file.value[0] && file.value[0].size / 20000000 > 1) {
        file.value = '';
        preview.value = '';

        alert('Sorry! your file size should be less then 20MB.');
    }
}

const options = reactive({
    multiple: false,
    onDrop,
    accept: '.jpg, .jpeg, .png, .mp4, .pdf, .ppt, .pptx ,.xlsx ,.xls ,.doc ,.docx,.dotx',
});

onMounted(() => {
    images.value = props;
});

function downloadFile(fileName, id, fileType) {
    if (fileType != 'embed') {
        axios({
            url: `/api/files/download/${id}`,
            method: 'GET',
            responseType: 'arraybuffer',
        }).then((response) => {
            let blob = new Blob([response.data], {
                type: fileType,
            });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        });
    }
}

// Submit the form
function submit() {
    errors.value = '';
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let formData = new FormData();

    formData.append('fileable_type', props.modelType);

    //for (var x = 0; x < file.value.length; x++) {
    formData.append('file', file.value[0]);
    // }
    waiting.value = true;
    request(true)
        .post(`files/uploading-file/${id}`, formData, config)
        .then((res) => {
            waiting.value = false;
            images.value = res.data;
            file.value = '';
            preview.value = '';
            fileType.value = '';

            store.commit('displaySuccessModal', 'Files successfully uploaded.');
        })
        .catch((err) => {
            waiting.value = false;
            if (err.response && err.response.status == 422) {
                errors.value = err.response.data.errors;
            }
        });
}

function deleteImage(id, index) {
    if (confirm('Are you sure to delete this file?')) {
        waiting.value = true;
        request(true)
            .post(`files/delete-image/${id}`)
            .then((res) => {
                waiting.value = false;
                if (res.data.message) {
                    store.commit('displayNetworkErrorModal', res.data.message);
                } else {
                    images.value = res.data;
                    store.commit(
                        'displaySuccessModal',
                        'File successfully deleted.'
                    );
                }
            })
            .catch((err) => {
                waiting.value = false;
                if (err.response && err.response.status == 422) {
                    errors.value = err.response.data.errors;
                }
            });
    }
}

function previewImage(path) {
    preview.value = path;
    open.value = true;
}

const embedForm = reactive({
    path: null,
    name: null,
    file_type: 'embed',
    fileable_type: props.modelType,
});

const isDisabled = ref(false);
const embedVideoErrors = ref('');

function embedVideo() {
    embedVideoErrors.value = '';
    isDisabled.value = true;
    //const config = {headers: { "Content-Type": "application/json", }}

    request(true)
        .post(`files/embed-video/${id}`, embedForm)
        .then((res) => {
            isDisabled.value = false;
            images.value = res.data;
            clearEmbedVideoForm();

            store.commit('displaySuccessModal', 'Data successfully added.');
        })
        .catch((err) => {
            isDisabled.value = false;
            if (err.response && err.response.status == 422) {
                embedVideoErrors.value = err.response.data.errors;
            }
        });
}

function clearEmbedVideoForm() {
    embedForm.name = null;
    embedForm.path = null;
}

const linkForm = reactive({
    path: null,
    name: null,
    file_type: 'link',
    fileable_type: props.modelType,
});

const linkErrors = ref('');

function link() {
    linkErrors.value = '';
    isDisabled.value = true;

    request(true)
        .post(`files/embed-video/${id}`, linkForm)
        .then((res) => {
            isDisabled.value = false;
            images.value = res.data;
            clearLinkForm();

            store.commit('displaySuccessModal', 'Data successfully added.');
        })
        .catch((err) => {
            isDisabled.value = false;
            if (err.response && err.response.status == 422) {
                linkErrors.value = err.response.data.errors;
            }
        });
}

function clearLinkForm() {
    linkForm.name = null;
    linkForm.path = null;
}

const { getRootProps, getInputProps, ...rest } = useDropzone(options);
</script>
