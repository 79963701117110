<template>
  <div v-if="discussion" class="container">
    <div class="sm:flex sm:items-center">
      <div v-if="!onWebsite" class="sm:flex-auto">
        <Breadcrumb
          :pages="[
            {
              name: 'Discussions List',
              href: '/discussions',
              current: false,
            },
            { name: 'Edit Discussion', href: null, current: true },
          ]"
        />
      </div>
      <div v-if="discussion && !onWebsite" class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <router-link :to="`/discussions/${discussion.id}`">
          <button
            class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            type="button"
          >
            View
          </button>
        </router-link>
      </div>
    </div>

    <div class="mt-8 flex flex-col">
      <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <form action="#" @submit.prevent="saveDiscussion">
          <div
            :class="{
              'shadow sm:rounded-md sm:overflow-hidden': !onWebsite,
            }"
          >
            <div
              :class="{
                'bg-white py-6 px-4 space-y-6 sm:p-6': !onWebsite,
              }"
            >
              <div class="grid grid-cols-6 gap-6">
                <div v-if="!onWebsite" class="col-span-6">
                  <fieldset>
                    <AppLabel asterisk for="website-checkboxes">Website</AppLabel>
                    <div class="mt-2 flex items-center space-x-2">
                      <AppCheckbox
                        id="website_id"
                        :checked="true"
                        class="cursor-not-allowed"
                        disabled="disabled"
                        name="website_id"
                      />
                      <label v-if="discussion.website" class="font-medium text-sm text-gray-700 cursor-not-allowed">{{
                        discussion.website.name
                      }}</label>
                    </div>
                  </fieldset>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <template v-if="!onWebsite">
                    <input-component
                      id="title"
                      v-model="form.title"
                      :ErrorMessage="errors.title ? errors.title[0] : null"
                      asterisk
                      labelText="Title"
                      placeholder="Tile"
                    />
                  </template>
                  <template v-else>
                    <AppInput v-model="form.title" label="Title" />
                    <AppInputError v-if="errors.title" :message="errors.title[0]" class="mt-2" />
                  </template>
                </div>
                <div v-if="!onWebsite" class="col-span-6 sm:col-span-3">
                  <label class="form-input-label" for="group_id"
                    >Group
                    <Asterisk />
                  </label>
                  <MultiSelect
                    id="group_id"
                    ref="multiSelect"
                    v-model="form.group_id"
                    :options="groups"
                    label="name"
                    placeholder="Select Group"
                    trackBy="name"
                    valueProp="id"
                  />
                  <p v-if="errors.group_id" class="mt-2 text-sm text-red-500">
                    {{ errors.group_id[0] }}
                  </p>
                </div>

                <div class="col-span-6">
                  <fieldset>
                    <label class="block text-sm font-medium text-gray-700" for="street-address"
                      >Description
                      <Asterisk />
                    </label>
                    <AppTextEditor v-model="form.description" />
                    <p v-if="errors.description" class="mt-2 text-sm text-red-500">
                      {{ errors.description[0] }}
                    </p>
                  </fieldset>
                </div>

                <!-- Published -->
                <div v-if="userRole != 'site users' && hasAccessToAdminPanel" class="col-span-3 sm:col-span-3">
                  <div class="flex items-center space-x-4">
                    <AppCheckbox id="published" v-model:checked="form.is_published" name="is_published" />
                    <label class="block text-sm font-medium text-gray-700" for="published">Published</label>
                  </div>
                  <p v-if="errors.is_published" class="mt-2 text-sm text-red-500">
                    {{ errors.is_published[0] }}
                  </p>
                </div>
                <div v-if="!onWebsite && hasAccessToAdminPanel" class="col-span-3 sm:col-span-3">
                  <div class="flex items-center space-x-4">
                    <AppCheckbox id="featured" v-model:checked="form.featured" name="featured" />
                    <label class="block text-sm font-medium text-gray-700" for="featured">Featured in Home Page</label>
                  </div>
                  <p v-if="errors.featured" class="mt-2 text-sm text-red-500">
                    {{ errors.featured[0] }}
                  </p>
                </div>
                <div v-if="userRole != 'site users' && hasAccessToAdminPanel" class="col-span-3 sm:col-span-3">
                  <div class="flex items-center space-x-4">
                    <AppCheckbox id="featured_group" v-model:checked="form.group_featured" name="featured_group" />
                    <label class="block text-sm font-medium text-gray-700" for="featured_group"
                      >Featured in group</label
                    >
                  </div>
                  <p v-if="errors.group_featured" class="mt-2 text-sm text-red-500">
                    {{ errors.group_featured[0] }}
                  </p>
                </div>
                <div class="col-span-3 sm:col-span-3">
                  <div v-if="!onWebsite && hasAccessToAdminPanel" class="flex items-center space-x-4">
                    <AppCheckbox id="from_united_way" v-model:checked="form.from_united_way" name="from_united_way" />
                    <label class="block text-sm font-medium text-gray-700" for="from_united_way">From United Way</label>
                  </div>
                  <p v-if="errors.from_united_way" class="mt-2 text-sm text-red-500">
                    {{ errors.from_united_way[0] }}
                  </p>
                </div>

                <!-- Cover Photo -->
                <div class="col-span-6">
                  <DropFile
                    :coverLocation="form.cover_location"
                    :file="form.cover"
                    customCoverLocation
                    @changeCoverLocation="($event) => (form.cover_location = $event)"
                    @fileSelected="onSelectFile"
                  />
                </div>
                <div class="col-span-6">
                  <fieldset>
                    <legend class="text-base font-medium text-gray-900">Audience</legend>
                    <MultiCheckbox
                      v-model="form.audience_id"
                      :error="errors.audience_id ? errors.audience_id[0] : null"
                      :options="audiences"
                    />
                  </fieldset>
                </div>
                <div class="col-span-6">
                  <fieldset>
                    <legend class="text-base font-medium text-gray-900">Subject Area</legend>
                    <MultiCheckbox
                      v-model="form.subject_area_id"
                      :error="errors.subject_area_id ? errors.subject_area_id[0] : null"
                      :options="subjectAreas"
                    />
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                :class="{
                  'bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500':
                    !onWebsite,
                  'button primary': onWebsite,
                  'disabled:!cursor-wait': isDisabled,
                }"
                :disabled="isDisabled"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
      <DropZoneComponent
        v-if="!onWebsite"
        :deleting="true"
        :files="discussion.files"
        :uploading="true"
        modelType="App\Models\Discussion"
      />
    </div>
  </div>
</template>

<script setup>
import store from '@/store'
import request from '@/environment/request'
import { onMounted, reactive, ref, watch } from 'vue'
import MultiSelect from '@vueform/multiselect'
import { useRoute, useRouter } from 'vue-router'
import AppLabel from '@/components/shared/Label'
import DropFile from '@/components/shared/DropFile'
import AppCheckbox from '@/components/shared/Checkbox'
import Breadcrumb from '@/components/shared/Breadcrumb'
import AppTextEditor from '@/components/shared/TextEditor'
import InputComponent from '@/components/shared/InputComponent'
import DropZoneComponent from '@/components/shared/DropZoneComponent'
import MultiCheckbox from '@/components/shared/MultiCheckbox'
import audiencesService from '@/services/audience'
import subjectAreasService from '@/services/subject-area'
import permsMixin from '@/services/permsMixin'
import Asterisk from '@/components/shared/Asterisk'

// Imports for website section
import AppInput from '@js/Components/Shared/Input'
import AppInputError from '@js/Components/Shared/InputError'

const errors = ref([])
const groups = ref([])
const types = ref([])
const cover = ref(null)
const audiences = ref([])
const subjectAreas = ref([])

const router = useRouter()
const route = useRoute()

const props = defineProps({
  onWebsite: Boolean,
  group: Object,
  discussionId: Number,
  userRole: String,
})

const form = reactive({
  title: '',
  cover: null,
  audience_id: [],
  group_id: '',
  website_id: '',
  description: '',
  featured: false,
  subject_area_id: [],
  group_featured: false,
  from_united_way: false,
  id: props.onWebsite ? props.discussionId : route.params.id,
  is_published: false,
  cover_location: 0,
})

let id = props.onWebsite ? props.discussionId : route.params.id
const discussion = ref(null)
onMounted(() => {
  request(!props.onWebsite)
    .get('discussions/' + id)
    .then((response) => {
      discussion.value = response.data.data
      console.log(discussion.value)
      assignValue(discussion.value)
      getGroup()

      if (!discussion.value.group?.id) {
        getSubjectAreas(form.group_id)
      }
    })

  audiencesService.getAll().then((data) => {
    audiences.value = data
  })
})

function getGroup() {
  request(!props.onWebsite)
    .get(`get-groups?website_id=${discussion.value.website.id}`)
    .then((response) => (groups.value = response.data.data))
}

const isDisabled = ref(false)

function saveDiscussion() {
  isDisabled.value = true

  const config = { headers: { 'Content-Type': 'multipart/form-data' } }
  let formData = new FormData()

  formData.append('title', form.title)
  formData.append('slug', form.slug)
  formData.append('cover', fileSelected.value == true ? form.cover : '')
  formData.append('group_id', form.group_id)
  formData.append('_method', 'PATCH')
  formData.append('description', form.description)
  formData.append('featured', form.featured)
  formData.append('from_united_way', form.from_united_way)
  formData.append('group_featured', form.group_featured)
  formData.append('is_published', form.is_published)
  formData.append('audience_id', form.audience_id)
  formData.append('subject_area_id', form.subject_area_id)
  formData.append('cover_location', form.cover_location)

  request(!props.onWebsite)
    .post(`discussions/${form.id}`, formData, config)
    .then(() => {
      store.commit('displaySuccessModal', 'Successfully updated.')
      if (props.onWebsite) {
        _toast.success({
          title: 'Updated discussion',
          message: 'Discussion has been successfully updated.',
        })
        setTimeout(() => (location.href = `/group/${props.group.slug}/discussion/${discussion.value.slug}`), 500)
      } else {
        router.push({ path: `/discussions/${form.id}` })
      }
    })
    .catch((err) => {
      isDisabled.value = false
      if (err.response && err.response.status == 422) {
        errors.value = err.response.data.errors
      }
    })
}

function selectedCover($event) {
  const formData = new FormData()
  formData.append('file', $event.target.files[0])
  const headers = { 'Content-Type': 'multipart/form-data' }
  request(!props.onWebsite)
    .post('upload', formData, { headers })
    .then((res) => {
      cover.value = res.data.path
      form.cover = cover.value
    })
    .catch((error) => (errors.value = error.response.data.errors))
}

function updateSubjectAreas(subjects) {
  form.subject_area_id = subjects
}

function updateAudiences(audience) {
  form.audience_id = audience
}

function assignValue(discussion) {
  form.id = discussion.id
  form.website_id = discussion.website.id ? true : false
  form.cover = discussion.cover
  form.title = discussion.title
  form.slug = discussion.slug
  form.group_id = discussion.group ? discussion.group.id : ''
  form.is_published = discussion.is_published
  form.description = discussion.description
  form.featured = discussion.featured == 1 ? true : false
  form.group_featured = discussion.group_featured === 1 ? true : false
  form.from_united_way = discussion.from_united_way === 1 ? true : false
  form.subject_area_id = discussion.subject_areas.map((item) => item.id)
  form.audience_id = discussion.audiences.map((item) => item.id)
  form.cover_location = discussion.cover_location
}

const fileSelected = ref(false)

function onSelectFile(files) {
  form.cover = files[0]
  fileSelected.value = true
}

function getSubjectAreas(groupId) {
  subjectAreasService.getAll(groupId).then((data) => {
    subjectAreas.value = data
    assignSubjectArea()
  })
}

function assignSubjectArea() {
  let subjectAreasId = subjectAreas.value.map((item) => item.id)
  let selectedSubjectAreasId = discussion.value.subject_areas.map((item) => item.id)

  form.subject_area_id = selectedSubjectAreasId.filter((item) => {
    return subjectAreasId.includes(item)
  })
}

watch(
  () => form.group_id,
  () => {
    getSubjectAreas(form.group_id)
  }
)

const { hasAccessToAdminPanel } = permsMixin.setup()
</script>
