<template>
  <div class="flex items-center">
    <p class="text-sm sm:text-normal text-gray-darkest mr-2">
      {{ currentFolder ? currentFolder.name : 'Add to folder' }}
    </p>
    <div class="cursor-pointer" @click="changeFolder = !changeFolder">
      <i class="fa-solid fa-edit"></i>
    </div>
  </div>
    <div v-if="changeFolder">
      <form @submit.prevent="selectFolder()" method="POST">
        <v-select v-model="selectedFolder">
          <option disabled :selected="!selectedFolder" value="null">Select Folder</option>
          <option v-for="folder in folders" :value="folder.id" :key="folder.id" :selected="selectedFolder && selectedFolder === folder.id">
            {{ folder.name }}
          </option>
        </v-select>
        <div class="flex justify-end mt-4 sm:mt-6 gap-x-4">
          <button v-if="selectedFolder" @click="removeFromFolder()" type="button" class="button tertiary w-full">Remove</button>
          <button type="submit" class="button primary w-full">Save</button>
        </div>
      </form>
    </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import VSelect from "../ui/VSelect.vue";

const props = defineProps({
  groupId: String,
  resourceId: Number,
  currentFolder: Object
});

const changeFolder = ref(false);
const folders = ref([]);
const selectedFolder = ref();

onMounted(() => {
  if (props.currentFolder) {
    selectedFolder.value = props.currentFolder.id;
  }

  fetchFolder();
});
function fetchFolder() {
  axios.get(`/api/folders/get-folders/${props.groupId}`)
    .then((response) => {
      folders.value = response.data.data
    });
}

function selectFolder() {
  if(!selectedFolder.value) {
    _toast.error({
      title: "An error ocurred!",
      message: 'Please select a folder.',
    });
    return;
  }
  axios.post(`/api/folders/add-to-folder/${props.resourceId}`, { folder_id: selectedFolder.value })
    .then(() => {
      changeFolder.value = false
      _toast.success({
        title: "Updated folder",
        message: 'Please wait while page is refreshed',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);

    })
    .catch(err => {
      if (err.response && err.response.status == 422) {
        errors.value = err.response.data.errors;
      }
    })

}


function removeFromFolder() {
  selectedFolder.value = null;
  axios.post(`/api/folders/add-to-folder/${props.resourceId}`, { folder_id: selectedFolder.value })
    .then(() => {
      changeFolder.value = false
      _toast.success({
        title: "Removed from folder!",
        message: 'Please wait while page is refreshed',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch(err => {
      if (err.response && err.response.status == 422) {
        errors.value = err.response.data.errors;
      }
    })
}
</script>
