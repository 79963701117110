import request from '@js/src/environment/request';

export default {

  getAll(groupId = '') {
    let url = 'admin/get-subject-areas';
    if(groupId){
      url = `admin/get-subject-areas?group_id=${groupId}`
    }
    return request()
      .get(url)
      .then(({ data: { data } }) => {
        return data;
      });
  }

}