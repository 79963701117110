<template>
    <label :for="name" :value="modelValue" class="sr-only">{{ label }}</label>
    <select :name="name" :disabled="disabled" @change="$emit('update:modelValue', $event.target.value)" class="bg-transparent block w-full px-3 py-4 text-base text-blue-dark border border-gray-dark focus:outline-none focus:ring-transparent hover:border-blue focus:border-blue focus:shadow-none sm:text-sm rounded-[5px]">
        <slot />
    </select>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: [String, Object],
            default: null
        }
    }
}
</script>

<style scoped>

</style>
