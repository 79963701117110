import request from '@js/src/environment/request';

export default {

  getAll() {
    return request()
      .get('admin/get-audiences')
      .then(({ data: { data } }) => {
        return data;
      });
  }

}