import Countdown from "./Components/Event2024/Countdown.vue";

require('./bootstrap');
window._toast = require('izitoast');
window._toast.settings({
    position: 'topCenter',
    closeOnClick: true,
    closeOnEscape: true,
    titleSize: 16,
    transitionIn: 'fadeIn',
});

import { createApp } from 'vue';
import VCalendar from 'v-calendar';

import Chat from './Components/Chat/Chat';
import StartConversation from './Components/Chat/StartConversation';
import ConversationMessages from './Components/Chat/ConversationMessages';
import CreateResource from './src/components/resource/Create';
import CreateEvent from './src/components/event/Create';
import CreateDiscussion from './src/components/discussion/Create';

import EditResource from './src/components/resource/Edit';
import EditEvent from './src/components/event/Edit';
import EditDiscussion from './src/components/discussion/Edit';

import Calendar from './src/components/calendar/Calendar';
import Alpine from 'alpinejs';
import Accordion from "./Components/Shared/Accordion/Accordion.vue";
import Event2024Speakers from "./Components/Event2024/Event2024Speakers.vue";
import Event2024Sessions from "./Components/Event2024/Event2024Sessions.vue";

const app = createApp({});

app.component('Chat', Chat);
app.component('StartConversation', StartConversation);
app.component('ConversationMessages', ConversationMessages);
app.component('CreateResource', CreateResource);
app.component('CreateEvent', CreateEvent);
app.component('CreateDiscussion', CreateDiscussion);

app.component('EditResource', EditResource);
app.component('EditEvent', EditEvent);
app.component('EditDiscussion', EditDiscussion);
app.component('Events2024Countdown', Countdown);
app.component('Event2024Speakers', Event2024Speakers);
app.component('Event2024Sessions', Event2024Sessions);
app.component('Accordion', Accordion);

app.use(VCalendar, {});
app.component('Calendar', Calendar);

if (document.querySelector('#use-vue-component')) {
    app.mount('#use-vue-component');
}

if (document.querySelector('#group-component')) {
    require('./group');
}

if (document.querySelector('#group-settings-component')) {
    require('./group-settings');
}

window.Alpine = Alpine;

Alpine.start();

const profilePhotoInput = document.getElementById('file');

const profilePhoto = document.getElementById('profilePhoto');

const changeImage = document.getElementById('changeImage');

const uploadImage = document.getElementById('uploadImage');

profilePhotoInput &&
    profilePhotoInput.addEventListener('change', (e) => {
        profilePhoto.style.backgroundImage = `url(${URL.createObjectURL(
            profilePhotoInput.files[0]
        )})`;
        changeImage.style.display = 'none';
        uploadImage.style.display = 'inline-flex';
    });

const requestToJoinModalBtn = document.querySelectorAll(
    '.requestToJoinModalBtn'
);
const requestToJoinModalCloseBtn = document.querySelectorAll(
    '.requestToJoinModalCloseBtn'
);
const requestForm = document.querySelector('#group-join-request-form');

requestToJoinModalBtn.forEach((item) => {
    item.addEventListener('click', () => {
        requestForm.action = '/groups/' + item.dataset.id + '/request';
    });
});

requestToJoinModalCloseBtn.forEach((item) => {
    item.addEventListener('click', () => {
        requestForm.action = null;
    });
});

const conversationBox = document.querySelector('#conversationBox');
conversationBox && (conversationBox.scrollTop = conversationBox.scrollHeight);
conversationBox &&
    conversationBox.addEventListener('scroll', () => {
        if (
            conversationBox.scrollTop !==
            conversationBox.scrollHeight - conversationBox.offsetHeight
        )
            conversationBox.classList.add('shadow-inset-bottom');
        else conversationBox.classList.remove('shadow-inset-bottom');
    });

// Upload group cover

const groupCoverInput = document.getElementById('cover_image');

const coverPhoto = document.getElementById('coverPhoto');

const changeCover = document.getElementById('changeCover');

const uploadCover = document.getElementById('uploadCover');

const error = document.getElementById('error');

const errorMessage = document.getElementById('errorMessage');

let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png'];

groupCoverInput &&
    groupCoverInput.addEventListener('change', (e) => {
        const file = groupCoverInput.files[0];
        if (allowedExtension.indexOf(file.type) > -1) {
            coverPhoto.style.backgroundImage = `url(${URL.createObjectURL(
                file
            )})`;
            changeCover.style.display = 'none';
            uploadCover.style.display = 'inline-flex';
        } else if (Math.round(file.size / 1024 > 2048)) {
            error.classList.remove('hidden');
            errorMessage.innerText = 'The image size is too large.';
        } else {
            error.classList.remove('hidden');
            errorMessage.innerText = 'The selected file is not an image.';
            setTimeout(() => {
                error.style.display = 'none';
            }, 3000);
        }
    });

//  Leave group modal
const requestToLeaveModalBtn = document.querySelectorAll(
    '.requestToLeaveModalBtn'
);
const requestToLeaveModalCloseBtn = document.querySelectorAll(
    '.requestToLeaveModalCloseBtn'
);
const requestLeaveForm = document.querySelector('#group-leave-form');

requestToLeaveModalBtn.forEach((item) => {
    item.addEventListener('click', () => {
        requestLeaveForm.action = '/groups/' + item.dataset.id + '/leave';
    });
});

requestToLeaveModalCloseBtn.forEach((item) => {
    item.addEventListener('click', () => {
        requestLeaveForm.action = null;
    });
});

const links = document.querySelectorAll('.prose-default a');

links &&
    links.forEach((link) => {
        if (!link.href.startsWith(process.env.MIX_APP_URL)) {
            link.setAttribute('target', '_blank');
        }
    });

// Check if at least one checkbox is checked in a required checkbox group

const checkboxGroups = document.querySelectorAll('.checkbox-group.required');

function isAtLeastOneChecked(checkboxes) {
    return Array.from(checkboxes).some((e) => e.checked);
}

checkboxGroups.forEach((group) => {
    group.addEventListener('click', (event) => {
        if (event.target.type === 'checkbox') {
            const checkboxes = group.querySelectorAll('input[type=checkbox]');
            isAtLeastOneChecked(checkboxes)
                ? checkboxes.forEach((checkbox) => (checkbox.required = false))
                : checkboxes.forEach((checkbox) => (checkbox.required = true));
        }
    });
});

window.addEventListener('load', function () {
    let passwordVisibility = document.querySelector('#passwordVisibility');

    passwordVisibility &&
        passwordVisibility.addEventListener('click', () => {
            const passwordInput = document.querySelector('#password');
            if (passwordInput) {
                const isVisible = passwordInput.type === 'text';

                passwordInput.type = isVisible ? 'password' : 'text';
                passwordInput.parentNode.classList.toggle(
                    'visible',
                    !isVisible
                );
            }
        });
});
