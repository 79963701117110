<template>
    <label :for="id" class="block text-sm font-medium text-gray-700">{{
        labelText
    }}</label>
    <select
        :id="id"
        :value="modelValue"
        class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        @change="$emit('update:modelValue', $event.target.value)"
    >
        <option value="">...</option>
        <option v-for="group in groups" :key="group" :value="group.id">
            {{ group.name }}
        </option>
    </select>
    <p v-if="ErrorMessage" class="mt-2 text-sm text-red-500">
        {{ ErrorMessage }}
    </p>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import request from '../../environment/request';

const props = defineProps({
    id: String,
    websiteId: Number,
    labelText: String,
    modelValue: Number | String,
    ErrorMessage: String,
});

const groups = ref([]);

onMounted(() => {
    request(true)
        .get(`get-groups?website_id=${props.websiteId}`)
        .then((response) => {
            groups.value = response.data.data;
        });
});
</script>
