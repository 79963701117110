<script setup>
import { ref, reactive, onMounted } from 'vue'
import AppDialogModal from './../Shared/DialogModal'
import AppInput from './../Shared/Input'
import AppInputError from './../Shared/InputError'
import AppTextEditor from '@/components/shared/TextEditor'
import MultiSelect from '@vueform/multiselect'
import { debounce } from 'lodash'
import LoadingIcon from '@/components/icons/LoadingIcon'


let conversationForm = reactive({
  subject: null,
  user_id: null,
  message: null
})

const props = defineProps({
  user: Object
})

onMounted(() => {
  if(props.user) {
    conversationForm.user_id = props.user.id
  }
})

const isProcessing = ref(false)
const errors = ref('')
const users = ref([])
const fechingUsers = ref(false)

const showStartConversationModel = ref(false)

const closeModal = () => {
    showStartConversationModel.value = false
};

function startConversation() {
  isProcessing.value = true

  let formData = new FormData()
  formData.append("subject", conversationForm.subject)
  formData.append("user_id", conversationForm.user_id)
  formData.append("message", conversationForm.message)
  
  axios.post(`/conversations/${conversationForm.user_id}/start`, formData)
    .then((response) => {
      isProcessing.value = false
      resetForm()
      closeModal()
      window.location.href = `/conversations/${response.data.data.id}`
    })
    .catch(err => {
      isProcessing.value = false
      if (err.response && err.response.status == 422) {
        errors.value = err.response.data.errors
      }
    })
}

function resetForm() {
    conversationForm.subject = null
    conversationForm.user_id = null
    conversationForm.message = null
}

const fetchUsers = debounce((event) => {
  if (event.target.value === '') {
    users.value = []
    return
  }
  fechingUsers.value = true
  axios.
    get(`/conversations/users/all?name=${event.target.value}`).
    then((response) => {
      users.value = response.data.data
    })
    .finally(() => fechingUsers.value = false)
}, 500)

</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<template>
    <button @click="showStartConversationModel = true" class="button primary !bg-blue hover:!bg-blue/80 space-x-2">
        <i class="fa-regular fa-comment-dots"></i>
        <span class="hidden sm:inline">send a message</span>
    </button>

    <AppDialogModal :show="showStartConversationModel" @close="closeModal">
        <template #title>
            <div class="flex items-center justify-between">
                <div class="text-lg sm:text-2xl font-tienne font-bold">Create a message</div>
                <button @click="showStartConversationModel = false" class="min-w-0 !p-0" type="button">
                    <i class="fa-solid fa-times text-normal"></i>
                </button>
            </div>
        </template>

        <template #content>
            <div class="space-y-4 sm:space-y-6">
                <fieldset>
                    <AppInput label="Subject" v-model="conversationForm.subject" />
                </fieldset>
                <fieldset class="relative" v-if="!$props.user">
                    <MultiSelect
                        id="user_id"
                        ref="multiSelect"
                        :options="users"
                        placeholder="Ping"
                        valueProp="id"
                        :required="true"
                        trackBy="name"
                        label="name"
                        :searchable="true"
                        @keyup="fetchUsers"
                        v-model="conversationForm.user_id"
                        />
                    <AppInputError v-if="errors.user_id" :message="errors.user_id[0]" class="mt-2" />
                    <div class="w-7 h-7 absolute right-[6px] top-3" v-if="fechingUsers">
                      <LoadingIcon />
                    </div>
                </fieldset>

                <fieldset>
                    <AppTextEditor 
                        v-model="conversationForm.message"
                        :toolbar="[
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            [{ 'indent': '-1' }, { 'indent': '+1' }],
                            [{ 'direction': 'rtl' }],
                            [{ 'color': [] }, { 'background': [] }],
                            [{ 'align': [] }],
                            ['clean'],
                        ]"
                    />
                </fieldset>
            </div>
        </template>

        <template #footer>
            <button @click="startConversation" class="button primary" :disabled="isProcessing">send a message</button>
        </template>
    </AppDialogModal>
</template>