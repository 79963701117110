<template>
  <section class="mb-16 sm:mb-24 pt-6 sm:pt-8 relative z-0 overflow-x-hidden">
    <div v-if="item" class="container">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-y-6 lg:gap-x-6">
        <div class="col-span-2 pr-0 lg:pr-8 space-y-4 lg:space-y-6">
          <div class="relative bg-gray shadow">
            <div class="space-y-4 lg:space-y-6 p-4 lg:p-6">
              <div class="flex justify-between items-center">
                <div class="flex flex-auto gap-x-4">
                  <a :href="`/user/${item.user.id}/profile`">
                    <div
                      :style="`background-image: url('` + item.user.thumbnail + `')`"
                      class="h-12 w-12 flex-none rounded-full bg-no-repeat bg-cover bg-center bg-blue-light"
                    ></div>
                  </a>
                  <div class="flex flex-col justify-center">
                    <div class="flex flex-col justify-center">
                      <a :href="`/user/${item.user.id}/profile`">
                        <div
                          :title="item.user.name + ' ' + item.user.last_name"
                          class="text-base sm:text-normal !leading-[1.3] text-blue-dark font-medium line-clamp-1"
                          v-text="item.user.name + ' ' + item.user.last_name"
                        ></div>
                      </a>
                      <p class="text-gray-darkest text-sm" v-text="item.time"></p>
                    </div>
                  </div>
                  <div class="items-center gap-x-2 ml-auto flex-none hidden md:flex">
                    <label
                      class="py-1 px-2 rounded-full bg-blue/50 text-white capitalize text-sm"
                      v-text="item.type"
                    ></label>
                    <label
                      v-if="item.from_united_way"
                      class="py-1 px-2 rounded-full bg-blue/50 text-white capitalize text-sm"
                      >FROM UNITED WAY
                    </label>
                  </div>
                </div>
                <div class="flex-none ml-2">
                  <div v-if="item.can.manage" class="relative inline-block text-left">
                    <div>
                      <button
                        id="menu-button"
                        aria-expanded="true"
                        aria-haspopup="true"
                        class="min-w-0 !p-2 hover:text-blue"
                        type="button"
                        @click="showPostDropDown = 1"
                      >
                        <i class="fa-solid fa-ellipsis-vertical cursor-pointer"></i>
                      </button>
                    </div>
                    <div
                      v-if="showPostDropDown === 1"
                      aria-labelledby="menu-button"
                      aria-orientation="vertical"
                      class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-xl bg-gray ring-1 ring-gray-dark ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10"
                      role="menu"
                      tabindex="-1"
                      x-cloak
                    >
                      <div class="py-1" role="none">
                        <a
                          id="menu-item-0"
                          :href="`${baseUrl}/group/${groupSlug}/${item.type}/${item.postable_id}/edit`"
                          class="group flex items-center px-4 py-2 text-md hover:bg-blue-light"
                          role="menuitem"
                          tabindex="-1"
                        >
                          <i class="fa-solid fa-edit mr-4"></i>
                          Edit
                        </a>
                        <a
                          id="menu-item-0"
                          class="group flex items-center px-4 py-2 text-md hover:bg-blue-light"
                          href="javascript:void()"
                          role="menuitem"
                          tabindex="-1"
                          @click="deletePost(item.id)"
                        >
                          <i class="fa-solid fa-trash mr-4"></i>
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <a v-if="item.type === 'event'" :href="`${baseUrl}/training-events/${item.slug}/event`">
                  <h4 class="text-2xl font-bold line-clamp-2 text-blue-dark" v-text="item.title"></h4>
                </a>
                <a v-else :href="`${baseUrl}/group/${groupSlug}/${item.type}/${item.slug}`">
                  <h4 class="text-2xl font-bold line-clamp-2 text-blue-dark" v-text="item.title"></h4>
                </a>
              </div>
              <div class="prose-default" v-html="item.summary"></div>
              <div
                :class="{
                  'h-[18rem]  lg:hidden': item.cover_location === 0,
                }"
                class="overflow-hidden relative justify-center items-center mb-4"
              >
                <img :src="item.image" alt="group post image" class="w-full object-center object-contain rounded" />
              </div>
              <div class="prose-default" v-html="item.description"></div>

              <!-- Embed Videos -->
              <div v-if="item.embeds.length > 0">
                <hr class="my-2 border-gray-darkest/50" />
                <div class="my-4">
                  <div v-for="embed in item.embeds" :key="embed.id">
                    <h4 class="my-2 font-semibold" v-text="embed.name"></h4>

                    <div class="embed-container">
                      <iframe
                        :src="embed.path"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                        frameborder="0"
                        height="315"
                        title="YouTube video player"
                        width="560"
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>

              <!-- File Attachments -->
              <div v-if="item.files.length > 0" class="my-4">
                <h4 class="mt-4 sm:mt-6 font-semibold">File Attachments</h4>
                <hr class="my-2 border-gray-darkest/50" />
                <table class="min-w-full table-fixed">
                  <tr v-for="(file, index) in item.files" :key="file.id">
                    <td class="p-2">
                      {{ index + 1 }}
                    </td>
                    <td class="p-2">
                      <a
                        class="text-blue-dark hover:text-blue-darkest"
                        href="#"
                        target="_blank"
                        v-text="file.name"
                        @click.prevent="previewLink(file.id, file.file_type)"
                      ></a>
                    </td>
                    <td class="p-2 hidden sm:table-cell text-gray-600" v-text="file.size"></td>
                    <td class="p-2 text-right hover:text-blue-dark">
                      <span class="cursor-pointer hover:text-blue-400" @click="downloadFile(file.id, file.file_type)">
                        <i
                          class="fa-solid fa-cloud-arrow-down text-xl text-blue-dark hover:text-blue-darkest hover:scale-110"
                        ></i>
                      </span>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="flex items-center gap-x-2 md:hidden">
                <label
                  v-if="item.from_united_way"
                  class="py-1 px-2 rounded-full bg-blue/50 text-white capitalize text-sm"
                >
                  FROM UNITED WAY
                </label>
                <label class="py-1 px-2 rounded-full bg-blue/50 text-white capitalize text-sm">
                  {{ item.type }}
                </label>
              </div>
              <div v-if="isGroupAdmin" class="justify-end gap-x-4 flex lg:hidden sm:max-w-lg ml-auto">
                <button
                  :disabled="loading || !item.published"
                  class="button primary w-full"
                  @click="togglePublishedAt(item.id)"
                >
                  <span v-if="loading || !item.published">unpublished</span>
                  <span v-else>unpublish</span>
                </button>
                <button
                  :disabled="loading || item.published"
                  class="button primary w-full"
                  @click="togglePublishedAt(item.id)"
                >
                  <span v-if="loading || item.published">published</span>
                  <span v-else>publish</span>
                </button>
              </div>

              <div>
                <div
                  v-if="item.published"
                  class="border-t border-t-gray-dark border-opacity-50 mt-4 px-4 pt-4 flex space-x-12"
                >
                  <div :class="item.liked ? 'text-blue' : ''" class="flex items-center space-x-2">
                    <button
                      class="!p-0 min-w-0"
                      @click="
                        likePost({
                          type: item.type,
                          id: item.id,
                        })
                      "
                    >
                      <thumb-up-icon class="w-6" />
                    </button>
                    <span class="mt-1" v-text="item.likes_count"></span>
                  </div>
                  <div class="flex items-center space-x-2">
                    <form action="javascript:">
                      <button class="!p-0 min-w-0" type="submit" @click="getComments(item.type, item.id)">
                        <chat-icon class="w-6 mt-1" />
                      </button>
                    </form>
                    <span
                      class="cursor-pointer"
                      @click="getComments(item.type, item.id)"
                      v-text="item.comments_count"
                    ></span>
                    <small
                      v-if="item.id == postId"
                      class="text-lg text-blue-dark flex items-center space-x-1 !text-blue"
                      >Please wait...</small
                    >
                  </div>
                </div>
                <div v-if="comments && item.published" class="flex mb-4 pt-4 space-x-4">
                  <div class="flex-none">
                    <div
                      :style="'background-image: url(' + authUserThumbnail + ')'"
                      class="h-12 w-12 rounded-full bg-no-repeat bg-cover bg-center bg-blue-light"
                    ></div>
                  </div>
                  <div class="relative flex-auto">
                    <comment-input
                      v-if="groupMembers"
                      v-model="commenMessage"
                      :disabled="commentLoading"
                      :users="groupMembers"
                      placeholder="Leave your comment"
                      @keyboard-send="sendMessage(item.type, item.id, 0)"
                    ></comment-input>
                    <div class="absolute right-0 bottom-2 flex items-center pr-4 lg:pr-6 space-x-4">
                      <button class="!p-0 min-w-0" @click="sendMessage(item.type, item.id, 0)">
                        <i class="fa-solid fa-chevron-right text-normal text-blue"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="item.published">
                  <ul class="divide-y divide-gray-dark">
                    <li v-for="(comment, x) in comments" :key="x" class="py-4">
                      <div class="flex justify-between mb-2">
                        <div class="flex space-x-4">
                          <div
                            :style="'background-image: url(' + comment.user.thumbnail + ')'"
                            class="h-12 w-12 flex-none rounded-full bg-no-repeat bg-cover bg-center bg-blue-light"
                          ></div>
                          <div class="flex flex-col justify-center">
                            <a :href="`/user/${comment.user.id}/profile`">
                              <div
                                :title="comment.user.name + ' ' + comment.user.last_name"
                                class="text-base sm:text-normal !leading-[1.3] text-blue-dark font-medium line-clamp-1"
                                v-text="comment.user.name + ' ' + comment.user.last_name"
                              ></div>
                            </a>
                            <p class="text-gray-darkest" v-text="comment.time"></p>
                          </div>
                        </div>
                        <div v-if="comment.user.id == userId || userRole == 1" class="relative inline-block text-left">
                          <div>
                            <button
                              id="menu-button"
                              aria-expanded="true"
                              aria-haspopup="true"
                              class="min-w-0 !p-0"
                              type="button"
                              @click="show == comment.id ? (show = '') : (show = comment.id)"
                            >
                              <i class="fa-solid fa-ellipsis cursor-pointer"></i>
                            </button>
                          </div>
                          <div
                            v-if="show == comment.id"
                            aria-labelledby="menu-button"
                            aria-orientation="vertical"
                            class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-xl bg-gray ring-1 ring-gray-dark ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10"
                            role="menu"
                            tabindex="-1"
                          >
                            <div class="py-1" role="none">
                              <a
                                v-if="comment.user.id == userId"
                                id="menu-item-0"
                                class="group flex items-center px-4 py-2 text-md hover:bg-blue-dark hover:text-white"
                                href="javascript:"
                                role="menuitem"
                                tabindex="-1"
                                @click="editComment(comment.body, comment.id, x)"
                              >
                                <i class="fa-regular fa-pen-to-square mr-4"></i>
                                Edit
                              </a>
                              <a
                                id="menu-item-6"
                                class="group flex items-center px-4 py-2 text-md hover:bg-blue-dark hover:text-white"
                                href="javascript:"
                                role="menuitem"
                                tabindex="-1"
                                @click="deleteComment(comment.id, x)"
                              >
                                <i class="fa-regular fa-trash-can mr-4"></i>
                                Delete
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <!-- Comment Body -->
                        <p
                          v-if="!(commentId == comment.id)"
                          class="lg:text-normal comment-box"
                          v-html="comment.body"
                        ></p>
                        <div v-if="commentId == comment.id" class="relative flex-auto">
                          <comment-input
                            v-if="groupMembers"
                            v-model="editCommenMessage"
                            :disabled="commentLoading"
                            :users="groupMembers"
                            placeholder="Edit your comment"
                            @keyboard-send="sendMessage(item.type, item.id, comment.id, (edit = true))"
                          >
                          </comment-input>
                          <div class="absolute right-0 bottom-2 flex items-center pr-4 lg:pr-6 space-x-4">
                            <button
                              class="!p-0 min-w-0"
                              @click="sendMessage(item.type, item.id, comment.id, (edit = true))"
                            >
                              <i class="fa-solid fa-chevron-right text-normal text-blue"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="pt-4 flex items-center space-x-6">
                        <div class="flex items-center space-x-4 lg:space-x-6">
                          <div class="relative group">
                            <button class="!p-0 min-w-0">
                              <i class="fa-regular fa-face-smile text-normal group-hover:text-blue"></i>
                            </button>
                            <ul
                              class="absolute space-x-4 bg-gray z-20 -top-8 px-4 py-1 rounded-lg shadow-lg tranis transition-all duration-100 flex oveflow-hidden delay-300 opacity-0 invisible group-hover:visible group-hover:opacity-100 group-hover:delay-100"
                            >
                              <li v-for="emoji in emojies" :key="emoji">
                                <button class="!p-0 min-w-0">
                                  <span
                                    :title="emoji.name"
                                    class="text-normal transition-transform duration-100 ease-in-out hover:scale-125"
                                    @click="likeComment(comment.id, emoji.id)"
                                    v-text="emoji.icon"
                                  ></span>
                                </button>
                              </li>
                            </ul>
                          </div>
                          <ul class="flex space-x-4">
                            <template v-for="like in comment.comment_likes" :key="like">
                              <li v-if="like.count > 0">
                                <button
                                  class="!py-1 !px-2 min-w-0 border border-gray-dark space-x-2 font-normal hover:border-blue"
                                  @click="likeComment(comment.id, like.emoji_type_id)"
                                >
                                  <span :title="like.name" class="text-normal" v-text="like.icon"></span>
                                  <span v-text="like.count"></span>
                                </button>
                              </li>
                            </template>
                          </ul>
                        </div>
                        <div class="border-l border-l-gray-dark pl-4">
                          <div class="lg:text-normal">
                            <form action="javascript:">
                              <span class="mx-2 cursor-pointer" @click="getReplyComments('comment', comment.id)"></span>

                              <span class="cursor-pointer" @click="getReplyComments('comment', comment.id)">{{
                                comment.replies_count > 1
                                  ? `${comment.replies_count}
                                                                replies`
                                  : `${comment.replies_count}
                                                                reply`
                              }}</span>
                            </form>
                          </div>
                        </div>
                        <small
                          v-if="comment.id == postCommentId"
                          class="text-lg text-blue-dark flex items-center space-x-1 !text-blue"
                          >Please wait...</small
                        >
                      </div>
                      <div v-if="comment['replies']" class="flex mb-4 pt-4 space-x-4 pl-6 lg:pl-10">
                        <div class="flex-none">
                          <div
                            :style="'background-image: url(' + authUserThumbnail + ')'"
                            class="h-12 w-12 rounded-full bg-no-repeat bg-cover bg-center bg-blue-light"
                          ></div>
                        </div>
                        <div class="relative flex-auto">
                          <comment-input
                            v-if="groupMembers"
                            v-model="replyMessage"
                            :disabled="replyLoading"
                            :users="groupMembers"
                            placeholder="Leave your reply"
                            @keyboard-send="sendReplyMessage(item.type, item.id, comment.id, 0)"
                          ></comment-input>
                          <div class="absolute right-0 bottom-2 flex items-center pr-4 lg:pr-6 space-x-4">
                            <button class="!p-0 min-w-0" @click="sendReplyMessage(item.type, item.id, comment.id, 0)">
                              <i class="fa-solid fa-chevron-right text-normal text-blue"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <ul v-if="comment['replies']" class="divide-y divide-gray-dark pl-6 lg:pl-10">
                        <li v-for="reply in comment['replies']" class="py-4">
                          <div class="flex justify-between mb-2">
                            <div class="flex space-x-4">
                              <div
                                :style="'background-image: url(' + reply.user.thumbnail + ')'"
                                class="h-12 w-12 flex-none rounded-full bg-no-repeat bg-cover bg-center bg-blue-light"
                              ></div>
                              <div class="flex flex-col justify-center">
                                <a :href="`/user/${reply.user.id}/profile`">
                                  <div
                                    :title="reply.user.name + ' ' + reply.user.last_name"
                                    class="text-base sm:text-normal !leading-[1.3] text-blue-dark font-medium line-clamp-1"
                                    v-text="reply.user.name + ' ' + reply.user.last_name"
                                  ></div>
                                </a>
                                <p class="text-gray-darkest" v-text="reply.time"></p>
                              </div>
                            </div>
                            <div
                              v-if="reply.user.id == userId || userRole == 1"
                              class="relative inline-block text-left"
                            >
                              <div>
                                <button
                                  id="menu-button"
                                  aria-expanded="true"
                                  aria-haspopup="true"
                                  class="min-w-0 !p-0"
                                  type="button"
                                  @click="show == reply.id ? (show = '') : (show = reply.id)"
                                >
                                  <i class="fa-solid fa-ellipsis cursor-pointer"></i>
                                </button>
                              </div>
                              <div
                                v-if="show == reply.id"
                                aria-labelledby="menu-button"
                                aria-orientation="vertical"
                                class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-xl bg-gray ring-1 ring-gray-dark ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10"
                                role="menu"
                                tabindex="-1"
                              >
                                <div class="py-1" role="none">
                                  <a
                                    v-if="reply.user.id == userId"
                                    id="menu-item-0"
                                    class="group flex items-center px-4 py-2 text-md hover:bg-blue-dark hover:text-white"
                                    href="javascript:"
                                    role="menuitem"
                                    tabindex="-1"
                                    @click="editReply(reply.body, reply.id)"
                                  >
                                    <i class="fa-regular fa-pen-to-square mr-4"></i>
                                    Edit
                                  </a>
                                  <a
                                    id="menu-item-6"
                                    class="group flex items-center px-4 py-2 text-md hover:bg-blue-dark hover:text-white"
                                    href="javascript:"
                                    role="menuitem"
                                    tabindex="-1"
                                    @click="deleteReplyComment(comment.id, reply.id)"
                                  >
                                    <i class="fa-regular fa-trash-can mr-4"></i>
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p v-if="!(replyId == reply.id)" class="lg:text-normal" v-html="reply.body"></p>
                            <div v-if="replyId == reply.id" class="relative flex-auto">
                              <comment-input
                                v-if="groupMembers"
                                v-model="editReplyMessage"
                                :disabled="replyLoading"
                                :users="groupMembers"
                                placeholder="Edit your reply"
                                @keyboard-send="
                                  sendReplyMessage(item.type, item.id, comment.id, reply.id, (edit = true))
                                "
                              >
                              </comment-input>
                              <div class="absolute right-0 bottom-2 flex items-center pr-4 lg:pr-6 space-x-4">
                                <button
                                  class="!p-0 min-w-0"
                                  @click="sendReplyMessage(item.type, item.id, comment.id, reply.id, (edit = true))"
                                >
                                  <i class="fa-solid fa-chevron-right text-normal text-blue"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="pt-4 flex items-center space-x-6">
                            <div class="flex items-center space-x-4 lg:space-x-6">
                              <div class="relative group">
                                <button class="!p-0 min-w-0">
                                  <i class="fa-regular fa-face-smile text-normal group-hover:text-blue"></i>
                                </button>
                                <ul
                                  class="absolute space-x-4 bg-gray z-20 -top-8 px-4 py-1 rounded-lg shadow-lg tranis transition-all duration-100 flex oveflow-hidden delay-300 opacity-0 invisible group-hover:visible group-hover:opacity-100 group-hover:delay-100"
                                >
                                  <li v-for="emoji in emojies" :key="emoji">
                                    <button class="!p-0 min-w-0">
                                      <span
                                        :title="emoji.name"
                                        class="text-normal transition-transform duration-100 ease-in-out hover:scale-125"
                                        @click="likeReply(comment.id, reply.id, emoji.id)"
                                        v-text="emoji.icon"
                                      >
                                      </span>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                              <ul class="flex space-x-4">
                                <template v-for="like in reply.comment_likes" :key="like">
                                  <li v-if="like.count > 0">
                                    <button
                                      class="!py-1 !px-2 min-w-0 border border-gray-dark space-x-2 font-normal hover:border-blue"
                                      @click="likeReply(comment.id, reply.id, like.emoji_type_id)"
                                    >
                                      <span :title="like.name" class="text-normal" v-text="like.icon"></span>
                                      <span v-text="like.count"></span>
                                    </button>
                                  </li>
                                </template>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div
                    v-if="comments && item.comments_count > 0"
                    class="flex justify-between border-t border-t-gray-dark pt-4 lg:text-normal"
                  >
                    <span>
                      <a
                        v-if="comments.length >= 5 && item.comments_count > 5 && comments.length < item.comments_count"
                        class="font-medium"
                        href="javascript:"
                        @click="getAllComments(item.type, item.id)"
                      >
                        Load more comments
                      </a>
                    </span>
                    <span
                      v-if="item.comments_count < 5"
                      v-text="item.comments_count + ' of ' + item.comments_count"
                    ></span>
                    <span v-else v-text="comments.length + ' of ' + item.comments_count"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="space-y-4 lg:space-y-6">
          <div v-if="isGroupAdmin" class="justify-end gap-x-4 hidden lg:flex">
            <button
              :disabled="loading || !item.published"
              class="button primary w-full"
              @click="togglePublishedAt(item.id)"
            >
              <span v-if="loading || !item.published">unpublished</span>
              <span v-else>unpublish</span>
            </button>
            <button
              :disabled="loading || item.published"
              class="button primary w-full"
              @click="togglePublishedAt(item.id)"
            >
              <span v-if="loading || item.published">published</span>
              <span v-else>publish</span>
            </button>
          </div>
          <div
            v-if="item.cover_location == 0"
            class="bg-gray h-[16rem] xl:h-[18rem] overflow-hidden relative hidden lg:flex justify-center items-center mb-4"
          >
            <img
              :src="item.image"
              alt="group post image"
              class="absolute inset-0 h-full w-full object-center object-contain rounded"
            />
          </div>

          <div v-if="item.type === 'event'" class="bg-blue-light p-6 sm:p-8 rounded-tr lg:rounded-br-8xl sticky">
            <div v-if="item.register" class="mb-4">
              <div>
                <a class="button primary w-full justify-between" href="{{ item.register }}" target="_blank">
                  {{ __('register') }}
                  <i class="fa-solid fa-arrow-right ml-4 text-blue"></i>
                </a>
              </div>
            </div>
            <ul class="space-y-8">
              <li class="flex space-x-4">
                <div>
                  <i class="fa-regular fa-user text-xl text-blue-dark"></i>
                </div>
                <div>
                  <h5 class="text-normal font-semibold text-blue-dark">Organizer</h5>
                  <p class="text-normal text-gray-darkest">
                    {{ item.event.organizer }}
                  </p>
                </div>
              </li>
              <li class="flex space-x-4">
                <div>
                  <i class="fa-solid fa-location-dot text-xl text-blue-dark"></i>
                </div>
                <div>
                  <h5 class="text-normal font-semibold text-blue-dark">Location</h5>
                  <p class="text-normal text-gray-darkest">
                    {{ item.event.location }}
                  </p>
                </div>
              </li>
              <li class="flex space-x-4">
                <div>
                  <i class="fa-regular fa-calendar text-xl text-blue-dark"></i>
                </div>
                <div>
                  <h5 v-if="!item.event.ongoing" class="text-normal font-semibold text-blue-dark">
                    Date

                    <span>{{ item.event.start_date }}</span>
                  </h5>

                  <p v-if="item.event.ongoing" class="text-normal text-gray-darkest">Ongoing</p>
                  <div v-else>
                    <p v-if="isFuture(item.event.end_date)" class="text-normal text-gray-darkest">
                      {{ item.event.end_date }}
                    </p>
                    <p v-else class="text-normal text-gray-darkest">This event has passed.</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- Beginning of Featured Quick Links  -->
          <featured-links-component :groupId="groupId" :groupSlug="groupSlug" />

          <div v-if="item.can_manage_folder && item.type === 'resource'" class="bg-gray p-8 sm:p-12 rounded">
            <h4 class="text-blue-dark font-bold mb-4 lg:mb-6">Manage Folder</h4>
            <add-to-folder :current-folder="item.folder" :group-id="groupId" :resource-id="item.postable_id" />
          </div>
          <!-- End of Featured Quick Links -->
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { ChatIcon, ThumbUpIcon } from '@heroicons/vue/outline'
import FeaturedLinksComponent from '../shared/FeaturedLinksComponent'
import AddToFolder from '../../resource/AddToFolder.vue'

const props = defineProps({
  itemId: {
    type: String,
  },
  groupSlug: {
    type: String,
  },
  isGroupAdmin: {
    type: Boolean,
  },
  authUserThumbnail: {
    type: String,
  },
  groupId: {
    type: String,
  },
  userId: {
    type: String,
  },
  notDownloadableFileTypes: {
    type: Object,
  },
})

const show = ref('')
const replies = ref([])
const emojies = ref('')
const replyId = ref('')
const comments = ref([])
const commentId = ref('')
const groupMembers = ref(null)
const replyMessage = ref([])
const editReplyMessage = ref([])
const commenMessage = ref('')
const editCommenMessage = ref([])
const showPostDropDown = ref('')
const loading = ref(false)
const commentLoading = ref(false)
const commentLoadingId = ref('')
const replyLoading = ref(false)
const replyLoadingId = ref('')
const postId = ref('')
const postCommentId = ref('')
const userRole = ref('')
const item = ref('')
const baseUrl = window.location.origin

onMounted(() => {
  axios
    .get(`/api/group-authenticated-user-role/${props.groupId}`)
    .then((response) => (userRole.value = response.data.user_role))
  getEmojies()
  getGroupMembers()
  getDetails()
})

function getEmojies() {
  // Get Emojies
  axios
    .get(`/api/get-emojies`)
    .then((response) => (emojies.value = response.data.data))
    .catch((error) => console.error(error))
}

function getDetails() {
  axios
    .get(`/api/group/${props.groupSlug}/${props.itemId}`)
    .then((response) => {
      item.value = response.data.data
      getAllComments(item.value.type, item.value.id)
      getEmojies()
    })
    .catch((error) => console.error(error))
}

const getGroupMembers = async () => {
  try {
    const response = await axios.get(`/api/groups/${props.groupId}/members?per_page=999&with_admins=1&not_me=1`)
    groupMembers.value = response.data.data
  } catch (error) {
    console.error(error)
  }
}

function sendMessage(type, id, commentId, edit = false) {
  if (commenMessage.value || editCommenMessage.value) {
    commentLoading.value = true
    commentLoadingId.value = id
    let body = commentId && edit ? editCommenMessage.value : commenMessage.value

    axios
      .post(`/api/comment/${type}/${id}`, {
        body: body,
        comment_id: edit ? commentId : null, // commentId is used for editing comments
      })
      .then((response) => {
        if (commenMessage.value && !edit) {
          replies.value = []
          comments.value.unshift(response.data.data)
          increaseCommentCount()
        } else {
          let commentIndex = comments.value.findIndex((c) => c.id === commentId)

          comments.value[commentIndex] = response.data.data
        }
        commenMessage.value = ''
        editCommenMessage.value = ''
        resetCommentId()
      })
      .catch((error) => console.error(error))
      .finally(() => {
        commentLoading.value = false
        commentLoadingId.value = ''
      })
  }
}

function resetCommentId() {
  commentId.value = ''
}

function resetReplyId() {
  replyId.value = ''
}

function editComment(message, id) {
  show.value = ''
  commentId.value = id
  editCommenMessage.value = message
}

function editReply(message, id) {
  show.value = ''
  replyId.value = id
  editReplyMessage.value = message
}

function getAllComments(type, id) {
  axios.get(`/api/comment/${type}/${id}?display=all`).then((response) => {
    comments.value = response.data.data
  })
}

function getComments(type, id) {
  postId.value = id
  axios
    .get(`/api/comment/${type}/${id}`)
    .then((response) => {
      replies.value = []
      comments.value = response.data.data
    })
    .catch()
    .finally(() => {
      postId.value = ''
    })
}

function likeComment(id, emojiTypeId) {
  axios.post(`/api/like-comment/${id}/${emojiTypeId}`, {}).then((response) => {
    let comment = comments.value.find((c) => c.id === id)
    comment.comment_likes = response.data.data
  })
}

function getReplyComments(type, id) {
  postCommentId.value = id
  axios
    .get(`/api/replies/${type}/${id}`)
    .then((response) => {
      let comment = comments.value.find((c) => c.id === id)
      comment['replies'] = response.data.data
    })
    .catch()
    .finally(() => {
      postCommentId.value = ''
    })
}

function sendReplyMessage(type, id, originalCommentId, replyId, edit = false) {
  if (replyMessage.value || editReplyMessage.value) {
    replyLoading.value = true
    replyLoadingId.value = id
    let body = replyId && edit ? editReplyMessage.value : replyMessage.value
    axios
      .post(`/api/replies/${type}/${id}/${originalCommentId}`, {
        body: body,
        reply_id: edit ? replyId : null,
      })
      .then((response) => {
        if (replyMessage.value && !edit) {
          let comment = comments.value.find((c) => c.id === originalCommentId)
          comment['replies'].unshift(response.data.data)
          comment.replies_count += 1
        } else {
          let comment = comments.value.find((c) => c.id === originalCommentId)
          let replyIndex = comment['replies'].findIndex((r) => r.id === replyId)
          comment['replies'][replyIndex] = response.data.data
        }
        replyMessage.value = ''
        editReplyMessage.value = ''
        resetReplyId()
      })
      .catch()
      .finally(() => {
        replyLoading.value = false
        replyLoadingId.value = ''
      })
  }
}

function likeReply(commentId, replyId, emojiTypeId) {
  axios.post(`/api/like-reply/${replyId}/${emojiTypeId}`, {}).then((response) => {
    let comment = comments.value.find((c) => c.id === commentId)
    let replyIndex = comment['replies'].findIndex((r) => r.id === replyId)
    comment['replies'][replyIndex].comment_likes = response.data.data
  })
}

function deleteComment(commentId) {
  axios.delete(`/api/comment/${commentId}`).then((res) => {
    show.value = ''
    let commentIndex = comments.value.findIndex((c) => c.id === commentId)
    comments.value.splice(commentIndex, 1)
  })
}

function deleteReplyComment(commentId, replyId) {
  axios.delete(`/api/comment/${replyId}`).then((res) => {
    show.value = ''
    let comment = comments.value.find((c) => c.id === commentId)
    let replyIndex = comment['replies'].findIndex((r) => r.id === replyId)

    if (replyIndex !== -1) {
      comment['replies'].splice(replyIndex, 1)
    }

    comment.replies_count -= 1
  })
}

function togglePublishedAt(id) {
  loading.value = true
  axios
    .post(`/api/groups/toggle-published-at/${id}`, {})
    .then((response) => {
      if (response.data.data) {
        updateGroupItems({
          data: response.data.data,
        })
      } else {
        console.log('Sorry! invalid data')
      }
    })
    .catch()
    .finally(() => {
      loading.value = false
    })
}

function updateGroupItems(args) {
  item.value = args.data
}

function likePost(args) {
  axios.post(`/api/like/${args.type}/${args.id}`, {}).then((response) => {
    item.value.likes_count = response.data.likes_count
    item.value.liked = response.data.liked
  })
}

function increaseCommentCount() {
  item.value.comments_count += 1
}

function decreaseCommentCount() {
  item.value.comments_count -= 1
}

function removeGroupItem() {
  item.value.splice(1)
}

function deletePost(postId) {
  if (confirm('Are you sure to delete this post?')) {
    axios.delete(`/api/delete-post/${postId}`).then((res) => {
      removeGroupItem()
    })
  }
}

function isFuture(dateString) {
  const currentDate = new Date()
  const startDate = new Date(dateString)
  return startDate > currentDate
}

function isSameDay(dateString1, dateString2) {
  const date1 = new Date(dateString1)
  const date2 = new Date(dateString2)
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

function previewLink(id, fileType) {
  // Validate parameters
  if (!id || !fileType) {
    console.error('Invalid parameters: id and fileType are required')
    return
  }

  //We can't preview these file types
  const downloadFileTypes = JSON.parse(props.notDownloadableFileTypes)
  //We can't preview these file types, so prompt download
  if (downloadFileTypes.includes(fileType)) {
    return downloadFile(id, fileType)
  }

  // Supported file types that should not be embedded or linked
  const unsupportedFileTypes = ['embed', 'link']

  // Check if fileType is unsupported
  if (!unsupportedFileTypes.includes(fileType)) {
    // Construct the URL
    const url = `${baseUrl}/files/preview/${id}`

    // Open the URL in a new tab
    window.open(url, '_blank')
  } else {
    console.warn('File type is not supported for preview:', fileType)
  }
}

function downloadFile(id, fileType) {
  // Validate parameters
  if (!id || !fileType) {
    console.error('Invalid parameters: id and fileType are required')
    return
  }

  // Supported file types that should not be embedded or linked
  const unsupportedFileTypes = ['embed', 'link']

  // Check if fileType is unsupported
  if (!unsupportedFileTypes.includes(fileType)) {
    // Construct the URL
    const url = `${baseUrl}/files/${id}`

    // Open the URL in a new tab
    window.open(url, '_blank')
  } else {
    console.warn('File type is not supported for download:', fileType)
  }
}
</script>

<style>
.comment-box span {
  padding: 0 3px;
  background: #35afc857;
  border-radius: 3px;
}
</style>
