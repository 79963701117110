<script setup>
import { ref, onMounted } from 'vue'

defineProps({
  authUser: Object
})

onMounted(() => {
  fetchConversations()
})

const conversations = ref([])

function fetchConversations() {
  axios
    .get(`/conversations`)
    .then(response => conversations.value = response.data)
}

function removeParticipant(id, index) {
  if(confirm("Are you sure to delete this conversation?")){
    axios.delete(`/conversations/${id}/remove-participant`)
    .then(() => fetchConversations())
  }
}

function formateDate(date) {
    let dt = new Date(date)
    let month = dt.toLocaleString('default', { month: 'long' })
    let day = dt.getDate();
    let year = dt.getFullYear();
    let hours = dt.getHours() ;
    hours = (hours % 12) || 12;
    hours = ('0' + hours).slice(-2)
    let AmOrPm = hours >= 12 ? 'PM' : 'AM';
    let minutes = ('0' + dt.getMinutes()).slice(-2) ;
    return `${month} ${day}, ${year} - ${hours}:${minutes}:${AmOrPm}`;
}

function showConversation(id) {
  window.location.href = `/conversations/${id}`
}
</script>

<template>
  <nav class="mt-6 sm:mt-8 border-b border-gray-dark/50">
    <ul class="flex items-center space-x-4 sm:space-x-6">
      <li class="py-4 cursor-pointer text-base sm:text-normal border-b-2 border-blue text-blue">
        <span>Direct Conversations</span>
        <span v-if="conversations.data" class="ml-2 inline-block bg-gray px-3 rounded-full">
          {{ conversations.data.length }}
        </span>
      </li>
    </ul>
  </nav>
  <ul v-if="conversations.data && conversations.data.length">
    <div 
      v-for="(conversation, index) in conversations.data" 
      :key="index"
      class="focus:outline-blue">
      <li @click="showConversation(conversation.id)" class="grid lg:grid-cols-4 lg:gap-x-4 sm:px-4 items-center py-4 border-b border-gray-dark/50 hover:bg-gray cursor-pointer focus:outline-none">
        <div class="flex items-center space-x-4">
          <template 
            v-for="participant in conversation.conversation.participants"
            :key="participant.id">
            <template v-if="participant.messageable && authUser.id != participant.messageable.id">
              <div
                class="h-9 w-9 rounded-full bg-center bg-cover bg-no-repeat bg-blue flex-none"
                v-bind:style="{ backgroundImage: 'url(' + participant.messageable.thumbnail + ')' }"
              >
              </div>
              <h5 class="text-base sm:text-lg text-gray-darkest font-medium flex-auto truncate">
                {{ participant.messageable.name }}
              </h5>
            </template>
          </template>
            <span v-if="!conversation.conversation.last_message.is_seen" class="inline-block px-4 text-blue bg-[#D6F5FF] rounded-xl flex-none">Unread</span>
            <span v-else class="inline-block px-4 text-gray-dark bg-gray rounded-xl flex-none">Read</span>
        </div>
        <div class="flex overflow-x-hidden">
          <p class="flex-auto truncate">
            {{ JSON.parse(conversation.data).subject }}
          </p>
        </div>
        <div class="overflow-x-hidden">
          <p 
            class="text-sm text-gray-darkest/60 truncate line-clamp-1"
            v-if="conversation.conversation.last_message"
            v-html="conversation.conversation.last_message.body"
            >
          </p>
        </div>
        <div class="flex items-center justify-between lg:justify-end space-x-4 sm:space-x-8">
          <p v-if="conversation.conversation.last_message" class="text-sm text-gray-darkest/60 whitespace-nowrap">
            {{ formateDate(conversation.conversation.last_message.created_at) }}
          </p>
          <button @click.stop="removeParticipant(conversation.id, index)" class="min-w-0 !p-3 rounded-full cursor-pointer hover:bg-red/10 focus:outline focus:outline-1 focus:outline-red" tabindex="0">
            <i class="fa-regular fa-trash-can text-red hover:text-red/75"></i>
          </button>
        </div>
      </li>
    </div>
  </ul>
  <div v-else class="text-center py-20">
    <p class="text-gray-darkest">You Don't have any conversation yet!</p>
  </div>
</template>