<template>
    <div>
        <!-- Tab buttons -->
        <div
            class="flex flex-col sm:flex-row justify-between items-center mb-6"
        >
            <div>
                <div
                    class="hidden sm:block"
                    v-for="(day, index) in agenda"
                    :key="index"
                >
                    <h2 v-if="currentTab === index" class="mt-4 mb-2">
                        {{ day.date }}
                    </h2>
                </div>
            </div>
            <div class="flex">
                <button
                    v-for="(day, index) in agenda"
                    :key="index"
                    @click="currentTab = index"
                    :class="{
                        primary: currentTab === index,
                        'text-grayt-400': currentTab === index,
                        'mr-4': index !== agenda.length - 1,
                    }"
                    class="px-4 py-2 button rounded-md transition duration-300 ease-in-out focus:outline-none"
                >
                    {{ day.date }}
                </button>
            </div>
        </div>

        <!-- Tables -->
        <div
            v-for="(day, index) in agenda"
            :key="index"
            v-show="currentTab === index"
        >
            <div class="overflow-x-auto">
                <table class="sessions-table min-w-full">
                    <thead>
                        <tr>
                            <th class="px-4 py-2 w-[200px]">Time</th>
                            <th class="px-4 py-2 w-[350px]">Activity</th>
                            <th class="px-4 py-2 min-w-[400px] sm:min-w-auto">
                                Description
                            </th>
                            <th class="px-4 py-2 w-[250px]">Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, i) in day.items" :key="i">
                            <td class="border px-4 py-2">{{ item.time }}</td>
                            <td class="border px-4 py-2">
                                {{ item.activity }}
                            </td>
                            <td
                                class="border px-4 py-2"
                                v-html="item.description"
                            ></td>
                            <td class="border px-4 py-2">
                                {{ item.location }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentTab: 0, // Index of the currently active tab
            agenda: [
                {
                    date: 'June 2',
                    items: [
                        {
                            time: '12:00pm to 5:00pm / 12:00h à 17:00h',
                            activity:
                                'Exhibitor Registration / Inscription des exposants',
                            description: '-',
                            location: 'Foyer (27th Floor) / Foyer (27ème étage)',
                        },
                        {
                            time: '12:00pm to 5:00pm / 12:00h à 17:00h',
                            activity:
                                'Exhibitor Set Up /Mise en place des accès des exposants ',
                            description: '-',
                            location: 'Summit Room (28th Floor) /Salle Summit (28ème étage)',
                        },
                        {
                            time: '3:00 to 9:00pm/ 15h00 à 21h00',
                            activity:
                                'Guest registration opens / Ouverture des inscriptions',
                            description: '-',
                            location: 'Foyer (27th Floor) / Foyer (27ème étage)',
                        },
                        {
                            time: '6:00 to 9:00pm/ 18h00 à 21h00',
                            activity:
                                'Networking Reception and Exhibitor Experience/ Réception de réseautage et Expérience exposants',
                            description: '-',
                            location: 'Summit Room (28th Floor) /Salle Summit (28ème étage)',
                        },
                    ],
                },
                {
                    date: 'June 3',
                    items: [
                        {
                            time: '7:45am/ 7h45',
                            activity:
                                'Light breakfast and networking/ Petit-déjeuner léger et réseautage',
                            description: '-',
                            location: 'Summit Room (28th Floor) /Salle Summit (28ème étage)',
                        },
                        {
                            time: '9:00am/ 9h00',
                            activity: 'Opening Remarks/ Allocution d’ouverture',
                            description: `
                A Word from Gregor Sneddon, Executive Director of HelpAge Canada/ Un mot de Gregor Sneddon, directeur exécutif d’Aide aux Aînés Canada
                <br><br>
                Welcome – Riley Brockington, Deputy Mayor of the City of Ottawa/ Bienvenue – Riley Brockington, Maire adjoint de la ville d’Ottawa
                <br><br>
                Welcome from Grandma Karen MacInnis, Algonquin Elder/ Bienvenue de Grand-mère Karen MacInnis, Aînée algonquine
              `,
                            location: 'Victoria Ballroom / Salle de bal Victoria',
                        },
                        {
                            time: '9:30am/ 9h30',
                            activity:
                                'Keynote Presentation/ Présentation d’ouverture',
                            description:
                                'CBSS101 – Dare to Dream It, Dare to Do It: A Story of the CBSS Sector in Canada./ Les SCA 101 – Oser en rêver, oser se lancer : l’histoire du secteur des SCA au Canada ',
                            location: 'Victoria Ballroom / Salle de bal Victoria',
                        },
                        {
                            time: '10:30am/ 10h30',
                            activity: 'Break/ Pause',
                            description: '-',
                            location: '',
                        },
                        {
                            time: '10:25am/ 10h25',
                            activity:
                                'Keynote Presentation/ Présentation d’ouverture',
                            description:
                                'The Honourable Seamus O’Regan Jr., Minister of Labour and Seniors / L’honorable Seamus O’Regan Jr., Ministre du Travail et des Aînés',
                            location: 'Victoria Ballroom / Salle de bal Victoria ',
                        },
                        {
                            time: '10:45am/ 10h45',
                            activity: 'Break / Pause',
                            description: '-',
                            location: '',
                        },
                        {
                            time: '11:00am/ 11h00',
                            activity:
                                'Lightning Round Presentations/ Présentations éclairs',
                            description: `
                Elder Abuse and Ageism – Anne Caines, RECAA/ Mauvais traitements envers les aînés et âgisme – Anne Caines RECAA
                <br><br>
                NORCs, Ambleside Oasis Pilot Project – Sue Garvey, Queen’s University/ Communautés de retraites naturelles (NORC), projet pilote Oasis d’Ambleside – Sue Garvey, Université Queen’s
                <br><br>
                Seniors Centre Without Walls – Ornella Gatore, The Good Companions Seniors Centre/ Centre sans murs pour les aînés – Ornella Gatore, Centre pour aînés The Good Companions
                <br><br>
                Social Isolation and Loneliness – Lyne Ouellet, St. Thomas University / L'isolement social et la solitude – Lyne Ouellet, Université St Thomas
                <br><br>
                Age-Friendly, Dementia Friendly and Compassionate Communities – Diane Weidner, McGill University Dementia Education Program / Communautés adaptées aux personnes âgées, aux personnes atteintes de démence et à la compassion – Diane Weidner, Programme de formation sur les troubles neurocognitifs de l’Université McGill
                <br><br>
                Moderated by Laura Tamblyn Watts – CanAge. Modéré par Laura Tamblyn Watts – CanAge
              `,
                            location: 'Victoria Ballroom / Salle de bal Victoria ',
                        },
                        {
                            time: '12:30pm/ 12h30',
                            activity:
                                'Networking Lunch and Exhibitor Experience/ Dîner de réseautage et expérience exposants',
                            description: '-',
                            location: 'Summit Room (28th Floor) /Salle Summit (28ème étage)',
                        },
                        {
                            time: '2:30pm/ 14h30',
                            activity:
                                'Workshop: Improving Wellbeing through Social Prescribing Interventions / Atelier : Améliorer le bien-être grâce à des interventions de prescription sociale',
                            description: `
                A collaborative workshop presented by:/ Un atelier collaboratif présenté par:
                <br><br>
                Felicia Hart, The Ontario Caregiver Organization/ Felicia Hart, Organisme de soutien aux aidants naturels de l’Ontario
                <br><br>
                Dena Silverberg and Sarah Feeney-Martin, OACAO/ Dena Silverberg et Sarah Feeney-Martin, OACAO
                <br><br>
                Paola Leal, Eva Marsden Centre for Social Justice and Aging / Paola Leal, Centre Eva Marsden pour la justice sociale et le vieillissement
                <br><br>
                Moderated by Sonia Hsiung, Canadian Institute of Social Prescribing/ Modérée par Sonia Hsiung, Institut Canadien de prescription sociale
              `,
                            location: 'Victoria Ballroom / Salle de bal Victoria',
                        },
                        {
                            time: '2:30pm/ 14h30',
                            activity:
                                'Workshop: Addressing Social Isolation and Loneliness in Rural Canada/ Atelier : Relever le défi de l’isolement social et de la solitude dans le Canada rural',
                            description: `
                A collaborative workshop presented by:/ Un atelier collaboratif présenté par :
                <br><br>
                Shahzi Yasmin Bokhari, Connection in Action and Halton’s Older Adult Isolation Action Table/ Shahzi Yasmin Bokhari, Connexion en action et et la table d'action sur l'isolement des personnes âgées de Halton
                <br><br>
                Amy Sturgeon, Gateway Centre of Excellence in Rural Health in Ontario / Amy Sturgeon, Centre d'excellence en santé rurale en Ontario
              `,
                            location: 'Rideau Room / Salle Rideau',
                        },
                        {
                            time: '2:30pm/ 14h30',
                            activity:
                                'Workshop: Advocacy, Policy, Networking, and Collective Impact / Atelier : Plaidoyer, politique, mise en réseau et impact collectif',
                            description: `
              A collaborative workshop presented by:/ Un atelier collaboratif présenté par :
              <br><br>
              Anne Smith, Healthy Aging Alberta / Anne Smith, Vieillissement en bonne santé Alberta
              <br><br>
              Marlene Raasok, Healthy Aging Alberta / Marlene Raasok, Vieillissement en bonne santé Alberta
              `,
                            location: 'Wellington Room / Salle Wellington',
                        },
                        {
                            time: '2:30pm/ 14h30',
                            activity:
                                'Workshop: Elder Abuse / Atelier : mauvais traitements envers les aînés',
                            description: `
                A collaborative workshop presented by:/ Un atelier collaboratif présenté par:
                <br><br>
                Marta Hajek, Elder Abuse Prevention Ontario/ Marta Hajek, Prévention des mauvais traitements envers les personnes âgées Ontario
                <br><br>
                Benedicte Schoepflin, CNPEA/ Benedicte Schoepflin, RCPMTA
                <br><br>
                Sharon Johnson, BC Association of Community Response Networks/ Sharon Johnson, Association des réseaux d'intervention Communautaire de Colombie-Britannique
              `,
                            location: 'Dalhousie Room / Salle Dalhousie',
                        },
                        {
                            time: '3:30pm/ 15h30',
                            activity: 'Break/ Pause',
                            description: '-',
                            location: '',
                        },
                        {
                            time: '3:45pm/ 15h45',
                            activity:
                                'Workshop: Collaborating for Change: Advancing Social Prescribing in Canada / Atelier : Collaborer pour changer : Faire progresser la prescription sociale au Canada',
                            description: `
                A collaborative workshop presented by:/ Un atelier collaboratif présenté par:
                <br><br>
                Connie Newman, Manitoba Association of Senior Communities/ Connie Newman, Association des communautés d’aînés du Manitoba 
                <br><br>
                Bobbi Symes, United Way of British Columbia/ Bobbi Symes, Centraide de Colombie-Britannique 
                <br><br>
                Beth Mansell, Healthy Aging Alberta / Beth Mansell, Vieillissement en bonne santé Alberta
                <br><br>
                Moderated by Sonia Hsiung, Canadian Institute of Social Prescribing/ Modérée par Sonia Hsiung, Institut Canadien de prescription sociale
              `,
                            location: 'Victoria Ballroom / Salle de bal Victoria',
                        },
                        {
                            time: '3:45pm/ 15h45',
                            activity:
                                'Workshop: Minority Language Communities/ Atelier : Communautés linguistiques minoritaires',
                            description: `
                A collaborative workshop presented by:/ Un atelier collaboratif présenté par:
                <br><br>
                Erica Botner, Community Health and Social Services Network/ Erica Botner, Réseau communautaire de santé et de services sociaux
                <br><br>
                Michel Tremblay, Fédération des aînés et retraités francophones de l'Ontario (FARFO)/ Michel Tremblay, Fédération des aînés et retraités francophones de l'Ontario (FARFO)
              `,
                            location: 'Rideau Room / Salle Rideau',
                        },
                        {
                            time: '3:45pm/ 15h45',
                            activity:
                                'Workshop: Grassroots Coordinating Services / Atelier : Services de coordination de proximité',
                            description:
                                'Presented by Asheika Sood and Sheila Hallett, Edmonton Seniors Coordinating Council/ Présenté par Asheika Sood et Sheila Hallett, Comité de coordination des aînés d’Edmonton',
                            location: 'Wellington Room / Salle Wellington',
                        },
                        {
                            time: '3:45pm/ 15h45',
                            activity:
                                `Workshop : Compassionate Communities: Sharing an Alberta Toolbox / Communautés de compassion : Partage d'une boîte à outils de l'Alberta`,
                            description:
                                'Presented by Maril Murray, Covenant Health Palliative Institute / présentée par Maril Murray, Institut palliatif de Covenant Health',
                            location: 'Dalhousie Room / Salle Dalhousie',
                        },
                        {
                            time: '4:45pm – 6:00pm/ 16h45-18h00',
                            activity:
                                'Break before Cocktail Reception/ Pause avant le cocktail',
                            description: '-',
                            location: '',
                        },
                        {
                            time: '6:00pm/ 18h00',
                            activity: 'Cocktail Reception/ Cocktail',
                            description: '-',
                            location: 'Victoria Ballroom (Mezzanine Level) / Salle de bal Victoria ( étage de la mezzanine)',
                        },
                        {
                            time: '7:00pm/ 19h00',
                            activity: 'Banquet',
                            description: `With emcee Mary Walsh / Avec l'animatrice Mary Walsh`,
                            location: 'Victoria Ballroom / Salle de bal Victoria',
                        },
                        {
                            time: '7:30pm/ 19h30',
                            activity:
                                'Keynote Presentation/ Présentation de clôture',
                            description:
                                'The Right Honourable Adrienne Clarkson, former Governor General of Canada/ La très honorable Adrienne Clarkson, ancienne gouverneure générale du Canada' +
                                '<br><br>' +
                                'Special performance by RECAA  / Représentation spéciale de RECAA',
                            location: 'Victoria Ballroom / Salle de bal Victoria ',
                        },
                        {
                            time: '10:00pm/ 22h00',
                            activity: 'Program Close/ Clôture du programme',
                            description: '-',
                            location: '',
                        },
                    ],
                },
                {
                    date: 'June 4',
                    items: [
                        {
                            time: '7:45am/ 7h45',
                            activity:
                                'Light Breakfast and Networking/ Petit-déjeuner léger et réseautage',
                            description: '-',
                            location: 'Summit Room (28th Floor) /Salle Summit (28ème étage)',
                        },
                        {
                            time: '8:45am / 8h45',
                            activity:
                                'Opening Remarks / Allocution d’ouverture',
                            description: '-',
                            location: 'Victoria Ballroom / Salle de bal Victoria',
                        },
                        {
                            time: '9:00am/ 9h00',
                            activity:
                                'Keynote Presentation/ Exposé liminaire',
                            description: `What is sector development? – presented by Vinod Rajasekaran, CEO of Future of Good and Annika Voltan, Executive Director of Impact Organizations of Nova Scotia/ Qu’est-ce que le développement sectoriel? – présenté par Vinod Rajasekaran, PDG de Future of Good et Annika Voltan, DG de Organisations d'impact de la Nouvelle-Écosse`,
                            location: 'Victoria Ballroom / Salle de bal Victoria',
                        },
                        {
                            time: '10:00am/ 10h00',
                            activity: 'Break / Pause',
                            description:
                                '-',
                            location: '',
                        },
                        {
                            time: '10:15am/ 10h15',
                            activity: 'World Café/ Café mondial',
                            description:
                                'Thinking large: what does it mean to work together as a national sector? Identify Key Priorities/ Réflexion globale : que signifie travailler ensemble en tant que secteur national? Identifier les grandes priorités',
                            location: 'Victoria Ballroom / Salle de bal Victoria',
                        },
                        {
                            time: '12:00pm/ 12h00',
                            activity:
                                'Networking Lunch and Exhibitor Experience/ Dîner de réseautage et expérience exposants',
                            description: '-',
                            location: 'Summit Room (28th Floor) /Salle Summit (28ème étage)',
                        },
                        {
                            time: '1:00pm/ 13h00',
                            activity:
                                'Breakout Session Overview/ Aperçu de la session de groupes de travail',
                            description: '-',
                            location: 'Plenary/ Plénière',
                        },
                        {
                            time: '1:15pm/ 13h15',
                            activity:
                                'World Café : Deep Dive / Café mondial : Plongée en profondeur',
                            description:
                                'Get ready to talk turkey! We’ll be breaking into groups to dive deep into priorities identified in the morning World Cafe,  carving out a path forward for the sector, together./ Préparez-vous à passer aux choses sérieuses! Nous nous répartirons en groupes pour nous plonger dans une réflexion autour des priorités identifiées dans la matinée lors du café mondial et tracer ensemble la voie à suivre pour le secteur.',
                            location: 'Victoria Ballroom / Salle de bal Victoria',
                        },
                        {
                            time: '2:45pm/ 14h45',
                            activity: 'Break/ Pause',
                            description: '-',
                            location: '',
                        },
                        {
                            time: '3 :00pm/ 15h00',
                            activity: 'Keynote presentation – Dr. Samir Sinha / Présentation d’ouverture – Dr. Samir Sinha',
                            description: '-',
                            location: 'Victoria Ballroom / Salle de bal Victoria',
                        },
                        {
                            time: '3:45pm/ 14h45',
                            activity: 'Closing Remarks / Allocution de clôture',
                            description: `
                            Indigenous prayer lead by Grandma Karen MacInnis/ Prière autochtone animée par Karen MacInnis
                        `,
                            location: 'Victoria Ballroom / Salle de bal Victoria',
                        },
                        {
                            time: '4:00pm/ 16h00',
                            activity:
                                'Conference Close/ Clôture de la conférence',
                            description: '-',
                            location: '',
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<style scoped>
.sessions-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #e2e8f0;
}

.sessions-table th,
.sessions-table td {
    border: 1px solid #e2e8f0;
    padding: 8px 16px; /* Adjust padding for consistent column widths */
}

.sessions-table th {
    text-align: left;
    color: #4a5568;
    background-color: #f7fafc;
}
</style>
