export default {
    state() {
        return {
            logoutModal: false,
            networkErrorModal: false,
            networkErrorMessage: '',
            successModal: false,
            successMessage: '',
            sideBar: false,
            loading: false
        };
    },
    mutations: {
        displayLogoutModal(state) {
            state.logoutModal = true;
        },
        displayNetworkErrorModal(state, message) {
            state.networkErrorMessage = message;
            state.networkErrorModal = true;
        },
        hideNetworkErrorModal(state) {
            state.networkErrorModal = false;
        },
        toggleSideBar(state) {
            state.sideBar = !state.sideBar;
        },
        updateLoadingState(state, payload) {
          return (state.loading = payload)
        },
        displaySuccessModal(state, message) {
            state.successMessage = message;
            state.successModal = true;
        },
        hideSuccessModal(state) {
            state.successModal = false;
        },
    },
    getters: {
        logoutModalState(state) {
            return state.logoutModal;
        },
        networkErrorModalState(state) {
            return state.networkErrorModal;
        },
        networkErrorMessage(state) {
            return state.networkErrorMessage;
        },
        sideBarState(state) {
            return state.sideBar;
        },
        getLoadingState(state) {
          return state.loading;
        },
        successModalState(state) {
            return state.successModal;
        },
        successMessage(state) {
            return state.successMessage;
        },
    },
};
