<template>
    <div class="mt-4 space-y-4">
        <div v-if="options.length != 0">
            <ul class="grid grid-cols-1 xl:grid-cols-2 gap-4">
                <li v-for="option in options" v-bind:key="option.id">
                    <label
                        class="flex items-center flex-none gap-x-4 font-medium text-gray-700"
                    >
                        <input
                          type="checkbox"
                          :value="option.id"
                          v-model="selectedOptions"
                          class="rounded border-gray-dark text-blue-dark shadow-sm focus:border-blue-dark focus:ring focus:ring-blue-dark focus:ring-opacity-50">

                        <div class="text-sm">
                            {{ option.name }}
                        </div>
                    </label>
                </li>
            </ul>
            <p class="mt-2 text-sm text-red-500" v-if="error">
                {{ error }}
            </p>
        </div>
        <div v-else>No data available.</div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    options: {
      type: Array,
      required: true
    },
    modelValue: {
        type: Array,
        default: [],
    },
    error: {
        type: String,
    },
});

const selectedOptions = ref(props.modelValue)
const emit = defineEmits(['update:modelValue']);

watch(() => props.modelValue, () => {
  selectedOptions.value = props.modelValue
});

watch(selectedOptions, () => {
  emit('update:modelValue', selectedOptions.value)
})

</script>
