import request from '@js/src/environment/request';
import { url } from '../support/utils';

export default {
    getAll($params = {}) {
        return request()
            .get(url('websites', $params))
            .then(({ data: { data } }) => {
                return data;
            });
    },
};
