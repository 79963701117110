<script setup>
import { ref, onMounted, reactive, nextTick } from 'vue'
import AppTextEditor from '@/components/shared/TextEditor'
import { ReplyIcon } from '@heroicons/vue/outline'

const props = defineProps({
    conversation: Object,
    authUser: Object
})

let messageForm = reactive({
  message: null
})

onMounted(() => {
  fetchMessages()
  getReceiver()
})

const messages = ref([])
const isProcessing = ref(false)
const errors = ref('')
const conversationBox = ref(null)
const textEditorKey = ref(0)
const receiver = ref(null)

function fetchMessages() {
  axios
    .get(`/conversations/${props.conversation.id}/messages`)
    .then((response) => {
        messages.value = response.data
        scrollToBottom()
    })
}

function getReceiver() {
    axios
        .get(`/conversations/${props.conversation.id}/get-receiver`)
        .then((response) => {
            receiver.value = response.data
    })
}

function formateDate(date) {
    let dt = new Date(date)
    let month = dt.toLocaleString('default', { month: 'long' })
    let day = dt.getDate();
    let year = dt.getFullYear();
    let hours = dt.getHours() ;
    hours = (hours % 12) || 12;
    hours = ('0' + hours).slice(-2)
    let AmOrPm = hours >= 12 ? 'PM' : 'AM';
    let minutes = ('0' + dt.getMinutes()).slice(-2) ;
    return `${month} ${day}, ${year} - ${hours}:${minutes}:${AmOrPm}`;
}

function sendMessage() {

  if(messageForm.message == null || messageForm.message === '') {
    return
  }

  isProcessing.value = true

  let formData = new FormData()
  formData.append("message", messageForm.message)
  
  axios.post(`/conversations/${props.conversation.id}/send-message`, formData)
    .then((response) => {
      isProcessing.value = false
      resetForm()
      fetchMessages()
      scrollToBottom()
    })
    .catch(err => {
      isProcessing.value = false
      if (err.response && err.response.status == 422) {
        errors.value = err.response.data.errors
      }
    })
}

function resetForm() {
    messageForm.message = null
    textEditorKey.value++
}

async function scrollToBottom() {
    await nextTick()
    conversationBox.value.scrollTop = conversationBox.value.scrollHeight
}
</script>

<template>
<div class="max-w-2xl mx-auto">
    <div class="flex items-center space-x-4 mb-4 lg:mb-6">
        <div
            class="h-16 w-16 rounded-full bg-center bg-cover bg-no-repeat bg-blue flex-none"
            :style="{ backgroundImage: 'url(' + receiver?.profile_photo_path + ')' }" >
        </div>
        <div class="flex-auto ">
            <h5 class="text-base sm:text-lg text-gray-darkest font-medium">
                {{ receiver?.name+' '+receiver?.last_name }} 
            </h5>
            <p class="text-base text-gray-darkest/70" v-if="receiver?.title">{{ receiver?.title }}</p>
        </div>
    </div>
    <div class="flex items-center justify-between border-b border-gray-dark/50 pb-4 lg:pb-8">
        <div>
            <h4 class="font-bold text-blue-dark mb-2">{{ conversation.data.subject }}</h4>
            <p class="text-base text-gray-darkest/70">
                <i class="fa-regular fa-calendar mr-2"></i>
                <span>{{ formateDate(conversation.created_at) }}</span>
            </p>
        </div>
    </div>
    <div class="mt-4 lg:mt-8">
        <ul class="max-h-[35rem] overflow-y-auto" ref="conversationBox">
            <li v-for="(message,index) in messages.data" :key="index" class="flex items-start space-x-4 pb-4 sm:pb-6 relative before:content-[''] before:absolute before:h-full before:w-[1px] before:left-5 before:bg-gray-dark/50 before:-z-10">
                <div 
                    v-if="message.sender"
                    class="h-10 w-10 rounded-full bg-center bg-cover bg-no-repeat bg-blue-light flex-none"
                    :style="{ backgroundImage: 'url(' + message.sender.thumbnail + ')' }" >
                </div>
                <div
                    v-else
                    class="h-10 w-10 rounded-full bg-center bg-cover bg-no-repeat bg-blue-light flex-none"
                    :style="{ backgroundImage: 'url(' + message.receiver.thumbnail + ')' }" >
                </div>
                <div class="flex-auto space-y-4">
                    <div>
                        <h5 class="text-base sm:text-lg text-gray-darkest font-medium">
                            {{ message.sender ? message.sender.name+' '+message.sender.last_name : message.receiver.name+' '+message.receiver.last_name }}
                        </h5>
                        <div class="text-sm text-gray-dark">
                            {{ formateDate(message.created_at) }}
                        </div>
                    </div>
                    <div class="prose-default" v-html="message.body">
                    </div>
                </div>
            </li>
        </ul>
        <div>
            <div class="flex items-start space-x-4">
                <div
                    class="h-10 w-10 rounded-full bg-center bg-cover bg-no-repeat bg-blue flex-none"
                    :style="{ backgroundImage: 'url(' + authUser.thumbnail + ')' }" >
                </div>
                <div class="flex-auto space-y-4">
                    <h5 class="text-base sm:text-lg text-gray-darkest font-medium">
                        {{ authUser.name+' '+authUser.last_name }}
                    </h5>
                    <fieldset>
                        <AppTextEditor 
                            :key="textEditorKey"
                            v-model="messageForm.message"
                            :toolbar="[
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                [{ 'indent': '-1' }, { 'indent': '+1' }],
                                [{ 'direction': 'rtl' }],
                                [{ 'color': [] }, { 'background': [] }],
                                [{ 'align': [] }],
                                ['clean'],
                            ]"
                        />
                    </fieldset>
                    <div class="flex justify-end">
                        <button @click="sendMessage" :disabled="isProcessing" class="button primary space-x-4">
                            <ReplyIcon class="w-6 h-6" aria-hidden="true"/>
                            <span>Reply</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>