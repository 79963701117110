import axios from 'axios';
import store from '../store';
import iziToast from 'izitoast';

export default (fromAdminPanel = false) => {
  const params = {};

  if (fromAdminPanel) {
    params['fromAdminPanel'] = true;
  }

  const request = axios.create({
    baseURL: '/api/',
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: params
  });

  // Intercept the request
  request.interceptors.request.use(
    function (config) {
      store.commit('updateLoadingState', true)
      return config;
    },
    function (error) {
      store.commit('updateLoadingState', false)
      return Promise.reject(error);
    }
  );

  // Intercept the response
  request.interceptors.response.use(
    function (response) {
      store.commit('updateLoadingState', false)
      return Promise.resolve(response);
    },
    function (error) {
      switch (error.response.status) {
        case 401:
          store.commit('displayLogoutModal');
          break;
        case 500:
          store.commit(
            'displayNetworkErrorModal',
            error.response.data.message
          );
        case 403:
          store.commit(
            'displayNetworkErrorModal',
            error.response.data.message
          );
          break;
        case 422:
          window.scrollTo({top: 0, behavior: 'smooth'})
          iziToast.error({
            title: "An error occured",
            message: "Something went wrong. please try again",
          });
          setTimeout(() => alertErrorMessage.classList.add('hidden'), 3000)
      }
      store.commit('updateLoadingState', false)
      return Promise.reject(error);
    }
  );

  return request;
};
