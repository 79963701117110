<script setup>
import {onMounted, ref} from "vue";

let currentId = ref(1);

</script>

<template>
  <div class="accordion">
    <div class="accordion-item py-4 border-b border-grayt-300 border-t">
      <div class="accordion-title flex items-start justify-between">
        <h3 class="!text-xl font-bold text-blue-dark">Bringing together thought leaders, innovators, and experts across our sector / Rassembler les maîtres à penser, les innovateurs et les experts</h3>
        <div class="ml-8">
          <span v-if="currentId !== 1" @click="currentId = 1" class="p-2 text-blue-dark hover:text-blue-darkest cursor-pointer">
            <i class="fa fa-lg fa-plus"></i>
          </span>
          <span v-else @click="currentId = null" class="p-2 text-blue-dark hover:text-blue-darkest cursor-pointer">
            <i class="fa fa-lg fa-minus"></i>
          </span>
        </div>
      </div>
      <div v-show="currentId === 1" class="accordion-content pt-3 text-grayt-500">
        <p>A first-of-its-kind event, the 2024 summit gathers stakeholders from across the country to share knowledge, resources, and expertise. The summit serves as an entry point for grassroots organizations to gain a fuller picture of aging in Canada, providing new opportunities for partnerships and growth.</p>
        <br>
        <p>À titre de premier événement en son genre, le sommet 2024 rassemblera les intervenants de partout au pays, afin que ceux-ci puissent mettre en commun leurs connaissances, leurs ressources et leur expertise. Le sommet servira de point d’entrée à ces organisations communautaires, afin que chacune puisse se faire un portrait du vieillissement au Canada, et trouver de nouvelles occasions de croître et de tisser des liens.</p>
      </div>
    </div>
    <div class="accordion-item py-4 border-b border-grayt-300">
      <div class="accordion-title flex items-start justify-between">
        <h3 class="!text-xl font-bold text-blue-dark">Establishing the needs & priorities of our sector / Déterminer nos besoins et nos priorités</h3>
        <div class="ml-8">
          <span v-if="currentId !== 2" @click="currentId = 2" class="p-2 text-blue-dark hover:text-blue-darkest cursor-pointer">
            <i class="fa fa-lg fa-plus"></i>
          </span>
          <span v-else @click="currentId = null" class="p-2 text-blue-dark hover:text-blue-darkest cursor-pointer">
            <i class="fa fa-lg fa-minus"></i>
          </span>
        </div>
      </div>
      <div v-show="currentId === 2" class="accordion-content pt-3 text-grayt-500">
        <p>The CBSS sector faces different challenges and situations from province to province and territory to territory. Drawing from our unique experiences, the 2024 summit is an intentional, dedicated space to establish the needs & priorities of the sector as a whole.</p>
        <br>
        <p>Le secteur des SCA est aux prises avec différents enjeux, selon la province et le territoire. C’est en puisant des expériences de chacun que nous ferons du sommet 2024 un lieu dévoué intentionnellement à la définition des besoins et des priorités pour tout le secteur.</p>
      </div>
    </div>
    <div class="accordion-item py-4 border-b border-grayt-300">
      <div class="accordion-title flex items-start justify-between">
        <h3 class="!text-xl font-bold text-blue-dark">Creating a unified vision for the future of our sector / Partager la vision d’un avenir commun</h3>
        <div class="ml-8">
          <span v-if="currentId !== 3" @click="currentId = 3" class="p-2 text-blue-dark hover:text-blue-darkest cursor-pointer">
            <i class="fa fa-lg fa-plus"></i>
          </span>
          <span v-else @click="currentId = null" class="p-2 text-blue-dark hover:text-blue-darkest cursor-pointer">
            <i class="fa fa-lg fa-minus"></i>
          </span>
        </div>
      </div>
      <div v-show="currentId === 3" class="accordion-content pt-3 text-grayt-500">
        <p>As leaders and innovators in the CBSS sector, we have seen first-hand the value older Canadians bring to their communities. We will carve out a pan-Canadian vision of a society where all older adults are included, empowered, and treated with dignity.</p>
        <br>
        <p>À titre de dirigeants à penser et d’innovateurs dans le secteur des SCA, nous reconnaissons la valeur que les aînés canadiens peuvent apporter à leurs communautés. Nous taillerons la vision pancanadienne d’une société où tous les aînés sont inclus, autonomisés et dignement traités.</p>
      </div>
    </div>
    <div class="accordion-item py-4 border-b border-grayt-300">
      <div class="accordion-title flex items-start justify-between">
        <h3 class="!text-xl font-bold text-blue-dark">Declaring the identity and role of our sector / Définir le rôle et l’identité de notre secteur</h3>
        <div class="ml-8">
          <span v-if="currentId !== 4" @click="currentId = 4" class="p-2 text-blue-dark hover:text-blue-darkest cursor-pointer">
            <i class="fa fa-lg fa-plus"></i>
          </span>
          <span v-else @click="currentId = null" class="p-2 text-blue-dark hover:text-blue-darkest cursor-pointer">
            <i class="fa fa-lg fa-minus"></i>
          </span>
        </div>
      </div>
      <div v-show="currentId === 4" class="accordion-content pt-3 text-grayt-500">
        <p>We will raise our collective voice to declare our sector's place in the ageing landscape in Canada, setting the tone for our relationship with government bodies, other sectors, and with older Canadians for years to come.</p>
        <br>
        <p>Nous unirons nos voix afin pour établir la place de notre secteur sur la scène du vieillissement au Canada, et donnerons le ton de notre relation avec les agences gouvernementales, les secteurs connexes et les aînés canadiens pour les années à venir.</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
