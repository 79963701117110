import request from '@js/src/environment/request';

export default {

  getAll(groupId) {
    let url = 'categories';
    if(groupId){
      url = `categories?group_id=${groupId}`;
    }
    return request()
      .get(url)
      .then(({ data: { data } }) => {
        return data;
      });
  }

}